<div class="col-md-12">

<div class="foodTitle mt-3">Recent Food Log</div>  

<div class="memberName mt-4">{{this.memberName}}</div>
<div class="col-md-12 ">
    <div class="col-md-12 mt-5" >
        <!-- all-food-logs.component.html -->
<mat-form-field>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Start date" 
             [(ngModel)]="selectedDateRange.start" 
             #dateRangeStart>
      <input matEndDate placeholder="End date" 
             [(ngModel)]="selectedDateRange.end" 
             #dateRangeEnd 
             (dateChange)="filterByDate(dateRangeStart, dateRangeEnd)">
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
    </div>

<div class="col-md-12 mt-5 ">
    <mat-list role="list" style="display: flex;">
        <mat-list-item class="tab" [ngClass]="{'active': selectedType === 'All'}" role="listitem" (click)="foodFilter('All')">
            <div  >
                <div class="activeTabContent" >All</div>
            </div>
        </mat-list-item>
        <mat-list-item class="tab" [ngClass]="{'active': selectedType === 'Breakfast'}" role="listitem" (click)="foodFilter('Breakfast')">
            <div  >
                <div class="tabContent" >Breakfast </div>
            </div>
        </mat-list-item>
        
        <mat-list-item class="tab" [ngClass]="{'active': selectedType === 'Lunch'}" role="listitem" (click)="foodFilter('Lunch')">
            <div >
                <div class="tabContent" >Lunch</div>
            </div>
            </mat-list-item>
        <mat-list-item  class="tab" [ngClass]="{'active': selectedType === 'Dinner'}" role="listitem" (click)="foodFilter('Dinner')">
            <div>
                <div class="tabContent" >Dinner </div>
            </div>
            </mat-list-item>
        <mat-list-item class="tab" [ngClass]="{'active': selectedType === 'Snack'}" role="listitem" (click)="foodFilter('Snack')">
            <div  >
            <div class="tabContent" >Snack </div>
        </div>
        </mat-list-item>
      </mat-list>
</div>


</div>

<!-- <div class="col-md-4 mt-4">
    <div class="dateClass">{{currentDateFormat}}</div>
    <hr>
</div> -->
<div class="col-md-12 floatLeft">
<div class="row" *ngFor='let food of foodDetails;'>
    <div class="col-md-4 mt-5">
        <div class="dateClass">{{food.date}}</div>
        <hr>
    </div>
    
     <div class="col-md-12">
  <app-total-nutri [foodInfo]="food"  *ngIf="this.foodDetails.length > 0"></app-total-nutri>
  </div>
  


<div class="col-md-12 mt-3" *ngIf="this.foodDetails.length > 0">
   <app-all-logs *ngFor='let foodInfo of food.foodLogsInfo;' [foodAllInfo]="foodInfo" [member]="memberId"></app-all-logs>
   <div class="col-md-12" *ngIf="food.foodLogsInfo.length < 1">
    No food log available for this meal category.
 </div>
</div>

</div>

<div class="row mt-5 flex floatLeft" *ngIf="this.foodDetails.length < 1">
    No Record Found..
 </div>
</div>
</div>