import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { NgForOf } from '@angular/common';
import { MemberCardComponent } from './member-card/member-card.component';
import { MemberService } from './member.service';
import { SharedServiceService } from '../../services/shared-service.service';
import { Member } from '../../models/member';
import { FormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';


@Component({
  selector: 'app-member-list',
  standalone: true,
  imports: [MatCardModule, NgForOf, MemberCardComponent, FormsModule, MatTableModule, MatPaginatorModule, MatPaginator, RouterLink],
  templateUrl: './member-list.component.html',
  styleUrl: './member-list.component.css'
})
export class MemberListComponent {
  @ViewChild('nameInput') nameInput!: ElementRef;
  displayedColumns: string[] = ['No', 'FirstName', 'Email', 'Mobile', 'City', 'State', 'Team', 'Actions'];
  //dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  timeout: any = null;
  filter: any = {
    'filterType': 'none',
    'name': '',
    'teamId': '',
    'fromDate': new Date(),
    'toDate': new Date(),
    'userLoggedIn': '',
    'isAssigned': '',
    'level': '0'
  };
  selectFilterBy: 'none';
  isAdmin: any;
  role: any;
  memberLists: any = [];
  totalRecords: any = [];
  memberDetails: any = [];
  pageSize: number = 10;
  teamsFilter: any = [];
  selectedTeamsFilter: "";
  selectLevelBy: "0";

  paginator: MatPaginator;
  dataSource = new MatTableDataSource<Member>;
  pageIndexVal: any = 0;
  constructor(private memberService: MemberService, private SharedService: SharedServiceService) {
    this.role = this.SharedService.getloggedUserRole();
    let user = this.SharedService.getLoggedInUser();
    this.filter.userLoggedIn = user.id

    if (this.role == 'Administrator') {
      this.isAdmin = true;
    }
    else if (this.role != 'Administrator') {
      this.isAdmin = false;
    }

  }
  ngOnInit(): void {
    this.selectFilterBy = 'none';
    this.selectedTeamsFilter = "";
    this.selectLevelBy = "0";
    this.getTeamsForFilter();
    const { stateObj } = window.history.state;
    if (stateObj) {
     this.filter = stateObj;
     this.selectFilterBy = this.filter.filterType;
      this.selectedTeamsFilter = this.filter.teamId;
      this.selectLevelBy = this.filter.level;
     
    }
    // this.dataSource.paginator = this.paginator;

    // let level:any=['memberLevel1','memberLevel2','memberLevel3','memberLevel4','memberLevel5'];
    // for(let i=0;i<5;i++){
    //   this.getMemberRecord(1,level[i],i);
    // }
    this.getMemberRecord(0);
  }
  ngAfterViewInit(){
 this.nameInput.nativeElement.value = this.filter.name;
  }

  getTeamsForFilter() {
    this.memberService.getTeams(this.isAdmin).subscribe(data => {
      this.teamsFilter = data['result'];
    });
  }

  getMemberRecord(page: any) {
    this.memberService.getMemberByFilterAPI(this.filter, page, this.isAdmin).subscribe(response => {
      this.memberDetails = response;
      this.dataSource = this.memberDetails['result'];
      this.dataSource.paginator = this.memberDetails['totalCount'];


    });
  }

  getMemberRecord1(page: any, level: any, index: any) {
    let user = this.SharedService.getLoggedInUser();



    this.memberService.getMemberByLevel(user.id, page, level, this.isAdmin).subscribe(response => {

      this.memberDetails = response;
      let memberData: any = [];
      if (this.memberDetails['result'].length > 0) {
        for (let j = 0; j < this.memberDetails['result'].length; j++) {
          memberData[j] = [{
            'id': this.memberDetails['result'][j].memberId,
            'personName': this.memberDetails['result'][j].firstName + ' ' + this.memberDetails['result'][j].lastName,
            'subTitle': '',
            'age': this.memberDetails['result'][j].age,
            'email': this.memberDetails['result'][j].email,
            'phoneNumber': this.memberDetails['result'][j].mobileNo,
            'address': this.memberDetails['result'][j].address,
            'team': ''

          }];
        }
      }
      this.memberLists[index] = memberData;
      this.totalRecords[index] = this.memberDetails['totalCount'];
      // this.memberDetails =  this.memberDetails['memberTeamAssignment'];

    });

    console.log(this.memberLists);
  }

  filterData(data: any, type: any) {
    //this.filter.teamId = '';
    //this.selectedTeamsFilter = "";
    //this.filter.level = '';

    clearTimeout(this.timeout);
    var $this = this;
    if (data.target.value != '') {
      this.timeout = setTimeout(() => {
        if (type == 1) {
          this.filter.name = data.target.value;
        }
        else if (type == 2) {
          this.filter.filterType = data.target.value;
        }
        else if (type == 3) {
          this.filter.teamId = data.target.value;
        }
        else if (type == 4) {
          this.filter.level = data.target.value;
        }
        this.getMemberRecord(0);
      }, 500);
    } else {
      if (type == 1) {
        this.filter.name = '';
      }
      if (type == 3) {
        this.filter.teamId = '';        
      }
      if (type == 4) {
        this.filter.level = '';
      }

      this.getMemberRecord(0);
    }
  }

  pageChangeEvent(page: any) {
    console.log(page);
    this.pageIndexVal = this.pageSize * page.pageIndex;
    this.getMemberRecord(page.pageIndex);
  }
  resetSearch() {
    this.getTeamsForFilter();
    this.nameInput.nativeElement.value = '';
    this.filter.filterType = 'none';
    this.filter.teamId = '';
    this.filter.level = '0';
    this.filter.name = '';
    this.selectFilterBy = 'none';
    this.selectedTeamsFilter = "";
    this.selectLevelBy = "0";
    this.getMemberRecord(0);
  }
}
