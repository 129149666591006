

<form #f="ngForm" (ngSubmit)="onSubmit()">
  <div class="col-md-12" style="display: flex;">
    <div class="col-3">
      <div class="imageOutter">
        <img class="profile-pic img-fluid" src={{this.member.profilePicture}} alt="profile-pic">
      </div>
    </div>
    <div class="col-9">

      <div class="col-md-6 form-group float ">

        <label for="firstName" class="form-label labelReport">First Name:</label>

        <input matInput type="text" [(ngModel)]="member.firstName" #firstName="ngModel" pInputText pKeyFilter="alpha" [ngModelOptions]="{standalone: true}" class="form-control inputField" id="fname" placeholder="First Name">


      </div>
      <div class="col-md-6 form-group float">

        <label for="lastName" class="form-label labelReport">Last Name:</label>

        <input matInput type="text" [(ngModel)]="member.lastName" #lastName="ngModel" pInputText pKeyFilter="alpha" [ngModelOptions]="{standalone: true}" class="form-control inputField" id="lname" placeholder="Last Name">


      </div>

      <div class="col-md-6 form-group float mt-4">

        <label for="mobile" class="form-label labelReport">Mobile Number:</label>

        <input matInput type="text" [(ngModel)]="member.phoneNumber" pinputtext="" pkeyfilter="num" minlength=10 maxlength=12 [ngModelOptions]="{standalone: true}" class="form-control inputField" id="mobno" placeholder="Mobile Number">

      </div>
      <div class="col-md-6 form-group float mt-4">

        <label for="email" class="form-label labelReport">Email Address:</label>

        <input matInput type="email" [(ngModel)]="member.email" disabled="disabled" [ngModelOptions]="{standalone: true}" class="form-control inputField" id="email" placeholder="Email">

      </div>

      <div class="col-md-6 form-group float mt-4">

        <label for="address1" class="form-label labelReport">Street Address 1:</label>

        <input matInput type="text" [(ngModel)]="memberList.address.addressLine1" [ngModelOptions]="{standalone: true}" class="form-control inputField" id="add1" placeholder="Street Address 1">

      </div>
      <div class="col-md-6 form-group float mt-4">

        <label for="address2" class="form-label labelReport">Street Address 2:</label>

        <input matInput type="text" [(ngModel)]="memberList.address.addressLine2" [ngModelOptions]="{standalone: true}" class="form-control inputField" id="add2" placeholder="Street Address 2">

      </div>

      <div class="col-md-6 form-group float mt-4">

        <label for="country" class="form-label labelReport">Country:</label>

        <input matInput type="text" [(ngModel)]="memberList.address.country" [ngModelOptions]="{standalone: true}" class="form-control inputField" placeholder="Country" />


      </div>
      <div class="col-md-6 form-group float mt-4">

        <label for="state" class="form-label labelReport">State:</label>

        <input matInput type="text" [(ngModel)]="memberList.address.state" [ngModelOptions]="{standalone: true}" class="form-control inputField" placeholder="State">


      </div>

      <div class="col-md-6 form-group float mt-4">

        <label for="city" class="form-label labelReport">Town/City:</label>

        <input matInput type="text" [(ngModel)]="memberList.address.city" [ngModelOptions]="{standalone: true}" class="form-control inputField" id="city" placeholder="Town/City">


      </div>
      <div class="col-md-6 form-group float mt-4">

        <label for="exampleInputEmail1" class="form-label labelReport">Zip Code:</label>


        <input matInput type="text" [(ngModel)]="memberList.address.zipCode" [ngModelOptions]="{standalone: true}" class="form-control inputField" id="pno" placeholder="Pin Code">

      </div>

      <div class="col-md-6 form-group float mt-4">

        <label for="lang" class="form-label labelReport">Preferred Language:</label>


        <select class="form-control inputField" style="padding:10px 23px;" [(ngModel)]="memberList.preferedLanguage" (change)="SelectLanguage($event)" [ngModelOptions]="{standalone: true}">
          <option value="en">English</option>
          <option value="hindi">Hindi</option>

        </select>
      </div>

      <div class="col-md-6 form-group float mt-4">

        <label for="martialstatus" class="form-label labelReport">Martial Status:</label>

        <select matSelectewaz class="form-control inputField" style="padding:10px 23px;" [(ngModel)]="member.maritalStatus" (change)="SelectMaritalStatus($event)" [ngModelOptions]="{standalone: true}">
          <option value="single">Single</option>
          <option value="married">Married</option>
        </select>
      </div>


      <div class="col-md-6 form-group float mt-4">

        <label for="childern" class="form-label labelReport">Do you have any children:</label>

        <select matSelectewaz class="form-control inputField" style="padding:10px 23px;" [(ngModel)]="member.noOfChild" (change)="selectChildrenDetails($event)" [ngModelOptions]="{standalone: true}">
          <option>select count</option>
          <option>0</option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
          <option>7</option>
          <option>8</option>
          <option>9</option>
          <option>10</option>
        </select>
      </div>

      <div class="col-md-6 form-group float mt-4">

        <label for="dob" class="form-label labelReport">Date of Birth:</label>


        <input class="form-control" type="date" [value]="memberList.dateOfBirth | date:'yyyy-MM-dd'" (change)="getDateOfBirth($event)" />

      </div>

      <div class="col-md-6 form-group float mt-4">

        <label for="exampleInputEmail1" class="form-label labelReport"> Please mention your qualification details:</label>

        <select matSelectewaz class="form-control inputField" style="padding:10px 23px;" [(ngModel)]="member.qualificationDetails" (change)="selectQualificateionDetails($event)" [ngModelOptions]="{standalone: true}">
          <option value="below_graduate">Below Graduate</option>
          <option value="graduate">Graduate</option>
          <option value="post_graduate">Post Graduate</option>
        </select>
      </div>

      <div class="col-md-6 form-group float mt-4">
        <label for="exampleInputEmail1" class="form-label labelReport"> Height:</label>
        <input type="number" [(ngModel)]="member.height" #preferredName="ngModel" [ngModelOptions]="{standalone: true}" class="form-control inputField" id="height" placeholder="Enter your height">

      </div>

      <div class="col-md-6 form-group float mt-4">
        <label for="exampleInputEmail1" class="form-label labelReport"> Unit of Height:</label>

        <select matSelectewaz class="form-control inputField" style="padding:10px 23px;" [(ngModel)]="member.heightUnit" (change)="selectQualificateionDetails($event)" [ngModelOptions]="{standalone: true}">
          <option value="cm">cm</option>
          <option value="m">m</option>

        </select>
      </div>
      <div class="col-md-6 form-group float mt-4">
        <label for="exampleInputEmail1" class="form-label labelReport"> Plan</label>

        <select class="form-control inputField" style="padding:10px 23px;" [(ngModel)]="member.plan" (change)="SelectPlan($event)" [ngModelOptions]="{standalone: true}">
          <option>Self Care Plan</option>
          <option>Premium Care Plan</option>
          <option>Premium Care Plan - Free / Advocate</option>
          <option>Heald X Stelo</option>
          <option>Heald X Stelo - Free / Advocate</option>
          <option>Concierge Plan</option>
        </select>
      </div>
      <div class="col-md-6 form-group float mt-4">
        <label for="exampleInputEmail1" class="form-label labelReport"> Level of Care:</label>

        <select class="form-control inputField" style="padding:10px 23px;" [(ngModel)]="member.memberLevel" (change)="SelectLevel($event)" [ngModelOptions]="{standalone: true}">
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>

        </select>
      </div>
      <div class="col-md-6 form-group float mt-4">
        <label for="exampleInputEmail1" class="form-label labelReport"> Desired active calories burn:</label>
        <input type="number" [(ngModel)]="member.desiredActiveCalsBurn" #preferredName="ngModel" [ngModelOptions]="{standalone: true}" class="form-control inputField">

      </div>


      <div class="col-md-12 form-group float mt-4">

        <button type="button" (click)="goBack()" class="btn btn-primary btn mt-4 color1">Back</button> &nbsp; <button type="submit" class="btn btn-primary btn mt-4 color">Save</button>

      </div>
    </div>
  </div>
</form>
