<div class="row">
  <div class="mt-3">
    <mat-form-field>
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Start date" [(ngModel)]="previousDateBmi" #dateRangeStartBmi>
        <input matEndDate placeholder="End date" [(ngModel)]="currentDateBmi" #dateRangeEndBmi (dateChange)="filterByDateBmi(dateRangeStartBmi, dateRangeEndBmi)">
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
  <div class="col-md-2">
    Weight Type
    <select class="form-control inputField ng-pristine " (change)="changeBMIWeightType($event)">
      <option value="1">Kg</option>
      <option value="2">Pound</option>
    </select>
  </div>
</div>

<div style="width: 80%;">
  <!-- <p-chart type="line" [data]="bmiReadings" [options]="bmiOptions"></p-chart> -->
  <highcharts-chart [Highcharts]="Highcharts"
                    [options]="chartOptions"
                    [callbackFunction]="chartCallback"
                    [(update)]="updateFlag"
                    [oneToOne]="oneToOneFlag"
                    [runOutsideAngular]="runOutsideAngular"
                    style="width: 100%; height: 100%; display: block;"></highcharts-chart>
</div>

<div class="col-md-12" *ngIf="bmiData.length < 1">
  <h4>No Record Found</h4>
</div>
