<div class="col-md-12 flex mt-4">
    <h4>Overall Total</h4>
</div>
<div class="col-md-12 flex mt-4">

<div class="col">
        
    <div style="width: 100% " class="flex"><div class="cal">dd</div> &nbsp; <span class="totalCal">Calories :</span><span class="nutriVal"> {{Math.round(foodInfo.totalCalories)}} C</span></div>
</div>

<div class="col">
   
    <div style="width: 100%" class="flex"><div class="protien">dd</div> &nbsp;<span class="totalCal">Protien :</span><span class="nutriVal"> {{foodInfo.totalProteins.toFixed(1)}} g</span></div>
</div>
<div class="col">
    
    <div style="width: 100%" class="flex"><div class="carb">dd</div> &nbsp;  <span class="totalCal">Carbs :</span><span class="nutriVal"> {{foodInfo.totalCarbs.toFixed(1)}} g</span></div>
</div>
<div class="col">
   
    <div style="width: 100%" class="flex"> <div class="fat">dd</div> &nbsp;  <span class="totalCal">Fats :</span><span class="nutriVal"> {{foodInfo.totalFats.toFixed(1)}} g</span></div>
</div>
</div>