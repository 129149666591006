import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ControllerApiPath } from '../../services/constant';
import { SharedServiceService } from '../../services/shared-service.service';
import { Observable } from 'rxjs';
import { Member } from '../../models/member';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  private apiBaseUrl: string;

  constructor(private http: HttpClient, private sharedService: SharedServiceService) {
    this.apiBaseUrl = sharedService.baseUrl();
  }

  getMemberByFilterAPI(param: any, page: any, isAdmin: any): Observable<Member> {
    const headers = this.sharedService.createAuthorizationHeader();
    // const userDetails = this.getLoggedUser();
    let url = '';
    if (isAdmin == true) {
      url = this.apiBaseUrl + ControllerApiPath.members + '?pageSize=' + 10 + '&pageNo=' + page + '&name=' + param.name + '&teamId=' + param.teamId + '&level=' + param.level + '&filterType=' + param.filterType;
    }
    else {
      url = this.apiBaseUrl + ControllerApiPath.members + '?name=' + param.name + '&teamId=' + param.teamId + '&level=' + param.level + '&filterType=' + param.filterType + '&pageSize=' + 10 + '&pageNo=' + page + '&isAssigned=true' + '&userLoggedIn=' + param.userLoggedIn;
    }
    return this.http.get<Member>(url, { headers }).pipe(
      map((member: Member) => {
        return member;
      }));


  }

  getMemberByLevel(user: any, page: any, level: any, isAdmin: any): Observable<Member> {
    const headers = this.sharedService.createAuthorizationHeader();
    // const userDetails = this.getLoggedUser();
    let url = '';
    if (isAdmin == true) {
      url = this.apiBaseUrl + ControllerApiPath.members + '/GetMemberByLevel?pageSize=' + 3 + '&pageNo=' + page + '&memberLevelFilter=' + level;
    }
    else {
      url = this.apiBaseUrl + ControllerApiPath.members + '/GetMemberByLevel?pageSize=' + 3 + '&pageNo=' + page + '&userLoggedIn=' + user + '&memberLevelFilter=' + level;
    }
    return this.http.get<Member>(url, { headers }).pipe(
      map((member: Member) => {
        return member;
      }));


  }
  getTeams(isAdmin: any) {
    var userdetails = this.sharedService.getLoggedInUser();
    const headers = this.sharedService.createAuthorizationHeader();
    let url = '';
    if (isAdmin) {
      url = this.apiBaseUrl + 'teams?pageNo=1&pageSize=100';
    } else {
      url = this.apiBaseUrl + 'teams?loggedInUserId=' + userdetails.id + '&pageNo=1&pageSize=100';
      
    }
    return this.http.get<any>(url, { headers });
  }
}
