import { Component } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MemberService } from '../member-service/MemberService ';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HelperService } from '../../services/helper-service';
import { Member } from '../../models/member';
import { CommonModule, formatDate } from '@angular/common';
import { ToastService } from '../../services/toast.service';
@Component({
  selector: 'app-member-detail-form',
  standalone: true,
  imports: [MatInputModule, MatFormFieldModule, FormsModule, CommonModule],
  templateUrl: './member-detail-form.component.html',
  styleUrl: './member-detail-form.component.css'
})
export class MemberDetailFormComponent {
  constructor(
    private memberService: MemberService,
    private route: ActivatedRoute, private router: Router,
    private helperService: HelperService,
    private toast: ToastService
  ) { }
  member: any = [];
  memberUpdate: Member = {
    email: '',
    firstName: '',
    lastName: '',
    id: '',
    password: '',
    mobileNo: '',
    timeZone: '',
    role: '',
    address: [],
    preferredName: '',
    preferedLanguage: "",
    qualificationDetails: '',
    maritalStatus: '',
    noOfChild: 0,
    dateOfBirth: '',
    height: 0,
    heightUnit: "",
    profilePicture: "",
    phoneNumber: "",
    plan: "",
    memberLevel: 0,
    desiredActiveCalsBurn: 0
  };
  memberId: string = '';
  selectedLanguage: string = ''
  selectedMaritalStatus: string = ''
  selectedPlan: string = ''
  selectedLevel: number = 0
  selectedDateOfBirth: string = ''
  selectedFileName: string = '';
  fileType: string = '';
  selectedPrescriptionName: string = '';
  selectedNoOfChilren: string = ''
  selectedQualification: string = ''
  selectedHeightUnits: string = ''
  cities: any = [];
  states: any = [];
  countries: any = {};
  tZone: any;
  memberList: any = [];
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.memberId = this.route.snapshot.queryParamMap.get('id') || '';
      if (this.memberId) {
        this.getMemberById(this.memberId);
      }
    });
  }

  getMemberById(id: string) {
    this.memberService.getMemberById(id).subscribe(data => {
      if (data) {

        // this.member = data.result; // Directly assign data
        console.log(this.member);
        this.memberList = data.result;
        if (this.memberList.timeZone && this.memberList.timeZone != '' && this.memberList.timeZone != "null") {
          this.tZone = this.memberList.timeZone
        } else {
          if (this.memberList.address.country.toUpperCase() === "INDIA") {
            this.tZone = 'Asia/Kolkata';
          } else {
            this.tZone = 'America/New_York';
          }
        }
        //console.log(this.memberList);
        this.member = this.memberList;
        this.member.memberLevel = this.member.memberLevel.replace(/L/g, "");
        this.member.address = this.memberList.address;
      }
    });
  }

  onSubmit() {
    this.memberUpdate.address = this.member.address;
    this.memberUpdate.firstName = this.member.firstName;
    this.memberUpdate.lastName = this.member.lastName;
    this.memberUpdate.dateOfBirth = this.member.dateOfBirth;
    this.memberUpdate.height = this.member.height;
    this.memberUpdate.heightUnit = this.member.heightUnit;
    this.memberUpdate.maritalStatus = this.member.maritalStatus;
    this.memberUpdate.noOfChild = this.member.noOfChild;
    this.memberUpdate.preferedLanguage = this.member.preferedLanguage;
    this.memberUpdate.qualificationDetails = this.member.qualificationDetails;
    this.memberUpdate.phoneNumber = this.member.phoneNumber;
    this.memberUpdate.plan = this.member.plan;
    this.memberUpdate.desiredActiveCalsBurn = this.member.desiredActiveCalsBurn;
    this.memberUpdate.memberLevel = this.member.memberLevel;
    if (this.member.phoneNumber.trim().length < 10) {
      this.toast.errorMessage("Mobile number should be equal to 11 or 12 digit with country code.");

    } else {
      let timeNow = new Date();
      let timeNow1 = formatDate(timeNow.setFullYear(timeNow.getFullYear() - 18), 'yyyy-MM-dd', 'en_US');
      if (this.member.height < 1 && this.member.heightUnit == 'm') {
        this.toast.errorMessage("Height must be equal or more than 1.");
      }
      else if (this.member.height < 120 && this.member.heightUnit == 'cm') {
        this.toast.errorMessage("Height must be equal or more than 120.");
      }

      else if (this.member.dateOfBirth.split('T')[0] >= timeNow1) {
        //  console.log(this.member.dateOfBirth.split('T')[0]);
        //console.log(timeNow1);
        this.toast.errorMessage("DOB must be greater than or equal to 18.");
      } else {
        // this.member.plan = this.selectedPlan;
        // this.member.memberLevel = this.selectedLevel;
        this.memberService.updateMember(this.memberId, this.memberUpdate).subscribe((data) => {
          if (data) {
            this.toast.successMessage("Member details updated successfully.");
            this.getMemberById(this.memberId);
          }
          
        });
      }
      console.log('Member updated successfully:');
      //this.memberService.updateMember(this.memberId, this.member).subscribe(response => {
      //  console.log('Member updated successfully:', response);
      //});
    }
  }


    getDateOfBirth(event: any) {
      this.member.dateOfBirth = new Date(event.target.value).toISOString();
    }
    SelectLanguage(event: any) {
      this.selectedLanguage = event.target.value;
    }
    selectChildrenDetails(event: any) {
      this.selectedNoOfChilren = event.target.value

    }
    SelectMaritalStatus(event: any) {
      this.selectedMaritalStatus = event.target.value;
    }
    SelectPlan(event: any) {
      this.selectedPlan = event.target.value;
    }
    SelectLevel(event: any) {
      this.selectedLevel = event.target.value;
    }
    selectQualificateionDetails(event: any) {
      this.selectedQualification = event.target.value;
    }
    selectHeight(event: any) {
      this.selectedHeightUnits = event.target.value;
  }

  goBack(): void {
    this.router.navigate(['/members']); 
  }
  }
