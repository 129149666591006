<div class="mt-3">
  <mat-form-field>
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Start date" [(ngModel)]="previousDateBmi" #dateRangeStartBmi>
      <input matEndDate placeholder="End date" [(ngModel)]="currentDateBmi" #dateRangeEndBmi (dateChange)="filterByDateSleepGrpah(dateRangeStartBmi, dateRangeEndBmi)">
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</div>
<div style="width: 80%;text-align:center">
  <br />
  <span><b>Sleep Stage Percentages</b></span>
  <!-- <p-chart type="line" [data]="bmiReadings" [options]="bmiOptions"></p-chart> -->
  <highcharts-chart [Highcharts]="Highcharts"
                    [options]="chartOptions2"
                    style="width: 100%; height: 100%; display: block;"></highcharts-chart>

</div>
<div style="width: 80%; text-align: center">
  <br />
  <span><b>Total Sleep Time</b></span>
  <!-- <p-chart type="line" [data]="bmiReadings" [options]="bmiOptions"></p-chart> -->
  <highcharts-chart [Highcharts]="Highcharts"
                    [options]="chartOptions1"
                    style="width: 100%; height: 100%; display: block;"></highcharts-chart>

</div>
