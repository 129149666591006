import { Component } from '@angular/core';

import { ResultComponent } from '../result/result.component';
import { MatListModule } from '@angular/material/list';
import { MealPlanService } from '../meal-plan.service';
import { ActivatedRoute } from '@angular/router';
import { NgFor } from '@angular/common';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FastingComponent } from "../fasting/fasting.component";
import { MealDetailsComponent } from '../meal-details/meal-details.component';
import { NgClass } from '@angular/common';
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { ToastService } from '../../services/toast.service';
import { Location } from '@angular/common';
import { MealMemberBasicDetailsComponent } from '../meal-member-basic-details/meal-member-basic-details.component';

@Component({
  selector: 'app-view-plan',
  standalone: true,
  imports: [MealMemberBasicDetailsComponent, ResultComponent, MatListModule, NgFor, NgIf, FastingComponent, MealDetailsComponent, NgClass, FormsModule],
  templateUrl: './view-plan.component.html',
  styleUrl: './view-plan.component.css'
})
export class ViewPlanComponent {
  memberId: any;
  mealPlanId: string;
  tabtype: any;
  meals: any = [];
  mealResult: any = [];
  ongoingMeal: any = [];
  completed: any = [];
  draft: any = [];
  upcomingmeal: any = [];
  viewDetials: any = [];
  isback: any = 1;
  // isHistory:boolean = true;
  // isDetail:boolean = false;
  isCooking: boolean = false;
  isIngredient: boolean = true;
  isType: any = 3;
  mealDate: any = [];
  memberDetails: any = [];
  actualWeight: any = 0;
  showTitle = true;
  constructor(private location: Location,private toast: ToastService, private mealService: MealPlanService, private route: ActivatedRoute, private router: Router, private datePipe: DatePipe) {

  }

  ngOnInit(): void {
    this.memberId = this.route.snapshot.queryParamMap.get('memberId');
    this.mealPlanId = this.route.snapshot.queryParamMap.get('mealplanId') || '';
    this.getMeals();
    this.isType = 2;
    this.route.queryParams.subscribe(params => {
      if (params['isType']) {

        this.isType = Number(params['isType']);
        
        //  if (this.isType !== 1) {
        //    this.getDraftMeals();
        //  }
        //  this.filterPlans(this.isType); // Call your existing filter method
        //}
      }
    });


  }
  
  getMeals() {
    this.mealService.getViewMealPlan(this.mealPlanId).subscribe(response => {
      this.meals = response;
      //console.log(this.meals);
      let details: any = [];
      let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      let mealPlan: any = [];
      let dates: any = [];
      for (let i = 0; i < this.meals.length; i++) {
        this.ongoingMeal.push([]);
        this.completed.push([]);

        this.mealDate.push({ startDt: this.meals[i].fromDate, endDt: this.meals[i].toDate, status: this.meals[i].status });
        mealPlan = this.meals[i].mealPlan;
        for (let j = 0; j < 7; j++) {
          if (mealPlan[days[j]].length < 1) {
            details = { day: days[j], meals: [] };
          } else {
            // console.log(mealPlan[days[j]][0]);
            details = { day: days[j], meals: mealPlan[days[j]][0].meals };
          }
          if (this.meals[i].status == 'ongoing') {
            this.ongoingMeal[i].push(details);
            this.mealResult = this.ongoingMeal;
          }
          else if (this.meals[i].status == 'completed') {
            this.completed[i].push(details);
            this.mealResult = this.completed;
          }
          else {
            this.completed[i].push(details);
            this.mealResult = this.completed;
          }
        }

      }
      
      
    });
  }
  getDraftMeals(): void {
    this.isType = 3;
    this.mealService.getDraftMeals(this.memberId).subscribe(response => {
      this.draft = response;
      let details: any = [];
      let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      let mealPlan: any = [];
      for (let i = 0; i < this.draft.length; i++) {
        this.draft[i].meals = [];
        mealPlan = this.draft[i].mealPlan;
        for (let j = 0; j < 7; j++) {
          if (mealPlan[days[j]].length < 1) {
            details = { day: days[j], meals: [] };
          } else {
            details = { day: days[j], meals: mealPlan[days[j]][0].meals };
          }
          this.draft[i].meals.push(details);
        }
      }
    });
  }
  getUpComingMeals(): void {
    this.isType = 4;
    this.draft = [];
    this.mealService.getUpComingMeals(this.memberId).subscribe(response => {
      this.upcomingmeal = response;
      let details: any = [];
      let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      let mealPlan: any = [];
      for (let i = 0; i < this.upcomingmeal.length; i++) {
        this.upcomingmeal[i].meals = [];
        mealPlan = this.upcomingmeal[i].mealPlan;
        for (let j = 0; j < 7; j++) {
          if (mealPlan[days[j]].length < 1) {
            details = { day: days[j], meals: [] };
          } else {
            details = { day: days[j], meals: mealPlan[days[j]][0].meals };
          }
          this.upcomingmeal[i].meals.push(details);
        }
      }
    });
  }

  saveAllIngredients() {

    this.mealService.saveIngredientsMealPlan(this.viewDetials.ingredients, this.viewDetials.recipeId).subscribe(response => {
      //      this.ToastrService.success('Meal plan created and assigned successfully.');
      this.toast.successMessage('Ingredients saved successfully.');
      console.log('Ingredients saved successfully', response);
    }, error => {
      console.error('Error saving ingredients', error);
    });
  }
  viewMealPlan(id: any) {
    this.showTitle = false;
    this.mealService.getMealDetail(id).subscribe(response => {
      this.viewDetials = response;
      this.viewDetials = this.viewDetials.recipeUI;
      // console.log( this.viewDetials );
    });
    this.isback = 2;


  }

  filterPlans(type: any) {

    if (type == 1) {
      this.mealResult = this.ongoingMeal;
      this.isType = 1;
      this.draft = [];
    } else if (type == 2) {
      this.mealResult = this.completed;
      this.draft = [];
      this.isType = 2;
    }
  }

  tabShow(type: any) {

    if (type == 1) {
      this.tabtype = 1;
      if (!this.draft || this.draft.length > 0) {
        this.isType = 3;
        this.isIngredient = true;
        this.isCooking = false;
        return;
      }
      this.isIngredient = true;
      this.isCooking = false;
      //this.isType = 2;
    } else {
      this.isIngredient = false;
      this.isCooking = true;
      this.tabtype = 2;
      //this.isType = 3;
    }

  }

  backBtn() {
    this.showTitle = true;
    if (this.isback == 2) {
      this.isback = 1;

    } else {
      this.location.back();
    }
  }
  getType(unit: any, size: any, qty: any) {
    let title: any;
    // console.log(unit);
    if (unit === 'grams') {
      title = size + ' ' + unit;
    }
    else if (unit === 'gram') {
      title = size + ' ' + unit;
    } else {
      title = qty + ' ' + unit;
    }
    return title;
  }

  getDate(selectedTime: any) {
    const timeParts = selectedTime.split(':');
    const selectedDate = new Date();
    selectedDate.setHours(parseInt(timeParts[0], 10));
    selectedDate.setMinutes(parseInt(timeParts[1], 10));
    return this.datePipe.transform(selectedDate, 'hh:mm a');
  }
  getDateFormat(selectedTime: any) {
    // const timeParts = selectedTime.split(':');
    // console.log('here',selectedTime);
    const selectedDate = this.convertDateToUTC(new Date(selectedTime));

    return this.datePipe.transform(selectedDate, 'd MMM Y');
  }

  convertDateToUTC(date: any) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }

  assignMealPlan(memberId: string, mealPlanId: string) {
    this.mealService.assignMealPlan(memberId, mealPlanId).subscribe(
      (response: any) => {

        // Success
        console.log('Meal plan assigned successfully');
        this.getDraftMeals(); // Refresh the list
        // Check if there are any remaining drafts

        // No more drafts, navigate to ongoing meal plans
        window.location.href = `/all-meal-plans?memberId=${memberId}&isType=1`;


      }

    );
  }
}
