import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ControllerApiPath } from '../services/constant';
import { SharedServiceService } from '../services/shared-service.service';
import { Preference } from '../model/preference';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})

export class MealPlanServiceV2 {

  private apiBaseUrl: string;
  chatApi = environment.chatapi + '/mealplan';
  chatApiChat = environment.chatapi + '/mealplan/copilot/chat';
  chatApiUpdate = environment.chatapi + '/mealplan/meal';
  chatApiRecipeUpdate = environment.chatapi + '/mealplan/meal/recipe';

  constructor(private http: HttpClient, private sharedService: SharedServiceService) {
    this.apiBaseUrl = sharedService.baseUrl();
  }
  createMealPlan(mealDetails: any) {
    const headers = this.sharedService.createAuthorizatioChatAPInHeader();
    return this.http.post(this.chatApi, mealDetails, { headers }).pipe(timeout(600000),
      map((response) => response),
      catchError((error) => {
        console.log('error', error);
        return of(1);
      }),
    );
  }

  getAllMeals(memberId: any, date: string, type: string) {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.memberMealPlan + '/v2?memberId=' + memberId + '&date=' + date + '&type=' + type;
    return this.http.get(url, { headers });
  }

  getMealPlanDetails(id: any, fromDate: any, toDate: any) {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + 'mealplan/v2?planId=' + id + '&fromDate=' + fromDate + '&toDate=' + toDate;;
    return this.http.get(url, { headers }).pipe(
      map((response) => response),
      catchError((error) => {
        console.log("get meal plan details error", error);
        return of(1);
      }),
    );

  }

  sendMessage(mealDetails: any) {
    const headers = this.sharedService.createAuthorizatioChatAPInHeader();
    return this.http.post(this.chatApiChat, mealDetails, { headers }).pipe(timeout(600000),
      map((response) => response),
      catchError((error) => {
        console.log('chat error', error);
        return of(1);
      }),
    );
  }

  addFasting(payload: any) {
    const headers = this.sharedService.createAuthorizatioChatAPInHeader();
    return this.http.put(this.chatApiUpdate, payload, { headers }).pipe(timeout(600000),
      map((response) => response),
      catchError((error) => {
        console.log('update chat api error', error);
        return of(1);
      }),
    );
  }

  updateRecipe(payload: any) {
    const headers = this.sharedService.createAuthorizatioChatAPInHeader();
    return this.http.put(this.chatApiRecipeUpdate, payload, { headers }).pipe(timeout(600000),
      map((response) => response),
      catchError((error) => {
        console.log('update chat api recipe error', error);
        return of(1);
      }),
    );
  }

  deleteMealPlan(id: any) {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.memberMealPlan + '/v2?mealPlanId=' + id;
    return this.http.delete(url, { headers });
  }

  updateMemberMealPlan(payload: any) {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.memberMealPlan + '/v2';
    return this.http.put(url, payload, { headers }).pipe(
      map((response) => response),
      catchError((error) => {
        console.log(error);
        return of(1);
      }));
  }
}
