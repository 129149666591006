import { Component } from '@angular/core';
import { MemberService } from '../member-service/MemberService ';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';


@Component({
  selector: 'app-mealplan-history',
  templateUrl: './mealplan-history.component.html',
  standalone: true,
  imports: [MatTableModule, CommonModule, MatPaginatorModule, MatPaginator],
  styleUrls: ['./mealplan-history.component.css']
})


export class MealPlanHistoryComponent {
  documents: any[] = [];
  firstPage: number = 0;
  totalRecords: number = 0;
  pageSize: number = 10;
  planFilter: any = '0';
  memberId: any;
  dataSource = new MatTableDataSource<any>;
  displayedColumns: string[] = ['Name', 'FromDate', 'ToDate', 'AssignedBy', 'Status', 'Action'];
  isImage: boolean;
  modalDocumentURL: SafeResourceUrl;
  mealPlanHistory: any = [];

  constructor(private memberService: MemberService, private route: ActivatedRoute, private sanitizer: DomSanitizer) {

  }
  ngOnInit(): void {
    this.memberId = this.route.snapshot.queryParamMap.get('id') || '';
    this.getMealPlanHistory('0', 1);

  }
  getMealPlanUrl(memberId: string, row: any): string {
    if(row.isV2Plan){
      return 'view-edit-plan?memberId='+memberId+'&id='+row.mealPlanId+'&fromDate='+row.fromDate.split('T')[0]+'&toDate='+row.toDate.split('T')[0]+'&mode=0&flow=1';
    }
    var type = 1;
    if (row.unassigned) {
      type = 2;
    }
    else {
      if (row.currentStatus =='completed') {
        type = 2;
      }
      if (row.currentStatus == 'ongoing') {
        type = 1;
      }
    }
    return `view-meal-plan?memberId=${memberId}&mealplanId=${row.id}&isType=${type}`;
  }
  getMealPlanHistory(status: any, page: any) {
    this.memberService.getMemberMealPlan(this.memberId, status, page).subscribe(response => {
      this.mealPlanHistory = response;
      this.dataSource = this.mealPlanHistory.mealPlanHistoryList;
      // this.mealPlanHistory = this.mealPlanHistory.mealPlanHistoryList;
      //console.log(this.mealPlanHistory);
    });
  }
  pageChangeEvent(page: any) {
    //
    this.getMealPlanHistory(this.planFilter, page.pageIndex+1);
  }
  filterPlan(status: any) {
    this.planFilter = status.target.value;
    this.getMealPlanHistory(status.target.value, 1);
  }
}
