import { Component } from '@angular/core';
import { MemberService } from '../member-service/MemberService ';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { ToastService } from '../../services/toast.service';
 

@Component({
  selector: 'app-progress-notes',
  templateUrl: './progress-notes.component.html',
  standalone: true,
  imports: [MatTableModule, CommonModule, FormsModule, MatPaginatorModule, MatPaginator],
  styleUrls: ['./progress-notes.component.css']
})


export class ProgressNotesComponent {
  isAddNotes: boolean = false;
  isShowNotes: boolean = false;
  progressNotes: any = {
    'progressNotesId': '',
    'callSummary': '',
    'outCome': '',
    'planOfAction': '',
    'memberId': '',
    'createdBy': ''
  };
  progressNoteList: any = [];
  progressCount: any;
  rowNumber: any;
  summary: boolean = false;
  outcome: boolean = false;
  planaction: boolean = false;
  progressNoteDetails: any = {
    'callSummary': '',
    'outCome': '',
    'planOfAction': ''
  }
  progressNotesDetailpopup: boolean = false;
  documents: any[] = [];
  firstPage: number = 0;
  pageSize: number = 10;
  totalRecords: number = 0;
  memberId: any;
  dataSource = new MatTableDataSource<any>;
  displayedColumns: string[] = ['Date', 'Summary', 'Outcome','ActionPlan','AddedBy','Action'];
  isImage: boolean;
  modalDocumentURL: SafeResourceUrl;
  constructor(private toast: ToastService,private memberService: MemberService, private route: ActivatedRoute, private sanitizer: DomSanitizer) {

  }
  ngOnInit(): void {
    this.memberId = this.route.snapshot.queryParamMap.get('id') || '';
    this.getProgressNotes(1);
  }
  

  openForm() {
    this.isAddNotes = true;
    this.progressNotes.callSummary = '';
    this.progressNotes.planOfAction = '';
    this.progressNotes.outCome = '';
  }
  closeForm() {
    this.isShowNotes = false;
    this.isAddNotes = false;
    this.progressNotesDetailpopup = false;
    this.progressNotes.callSummary = '';
    this.progressNotes.planOfAction = '';
    this.progressNotes.outCome = '';
  }
  addProgressNotes() {
    if (this.progressNotes.callSummary == '' || this.progressNotes.planOfAction == '' || this.progressNotes.outCome == '') {
      this.toast.errorMessage('All field is required');
    } else {
      this.progressNotes.memberId = this.memberId;
      this.progressNotes.createdBy = this.memberId.id;
      this.memberService.addProgressPlan(this.progressNotes).subscribe(response => {
        // console.log('dfd'); 
        this.toast.successMessage('Progress notes added successfully.');
        //console.log('now'); 
        this.getProgressNotes(1);
        this.progressNotes.callSummary = '';
        this.progressNotes.planOfAction = '';
        this.progressNotes.outCome = '';
        this.isAddNotes = false;
      });
    }

  }

  getProgressNotes(page: any) {
    this.memberService.getProgressPlan(this.memberId, page).subscribe(response => {
      this.progressNoteList = response;
      this.dataSource = this.progressNoteList['results'];
      // console.log(this.progressNoteList.progressNotesWithUser[0]);
      this.progressCount = this.progressNoteList.totalCount;

    });
  }

  getNextNotesPage(id: any) {
    let pageNumber = id.page + 1;
    this.rowNumber = id.first;
    this.getProgressNotes(pageNumber);
  }

  toggleReadMore(type: any) {
    if (type == 1) {
      this.summary = !this.summary;
    }
    else if (type == 2) {
      this.outcome = !this.outcome;
    }
    else if (type == 3) {
      this.planaction = !this.planaction;
    }

  }

  getNotesView(id: string) {
    
    let details = this.progressNoteList.results.find((item: { id: string }) => item.id === id);
    this.progressNotes.callSummary = details.callSummary;
    this.progressNotes.outCome = details.outCome;
    this.progressNotes.planOfAction = details.planOfAction;
    this.isShowNotes = true;
  }
  pageChangeEvent(page: any) {
    //
    this.getProgressNotes(page.pageIndex);
  }

}
