import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule, formatDate } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MemberService } from '../member-service/MemberService ';
import { ActivityDataList, ActivityWorkooutDataList } from '../../models/activityData';

@Component({
  selector: 'activity-graph-graph',
  templateUrl: './activity-graph.component.html',
  standalone: true,
  imports: [
    FormsModule,
    MatTableModule,
    CommonModule,
    HighchartsChartModule,
    MatFormFieldModule,
    MatDatepickerModule,
  ],
  styleUrls: ['./activity-graph.component.css'],
})
export class ActivityGraphComponent {
  memberId: string = '';
  Highcharts: typeof Highcharts = Highcharts;
  dates: string[] = [];
  totalSteps: number[] = [];
  totalStepsDisplay: number[] = [];
  calBurn: number[] = [];
  chartOptions: Highcharts.Options = {};
  chartOptions1: Highcharts.Options = {};
  endDate: string = new Date().toISOString().split('T')[0];
  startDate: string = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
  currentDateBmi: any;
  previousDateBmi: any;
  noRecord: boolean = false;

  groupedActivities: { [key: string]: { calsBurned: number; mints: number; type: string }[] } = {};

  constructor(
    private memberService: MemberService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    var d = new Date();
    var p = new Date(new Date().setDate(new Date().getDate() - 7));
    this.currentDateBmi = d
    this.previousDateBmi = p;
  }

  ngOnInit(): void {

    this.memberId = this.route.snapshot.queryParamMap.get('id') || '';
    if (this.memberId) {
      this.getActivityMemberById(this.startDate, this.endDate);
    }
    this.createChart();
    this.workOutActivity(this.startDate, this.endDate);
  }
  getGroupedDates(): string[] {
    return Object.keys(this.groupedActivities);
  }
  filterByDateBmi(startDateInput: HTMLInputElement, endDateInput: HTMLInputElement): void {
    const start = startDateInput.value;
    const end = endDateInput.value;
    if (start && end) {
      this.startDate = start;
      this.endDate = end;
      this.getActivityMemberById(start, end);
      this.workOutActivity(start, end);
    }
  }

  getActivityMemberById(sDate: string, eDate: string): void {
    this.noRecord = false;
    if (!this.memberId) return;
    this.memberService.getActivityMemberById(this.memberId, sDate, eDate).subscribe((data: ActivityDataList) => {

      if (data && data.result) {
        this.dates = data.result.map((item) => item.dayDateStr);
        this.totalSteps = data.result.map((item) => (Number(item.totalSteps.toString().replace(/\s/g, '')) < 100 ? 200 : Number(item.totalSteps.toString().replace(/\s/g, ''))));
        this.totalStepsDisplay = data.result.map((item) => Number(item.totalSteps.toString().replace(/\s/g, '')));
        this.calBurn = data.result.map((item) => Number(item.totalCalsBurnt.toString().replace(/\s/g, '')));
        this.createChart();
      }
      if (data.result.length === 0) {
        this.noRecord = true;
      }
    });
  }

  workOutActivity(sDate: string, eDate: string) {
    this.memberService.getWorkoutActivityMemberById(this.memberId, sDate, eDate).subscribe((response: ActivityWorkooutDataList) => {
      if (!response.result || !Array.isArray(response.result)) {
        console.error("Invalid response format:", response);
        this.groupedActivities = {};
        return;
      }
      this.groupedActivities = response.result.reduce((acc, activity) => {
        if (!activity.date || !activity.data) return acc;
        const formattedDate = new Date(activity.date).toISOString().split('T')[0];
        activity.data.type = this.formatActivityName(activity.data.type);
        (acc[formattedDate] = acc[formattedDate] || []).push(activity.data);
        return acc;
      }, {} as { [key: string]: { calsBurned: number; mints: number; type: string }[] });
    });

  }
  formatActivityName(name: string): string {
    return name.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  createChart(): void {
    this.chartOptions = {
      chart: { type: 'column' },
      title: { text: 'Steps Count' },
      xAxis: { type: 'datetime', categories: this.dates },
      credits: { enabled: false },
      yAxis: { min: 0 },
      tooltip: {
        formatter: function () {
          const customData = this.point.options.custom ?? {};
          if (this.y === 0) {
            return false;
          }
          return this.series.name + ' : ' + customData['stepsText']
        },
        backgroundColor: 'rgba(0,0,0, 0.6)',
        borderWidth: 1,
        shadow: true,
        style: {
          color: '#fff',
          fontSize: '10pt',
          fontFamily: 'Poppins, sans-serif'
        }
      },
      plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
      series: [
        {
          name: 'Steps',
          type: 'column',
          data: this.totalSteps.map((value: number, index: number) => ({
            x: index,
            y: value,
            custom: {
              stepsText: this.totalStepsDisplay[index],

            }
          })) as Highcharts.PointOptionsObject[]
        }

      ],
    };

    this.chartOptions1 = {
      chart: { type: 'column' },
      title: { text: 'Calories Burned' },
      xAxis: { type: 'datetime', categories: this.dates },
      credits: { enabled: false },
      yAxis: { min: 0 },
      tooltip: {
        formatter: function () {
          if (this.y === 0) {
            return false;
          }
          return this.series.name + ' : ' + this.y + ' Kcal'
        },
        backgroundColor: 'rgba(0,0,0, 0.6)',
        borderWidth: 1,
        shadow: true,
        style: {
          color: '#fff',
          fontSize: '10pt',
          fontFamily: 'Poppins, sans-serif'
        }
      },
      plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
      series: [
        { name: 'Calories', type: 'column', data: this.calBurn, color: '#6D68DE' },
      ],
    };
  }
}
