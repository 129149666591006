<div id="wait" *ngIf="isLoading == 1">
    <mat-spinner></mat-spinner>
</div>
<app-meal-member-basic-details [memberDetail]="memberId"></app-meal-member-basic-details>
<div class="row" *ngIf="flow == 0">
    <img class="imgBack" (click)="backBtn()" src="assets/img/backbtn.png" />
</div>
<div [ngClass]="{
  'leftSection': mode == 1,
  'leftSection1': mode == 0 }">
    <div class="row mt-5" *ngIf="planDetails.length < 1 && isLoading == 2 && error == 0">
        <div class="col notFound">
            <p>Meal plan not found</p>
        </div>
    </div>
    <div class="row" *ngIf="planDetails.length > 0 && mode == 1">
        <div class="col-md-10"></div>
        <div class="col-md-2 assignBtnDiv"><button class="btn tabContent1" (click)="assignMealPlan()">Assign Meal
                Plan</button></div>
    </div>
    <div *ngFor="let plan of planDetails;let i = index;">
        <div class="row mt-5 ml-1" *ngIf="plan.meals.length > 0">
            <div class="col">
                <p class="dt">{{plan.date}}, {{plan.day}}
            </div>
            <div class="col textAlign " *ngIf="mode == 1">
                <div class="fastBtnDiv">
                    <button class="btn tabContent" (click)="openFastingModal(i, plan.date, plan.day,null)">Add Fasting
                        Window
                    </button>
                </div>
            </div>
        </div>
        <div *ngFor="let meal of plan.meals">
            <div class="col mt-3">
                <p class="dt1">{{meal.mealType}}, Time : {{meal.mealTime}}</p>
            </div>
            <div class="row">
                <div [ngClass]="{'col-sm-4 mt-1': mode == 0,'col-sm-6 mt-1': mode == 1 }"
                    *ngFor="let rec of meal.mealRecipe">
                    <div class="tiles mt-2">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="pack" *ngIf="rec.recipeType == 'Packaged'">
                                    <p style="padding: 10px;">Packaged Food</p>
                                </div>
                                <div class="rest" *ngIf="rec.recipeType == 'Restaurant'">
                                    <p style="padding: 10px;">Restaurant Food</p>
                                </div>
                                <div class="diy" *ngIf="rec.recipeType == 'DIY'">
                                    <p style="padding: 10px;">Do It Yourself</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div *ngIf="mode == 1">
                                    <img class="editImg" src="assets/img/edit.png" /><label class="editLabel"
                                        (click)="viewEditRecipe(plan,meal,rec)">Edit</label>
                                    <img class="selectImg" id="{{meal.mealId+'_'+rec.recipeId}}"
                                        (click)="selectImg($event,'recipe')" src="assets/img/unselect.png" />
                                </div>
                                <div *ngIf="mode == 0">
                                    <img class="editImg" src="assets/img/view.png" /><label class="editLabel"
                                        (click)="viewEditRecipe(plan,meal,rec)">View</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col mealName">{{rec.recipeName}}</div>
                        </div>
                        <div class="row mt-3">
                            <div class="col recDetails"><span>{{rec.measurement.value}}
                                    {{rec.measurement.unit}}</span>&nbsp;<span><img width="10"
                                        src="assets/img/calories.png" /> </span>&nbsp;<span>{{rec.nutrition.calories}}
                                    Kcal</span>
                                &nbsp;<span><img width="8"
                                        src="assets/img/protien-old.png" /></span>&nbsp;<span>{{rec.nutrition.protein}}
                                    g of Protein</span>
                                &nbsp;<span><img width="10"
                                        src="assets/img/carbs.png" /></span>&nbsp;<span>{{rec.nutrition.carbs}} g of
                                    Carbs</span>
                                &nbsp;<span><img width="10"
                                        src="assets/img/fat.png" /></span>&nbsp;<span>{{rec.nutrition.fat}} g of
                                    Fats</span>
                            </div>
                        </div>
                        <div class="row mt-4" *ngIf="rec.restaurantRecipeData != null">
                            <div class="col-lg-6" [ngClass]="{'ingrDetails1': i % 2 === 0, 'ingrDetails': i % 2 !== 0}"
                                *ngFor="let ingr of rec.restaurantRecipeData.ingredients; let i = index">
                                {{ingr.ingredientName}} ({{ingr.measurement.quantity}} {{ingr.measurement.unit}})
                            </div>
                        </div>
                        <div class="row mt-4" *ngIf="rec.packagedRecipeData != null">
                            <div class="col-lg-6" [ngClass]="{'ingrDetails1': i % 2 === 0, 'ingrDetails': i % 2 !== 0}"
                                *ngFor="let ingr of rec.packagedRecipeData.ingredients; let i = index">
                                {{ingr.ingredientName}} ({{ingr.measurement.quantity}} {{ingr.measurement.unit}})
                            </div>
                        </div>
                        <div class="row mt-4" *ngIf="rec.diyRecipeData != null">
                            <div class="col-lg-6" [ngClass]="{'ingrDetails1': i % 2 === 0, 'ingrDetails': i % 2 !== 0}"
                                *ngFor="let ingr of rec.diyRecipeData.ingredients; let i = index">
                                {{ingr.ingredientName}} ({{ingr.measurement.quantity}} {{ingr.measurement.unit}})
                            </div>
                        </div>
                        <div class="row mt-4" *ngIf="rec.packagedRecipeData != null">
                            <div class="col-md-7"></div>
                            <div class="col-md-5 restDetails1" *ngIf="rec.packagedRecipeData.stores?.length > 0">
                                Available in <span *ngFor="let item of rec.packagedRecipeData.stores">
                                    {{item.storeName}}
                                </span>
                            </div>
                        </div>

                        <div class="row mt-3" *ngIf="rec.restaurantRecipeData != null">
                            <div class="col resCollapse">Restaurant Details &nbsp;<img width="13"
                                    (click)="changeImg(rec.recipeId+meal.mealId)" id={{rec.recipeId+meal.mealId}}
                                    src="assets/img/down1.png" />
                            </div>
                        </div>
                        <div class="collapse" id="{{rec.recipeId+meal.mealId+'col'}}"
                            *ngIf="rec.restaurantRecipeData != null">
                            <div class="card card-body colapse">
                                <div class="row">
                                    <div class="col">
                                        <span class="restDetails">Name:</span> &nbsp;<span
                                            class="restDetails1">{{rec.restaurantRecipeData.restaurantName}}</span><br />
                                        <span class="restDetails">Address:</span> &nbsp;<span
                                            class="restDetails1">{{rec.restaurantRecipeData.location}}</span><br />
                                        <span class="restDetails">Phone:</span> &nbsp;<span
                                            class="restDetails1">{{rec.restaurantRecipeData.phoneNumber}}</span><br />
                                        <span class="restDetails"
                                            *ngIf="rec.restaurantRecipeData.website!='' && rec.restaurantRecipeData.website!=null">
                                            <a href="{{rec.restaurantRecipeData.website}}"
                                                target="_blank">Website</a></span> &nbsp;&nbsp;<span class="restDetails"
                                            *ngIf="rec.restaurantRecipeData.mapGeoLocation!='' && rec.restaurantRecipeData.mapGeoLocation!=null"><a
                                                href="{{rec.restaurantRecipeData.mapGeoLocation}}">Map</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="plan.fastings.length > 0">
            <div class="col mt-3">
                <p class="dt1">Fasting</p>
            </div>
            <div class="row">
                <div [ngClass]="{'col-sm-4 mt-1': mode == 0,'col-sm-6 mt-1': mode == 1 }"
                    *ngFor="let fast of plan.fastings">
                    <div class="tileFast">
                        <div class="row">
                            <div class="col-2">
                                <img width="60" src="/assets/img/fastingIcon.png" />
                            </div>
                            <div class="col-6"></div>
                            <div class="col-md-4" *ngIf="mode == 1">
                                <img class="editImg" src="assets/img/edit.png" /><label class="editLabel"
                                    (click)="openFastingModal(i, plan.date, plan.day, fast)">Edit</label>
                                <!-- <img class="selectImg" id="{{fast.id+'_'+plan.day+'_'+plan.date}}"
                                    (click)="selectImg($event,'fasting')" src="assets/img/unselect.png" /> -->
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <div class="col"><span class="dt1">From:</span>&nbsp;&nbsp;<span
                                        class="dt2">{{fast.fromTime}}</span> </div>
                            </div>
                            <div class="col-md-12 mt-2">
                                <div class="col"><span class="dt1">To:</span>&nbsp;&nbsp;<span
                                        class="dt2">{{fast.toTime}}</span></div>
                            </div>
                            <div class="col-md-12 mt-2">
                                <div class="col"><span class="dt1">Duration:</span>&nbsp;&nbsp;<span
                                        class="dt2">{{convertSecondsToHoursAndMinutes(fast.duration)}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="width:30%;float: left;height: 580px;" *ngIf="mode==1">
    <div class="col chatMainDiv" *ngIf="showHideChatBot">
        <div class="row">
            <div class="col">
                <label class="aiHeader">Meal Plan Co-pilot</label>
            </div>
            <div class="col">
                <img width="20" (click)="hideChatBot()" src="assets/img/close.png" class="chatHide" />
            </div>
        </div>

        <div class="row" style="height: 465px;">
            <div class="col-md-12" style="height: 410px; overflow-y: auto;">

                <div *ngFor="let message of messages" class="message">
                    <div class="message-content"
                        [ngClass]="{ 'user': message.sender === 'user', 'bot': message.sender === 'assistant' }">

                        <strong>{{ message.sender === 'user' ? 'You: ' : 'Bot: ' }}</strong>
                        <span id={{message.id}}>{{ message.text }}</span>
                    </div>
                </div>

            </div>
            <div class="col-md-12" style="height: 50px;border-radius: 15px;border: 1px solid #d5d5d5;">
                <input type="text" [(ngModel)]="userMessage" (keyup.enter)="sendMessage()"
                    class="form-control botInput " placeholder="Type here" />
                &nbsp;&nbsp;<img width="40" src="assets/img/send.png" (click)="sendMessage()"
                    style="margin-top: 5px;" />
            </div>
        </div>
    </div>
    <div class="col"><img class="chatBotBtn" width="60" (click)="openChatBot()" src="assets/img/ai.png" /></div>
</div>

<div class="modal fade" id="confirmAssign" tabindex="-1" role="dialog" aria-labelledby="confirmAssignLabel"
    aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content modal-size-style radius">
            <div class="modal-header text-center">
                <span class="title1  w-100">Meal plan already Exist!</span>
                <button type="button" class="close" aria-label="Close" data-bs-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body textCenter padding">
                <p class="modalText">You already have an active meal plan for the selected date.
                    Would you like to remove or change the date for the existing meal
                    plan?</p>
                <br />
                <span><button class="btnConfirm btnLabel" (click)="showcheckAssign()">Yes,
                        Update the existing meal plan</button></span><span>
                    <button class="btnConfirm1 btnLabel1" (click)="closeConfirmAssign()">No,
                        Keep the existing meal plan</button> </span>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="checkAssign" tabindex="-1" role="dialog" aria-labelledby="checkAssignLabel"
    aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-size-style radius">
            <div class="modal-header text-center">
                <span class="title1  w-100">Confirm Date</span>
                <button type="button" class="close" aria-label="Close" data-bs-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body textCenter">
                <p class="modalText">New Meal Plan: {{this.mealPlanName}}</p>
                <p class="modalSubText">{{this.newMealPlan?.fromDate.split('T')[0]}} -
                    {{this.newMealPlan?.toDate.split('T')[0]}}
                </p>
                <p class="modalText">Existing Meal Plan: {{this.exisitngMealPlanName}}</p>
                <p class="modalSubText">{{this.existingMealPlan?.fromDate.split('T')[0]}} -
                    {{this.existingMealPlan?.toDate.split('T')[0]}}
                </p>
                <p class="modalText">Please select new end date for existing meal plan</p>
                <p><mat-form-field>
                        <mat-label>Pick end date</mat-label>
                        <input matInput [matDatepicker]="picker2" #dateRangeEnd2 [(ngModel)]="currentDate"
                            [min]="minDate" (dateChange)="setMemMealDate(dateRangeEnd2)">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field></p>
                <p class="modalSubText">You End Date will be updated to {{memMealDate}} </p>
                <button class="col-md-2 btn btnSec btnLabel1" (click)="updateAndAssign()">Confirm</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="fastingModal" tabindex="-1" aria- labelledby="fastingModalLabel" data-bs-backdrop="static"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-size-style radius">
            <div class="modal-header">
                <span class="title1">Add Fasting Window</span>
                <button type="button" class="close" aria-label="Close" data-bs-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mt-1">
                    <div class="col modalText">{{fastingDate}}, {{fastingDay}}</div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm">
                        <span class="modalSubText">From Time:</span>
                        <input type="time" [(ngModel)]="fastingStartTime" [ngModelOptions]="{standalone: true}"
                            placeholder="Start Date" class="form-control fastingField" />
                    </div>
                    <div class="col-sm">
                        <span class="modalSubText">To Time:</span>
                        <input type="time" [(ngModel)]="fastingEndTime" [ngModelOptions]="{standalone: true}"
                            placeholder="End Date" class="form-control fastingField" />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn fastBtn btnLabel1" (click)="saveFastingWindow()">Submit</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="editModal" tabindex="-1" aria- labelledby="editModalLabel" data-bs-backdrop="static"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content modal-size-style radius">
            <div class="modal-header">
                <span class="title1">{{mode==1?'Edit Details':'Details'}}</span>
                <button type="button" class="close" aria-label="Close" data-bs-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="editRecipeObj">
                    <div class="row mt-1">
                        <div class="col-md-12">
                            <span class="editSubText">Name</span>
                            <input type="text" [disabled]="mode==0" [(ngModel)]="editRecipeObj.recipeName" class="form-control editField" />
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-md-2">
                            <span class="editSubText">Calories (Kcal)</span>
                            <input type="number" [disabled]="mode==0" onkeydown="return event.keyCode !== 69"
                                [(ngModel)]="editRecipeObj.nutrition.calories" class="form-control editField1" />
                        </div>
                        <div class="col-md-2">
                            <span class="editSubText">Protein (grams)</span>
                            <input type="number" [disabled]="mode==0" onkeydown="return event.keyCode !== 69"
                                [(ngModel)]="editRecipeObj.nutrition.protein" class="form-control editField1" />
                        </div>
                        <div class="col-md-2">
                            <span class="editSubText">Carbs (grams)</span>
                            <input type="number" [disabled]="mode==0" onkeydown="return event.keyCode !== 69"
                                [(ngModel)]="editRecipeObj.nutrition.carbs" class="form-control editField1" />
                        </div>
                        <div class="col-md-2">
                            <span class="editSubText">Fats (grams)</span>
                            <input type="number" [disabled]="mode==0" onkeydown="return event.keyCode !== 69"
                                [(ngModel)]="editRecipeObj.nutrition.fats" class="form-control editField1" />
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col title1">Ingredients</div>
                    </div>
                    <div class="row mt-3" *ngFor="let ingr of editRecipeObj.ingredients; let i = index">
                        <div class="col-md-5">
                            <span class="editSubText">Name of the Recipe</span>
                            <input type="text" [disabled]="mode==0" [(ngModel)]="ingr.name" class="form-control editField2 ht" />
                        </div>
                        <div class="col-md-2">
                            <span class="editSubText">Number of Serving</span>
                            <input type="number" [disabled]="mode==0" onkeydown="return event.keyCode !== 69" [(ngModel)]="ingr.quantity"
                                class="form-control editField1 ht" />
                        </div>
                        <div class="col-md-3">
                            <span class="editSubText">Serving Unit</span>
                            <input type="text" [disabled]="mode==0" (input)="inputChange($event)" [(ngModel)]="ingr.unit"
                                class="form-control editField ht" />
                            <span *ngIf="mode==1"><img src="assets/img/minus.png" width="30" (click)="deleteIngr(i)"
                                    style="cursor: pointer;margin-left: 135px;margin-top: -79px;" /></span>
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="mode==1">
                        <div class="col">
                            <img src="assets/img/plus.png" width="30" (click)="addIngr()" style="cursor: pointer;" />
                        </div>
                    </div>
                    <div *ngIf="editRecipeObj.recipeType == 'Packaged'">
                        <div class="row mt-4">
                            <div class="col-md-12 title1">
                                Brand details
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-3">
                                <span class="editSubText">Name</span>
                                <input type="text" [disabled]="mode==0" [(ngModel)]="editRecipeObj.brand.brandName"
                                    class="form-control editField2" />
                            </div>
                            <div class="col-md-6">
                                <span class="editSubText">Barcode</span>
                                <input type="text" [disabled]="mode==0" [(ngModel)]="editRecipeObj.brand.barCode"
                                    class="form-control editField2" />
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-md-12 title1">
                                Available in
                            </div>
                        </div>
                        <div class="row mt-2" *ngFor="let store of editRecipeObj.stores;let i=index">
                            <div class="col-md-3">
                                <span class="editSubText">Name</span>
                                <input type="text" [disabled]="mode==0"  [(ngModel)]="store.storeName" class="form-control editField4" />
                            </div>
                            <div class="col-md-4">
                                <span class="editSubText">Location</span>
                                <input type="text" [disabled]="mode==0"  [(ngModel)]="store.location" class="form-control editField4" />
                            </div>
                            <div class="col-md-4">
                                <span class="editSubText">Website</span>
                                <input type="text" [disabled]="mode==0"  [(ngModel)]="store.website" class="form-control editField4" />
                                <span *ngIf="mode==1"><img src="assets/img/minus.png" width="30" (click)="deleteStore(i)"
                                        style="margin-left: 285px;margin-top: -80px;cursor: pointer;" /></span>
                            </div>
                        </div>

                        <div class="row mt-1" *ngIf="mode==1">
                            <div class="col">
                                <img src="assets/img/plus.png" width="30" (click)="addStore()"
                                    style="cursor: pointer;" />
                            </div>
                        </div>
                    </div>
                    <div *ngIf="editRecipeObj.recipeType == 'DIY'">
                        <div class="row mt-4">
                            <div class="col-md-12 mt-2 title1">
                                Cooking Methods
                            </div>
                        </div>
                        <div class="row" *ngFor="let method of editRecipeObj.cookingMethods;let i=index">
                            <div class="col-md-8">
                                <input type="text" [disabled]="mode==0" [(ngModel)]="method.method" class="form-control editField2" />
                                <span *ngIf="mode==1"><img src="assets/img/minus.png" width="30" (click)="deleteMethod(i)"
                                        style="margin-left: 680px;margin-top: -77px;cursor: pointer;" /></span>
                            </div>
                        </div>
                        <div class="row" *ngIf="mode==1">
                            <div class="col">
                                <img src="assets/img/plus.png" width="30" (click)="addMethod()"
                                    style="cursor: pointer;" />
                            </div>
                        </div>
                    </div>
                    <div *ngIf="editRecipeObj.recipeType == 'Restaurant'">
                        <div class="row mt-4">
                            <div class="col-md-12 title1">
                                Restaurant Details
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-6">
                                <span class="editSubText">Name</span>
                                <input type="text" [disabled]="mode==0"  [(ngModel)]="editRecipeObj.restaurant.name"
                                    class="form-control editField2" />
                            </div>
                            <div class="col-md-6">
                                <span class="editSubText">Location</span>
                                <input type="text" [disabled]="mode==0"  [(ngModel)]="editRecipeObj.restaurant.location"
                                    class="form-control editField2" />
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-6">
                                <span class="editSubText">Website</span>
                                <input type="text" [disabled]="mode==0"  [(ngModel)]="editRecipeObj.restaurant.website"
                                    class="form-control editField2" />
                            </div>
                            <div class="col-md-6">
                                <span class="editSubText">Phone Number</span>
                                <input type="text" [disabled]="mode==0"  [(ngModel)]="editRecipeObj.restaurant.phoneNumber"
                                    class="form-control editField2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button *ngIf="mode==1" class="btn fastBtn btnLabel1" (click)="updateRecipe()">Save Changes</button>
                <button *ngIf="mode==0" class="btn fastBtn btnLabel1" (click)="closeEditModal()">Close</button>
            </div>
        </div>
    </div>
</div>