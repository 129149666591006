import { Component } from '@angular/core';
import { MemberService } from '../member-service/MemberService ';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';

@Component({
  selector: 'app-dietary-preference',
  standalone: true,
  imports: [MatTableModule, CommonModule, MatPaginatorModule, MatPaginator],
  templateUrl: './dietary-preference.component.html',
  styleUrl: './dietary-preference.component.css'
})
export class DietaryPreferenceComponent {
  documents: any[] = [];
  firstPage: number = 0;
  pageSize: number = 5;
  totalRecords: number = 0;
  memberId: any;
  dataSource = new MatTableDataSource<any>;
  displayedColumns: string[] = ['CreatedDateTime', 'Type', 'Actions'];
  isImage: boolean;
  modalDocumentURL: SafeResourceUrl;
  constructor(private memberService: MemberService, private route: ActivatedRoute, private sanitizer: DomSanitizer) {

  }
  ngOnInit(): void {
    this.memberId = this.route.snapshot.queryParamMap.get('id') || '';
    this.getLabWork(1);
  }
  getLabWork(page: any) {
    this.memberService.GetLabWork(this.memberId, page).subscribe(data => {
      const documentsdata = data;
      this.dataSource = data['result'];
      this.totalRecords = data['totalCount'];
    });
  }
  openModal(document: any) {
    if (document.toLowerCase().endsWith('.jpeg') || document.toLowerCase().endsWith('.jpg')) {
      this.isImage = true;
    } else if (document.toLowerCase().endsWith('.pdf')) {
      this.isImage = false;
    } else {
      console.log('The document no supported. Try again');
    }
    this.modalDocumentURL = this.sanitizer.bypassSecurityTrustResourceUrl(document);

  }
  pageChangeEvent(page: any) {
    //page.pageIndex
    this.getLabWork(page.pageIndex+1);
  }
}
