<div class="mt-3">
  <mat-form-field>
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Start date" [(ngModel)]="previousDateBmi" #dateRangeStartBmi>
      <input matEndDate placeholder="End date" [(ngModel)]="currentDateBmi" #dateRangeEndBmi (dateChange)="filterByDateBmi(dateRangeStartBmi, dateRangeEndBmi)">
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <div *ngIf="noRecord" style="display:inline;">
    No Record Found.
  </div>
</div>

<div style="width: 80%;">
  <!-- <p-chart type="line" [data]="bmiReadings" [options]="bmiOptions"></p-chart> -->
  <highcharts-chart [Highcharts]="Highcharts"
                    [options]="chartOptions"
                    style="width: 100%; height: 100%; display: block;"></highcharts-chart>
</div>
<div style="width: 80%;">
  <!-- <p-chart type="line" [data]="bmiReadings" [options]="bmiOptions"></p-chart> -->
  <highcharts-chart [Highcharts]="Highcharts"
                    [options]="chartOptions1"
                    style="width: 100%; height: 100%; display: block;"></highcharts-chart>
</div>
<br />
 
<div class="activity-section">
  <h2 class="section-heading">Activity Trend</h2>

  <div *ngFor="let date of getGroupedDates()" class="date-group">
    <br />
    <p class="date-heading">{{ date }}</p>
    <div class="activity-container">
      <div class="activity-card" *ngFor="let activity of groupedActivities[date]">
        <div class="icon">
          <img src="/assets/img/activity.png" alt="Activity">
        </div>
        <div class="details">
          <p><strong>{{ activity.calsBurned }}</strong> Kcal</p>
          <p>{{ activity.type }}</p>
        </div>
        <div class="details">
          <p><strong>{{ activity.mints }}</strong> mins</p>
          <p>Total time duration</p>
        </div>
      </div>
    </div>
  </div>
</div>



<br />
