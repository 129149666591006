
<div class="col-md-6">
  <div class="d-flex flex-column" style="float:left">
    <label for="dateInput">Filter:</label>
    <select class="form-control" (change)="filterPlan($event)" >     
      <option value="0">All</option>
      <option value="1">Completed</option>
      <option value="2">Scheduled</option>
      <option value="3">Ongoing</option>
      <option value="4">Unassigned</option>
      <option value="5">Draft</option>
    </select>
  </div>
</div>
<table mat-table [dataSource]="dataSource">
  ['Name', 'FromDate','ToDate','AssignedBy','Status', 'Action'];
  <ng-container matColumnDef="Name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element">{{element.mealPlanName }}  </td>
  </ng-container>
  <ng-container matColumnDef="FromDate">
    <th mat-header-cell *matHeaderCellDef> From Date </th>
    <td mat-cell *matCellDef="let element">{{element.fromDate | date: 'dd-MM-yyyy' }}  </td>
  </ng-container>
  <ng-container matColumnDef="ToDate">
    <th mat-header-cell *matHeaderCellDef> To Date </th>
    <td mat-cell *matCellDef="let element">{{element.toDate | date: 'dd-MM-yyyy' }}  </td>
  </ng-container>
  <ng-container matColumnDef="AssignedBy">
    <th mat-header-cell *matHeaderCellDef> Assigned By </th>
    <td mat-cell *matCellDef="let element">{{element.assignedBy  }}  </td>
  </ng-container>
  <ng-container matColumnDef="Status">
    <th mat-header-cell *matHeaderCellDef> Status</th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="!element.unassigned">{{ element.currentStatus }}</ng-container>
      <ng-container *ngIf="element.unassigned">unassigned</ng-container>
    </td>

  </ng-container>

  <ng-container matColumnDef="Action">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let row" class="flex">
      <a [href]="getMealPlanUrl(memberId, row)" target="_blank" class="memberDashboard1"  >View</a>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div style="text-align:center;display:flex;">
  <mat-paginator #paginator
                 (page)="pageChangeEvent($event)"
                 [length]="this.mealPlanHistory['count']"
                 [pageSize]="pageSize"
                 [pageIndex]="0"
                 showFirstLastButton>
  </mat-paginator>
</div>
