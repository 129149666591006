import { Component, Input, SimpleChanges } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NotesComponent } from '../notes/notes.component';
import { MemberDashboardService } from '../member-dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';
import { CommonModule, formatDate } from '@angular/common';
import moment from 'moment';
import { NgIf } from '@angular/common';
import { MatDateRangeInput, MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule, MatHint, MatLabel } from '@angular/material/form-field';
declare var $: any;

@Component({
  selector: 'app-bmi-chart',
  standalone: true,
  imports: [CommonModule,FormsModule,MatCheckboxModule, NotesComponent, MatNativeDateModule, MatDateRangeInput, MatHint, MatLabel, MatFormFieldModule, MatDatepickerModule, HighchartsChartModule, NgIf],
  templateUrl: './bmi-chart.component.html',
  styleUrl: './bmi-chart.component.css'
})
export class BMIChartComponent {


  @Input() sDate: any;
  @Input() eDate: any;
   
  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options = {};
  
  tZone: any;
  currentDateBmi: any;
  previousDateBmi: any; 
  
  timeZone: any;   
  activityName: any;
  calsBurned: any;
  distCovered: any;
  distUnits: any = "Km";
  foodName: any;
  foodCals: any;
  foodCarbs: any;
  foodProteins: any;
  foodFats: any;
  memberId: any = this.route.snapshot.queryParamMap.get('memberId');
  bmiWeightType: any = 1;
  weightByKg: any = [];
  kgWeight: any = [];
  weightByPound: any = [];
 // BMIVals: any = [];
  updateFlag: boolean = false; // optional boolean
  oneToOneFlag: boolean = true; // optional boolean, defaults to false
  runOutsideAngular: boolean = false; // optional boolean, defaults to false
  endDate: any = formatDate(Date.now(), 'Y-MM-d', 'en');
  startDate: any = formatDate(new Date().setDate(new Date().getDate() - 7), 'Y-MM-d', 'en');
   
  vitalDetails: any;
  bmiData: any = [];  
  BMIVals: any = [];
  bmiDetails: any = [];
  bmiValues: any = [];
  chartCallback: Highcharts.ChartCallbackFunction = function (chart) { } // optional function, defaults to null
  constructor(private dashboard: MemberDashboardService, private route: ActivatedRoute) {
    var d = new Date();
    var p = new Date(new Date().setDate(new Date().getDate() - 7));
    this.currentDateBmi = d
    this.previousDateBmi = p;
  }
  ngOnChanges() {
    this.getBMIDetails(this.startDate, this.endDate);
  }
  filterByDateBmi(StartDate: HTMLInputElement, endDate: HTMLInputElement) {
    if (StartDate.value != '' && endDate.value != '') {
      this.startDate = StartDate.value;
      this.endDate = endDate.value;
      this.getBMIDetails(StartDate.value, endDate.value);
    }
  }
  
  getBMIDetails(sDate: any, eDate: any) {    
    this.dashboard.getBmiReadings(this.memberId, sDate, eDate).subscribe(data => {
      this.bmiData = data;      
      const bmiData = [];
      this.BMIVals = [];
      let timeOnly: any;
      let timeFormat: any;
      let onlyLabel: any = [];
      this.weightByPound = [];
      this.bmiDetails = [];
      for (let i = 0; i < this.bmiData.length; i++) {        
        var currentDate = formatDate(this.bmiData[i].timeStamp, 'yyyy-MM-dd HH:mm:ss', 'en');        
        var date = Date.parse(currentDate);
        
        timeOnly = this.bmiData[i].timeStamp.split('T');
        timeOnly = timeOnly[1].split('.');

        timeOnly = timeOnly[0].split(':');
        timeFormat = this.changeTimeFormat(timeOnly[0], timeOnly[1]);
        
        this.bmiValues.push(this.bmiData[i].bmiValue);
        this.bmiDetails.push({ 'bmiValue': this.bmiData[i].bmiValue, 'weight': this.bmiData[i].weight, 'date': currentDate });
        if (this.bmiData[i].weightUnit == 'kg') {
          this.weightByPound.push([date, this.bmiData[i].weight * 2.205]);
        } else {
          this.weightByPound.push([date, this.bmiData[i].weight]);
        }

        bmiData.push([date, this.bmiData[i].weight]);
        this.BMIVals.push([date, this.bmiData[i].bmiValue]);

      }
      this.kgWeight = bmiData.map(i => i);
      this.weightByKg = bmiData;
      this.bmiWeightType = 1;
      this.createGraph(bmiData);
     

    });

  }
  changeTimeFormat(hours: any, minutes: any) {



    // Check whether AM or PM
    let newformat = hours >= 12 ? 'PM' : 'AM';

    // Find current hour in AM-PM Format
    hours = hours % 12;

    // To display "0" as "12"
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '' + minutes : minutes;

    return hours + ':' + minutes + ' ' + newformat;
  }

  changeBMIWeightType(data: any) {
    let type: any;

    let bmiData = [];
    if (data.target.value == 1) {
      this.bmiWeightType = 1;
      this.createGraph(this.kgWeight);
    
      // bmiData  = this.kgWeight;
      type = 'Kg';
    } else {
      this.bmiWeightType = 2;
      this.createGraph(this.weightByPound);
      // bmiData  = this.weightByPound;
      type = 'pound';
    }
    
  }
  createGraph(bmi: any) {     
    let tZone = this.tZone;
    let metric = this.bmiWeightType == 1 ? 'kg' : 'Pound';
    console.log('metric' + metric);
    this.chartOptions = {
      chart: {
        type: 'line'
      },
      title: { text: 'BMI & Weight Readings' },
      xAxis: [{
        type: 'datetime',        
      }],
      credits: {
        enabled: false
      },
      yAxis: [{
        lineColor: '#089bab',
        lineWidth: 1,
        title: {
          text: 'Weight'
        }
      }, {
        opposite: true,
        lineColor: '#E58009',
        lineWidth: 1,
        title: {
          text: 'BMI'
        }
      }],
      tooltip: {
        formatter: function () {
          if (this.x !== undefined) {
            let conD = new Date(this.x).toLocaleString('en-US', { timeZone: tZone });
            let d = moment(conD).format('ddd, MMM DD');
            let t = moment(conD).format('HH:mm:ss');
            if (this.series.name == 'BMI') {
              return d + ' at ' + t + '</b> <br>' + this.series.name + ' : <b>' + this.y?.toFixed(2) + '</b>';
            } else {
              return d + ' at ' + t + '</b> <br>' + this.series.name + ' : <b>' + this.y?.toFixed(2) + ' ' + metric + '</b>';
            }
          }
          return 'BMI/Weight: ' + this.y?.toFixed(2);
        },
        // valueDecimals: 2,
        backgroundColor: 'rgba(0,0,0, 0.6)',
        borderWidth: 1,
        shadow: true,
        style: {
          color: '#fff',
          fontSize: '12pt',
          fontFamily: 'Poppins, sans-serif'
        }
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: false
          },
          enableMouseTracking: true
        },
        series: {
          marker: {
            symbol: 'circle',
            fillColor: '#000408',
            enabled: true,
            radius: 3,
            lineWidth: 1,

          }
        },
        // tooltip: {
        //         x: {
        //             format: 'dd/MM/yy HH:mm' 
        //         },
        //     },
      },


      series: [{
        name: 'Weight',
        data: bmi,
        type: 'line',
        color: '#089bab',

      }, {
        name: 'BMI',
        data: this.BMIVals,
        yAxis: 1,
        type: 'line',
        color: '#E58009',

      }

      ],

      // tooltip: {

      //         format: 'dd/MM/yy HH:mm' 

      // },
    };
    // console.log(this.chartOptions);
    this.chartOptions.time = {
      timezone: tZone
    }
  }
}
