import { Component, Input, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { NotesComponent } from '../notes/notes.component';
import { GlucoseValueComponent } from './glucose-value/glucose-value.component';
import { MemberDashboardService } from '../member-dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';
import { formatDate } from '@angular/common';
import moment from 'moment';
import { NgIf } from '@angular/common';
declare var $: any; 

@Component({
  selector: 'app-glucose',
  standalone: true,
  imports: [MatCheckboxModule,NotesComponent,GlucoseValueComponent,HighchartsChartModule,NgIf],
  templateUrl: './glucose.component.html',
  styleUrl: './glucose.component.css'
})
export class GlucoseComponent {


  @Input() glucoseDetails:any;
  @Input() memberbasic:any;
  Highcharts: typeof Highcharts = Highcharts;
   
  chartOptions: Highcharts.Options = {};
  postMeal:any=[];
  cgm:any=[];
  timeLines:any=[];
  fasting:any=[];
  timeZone:any;
  showModal:boolean=false;
  modalType:any;
  modalSTime:any;
  modalETime:any;
  modalDuration:any;
  modalImg:any;
  activityName:any;
  calsBurned:any;
  distCovered:any;
  distUnits:any = "Km";
  foodName:any;
  foodCals:any;
  foodCarbs:any;
  foodProteins:any;
  foodFats:any;
  memberId:any=this.route.snapshot.queryParamMap.get('memberId');
  constructor(private dashboard:MemberDashboardService,private route:ActivatedRoute) {
  
  }
   ngOnChanges() {
        this.fasting=[];
        this.postMeal=[];
        this.cgm=[];
        this.timeLines=[];

      if(this.glucoseDetails.readings?.length > 0 || this.glucoseDetails.timeLine?.length > 0){
        this.timeZone = this.glucoseDetails.timeZone;
        let lastReading = 0;
        let lastTimeLines = [];
        for(let i=0;i<this.glucoseDetails.timeLine.length;i++){
          let reading = {
            timeStampKeyVal: this.glucoseDetails.timeLine[i].date,
            reading: "timeLine",
            platform:"NA",
            type: this.glucoseDetails.timeLine[i].type,
            data: this.glucoseDetails.timeLine[i].data
          };
          this.glucoseDetails.readings.push(reading);
        }
        this.glucoseDetails.readings = this.glucoseDetails.readings.sort((a:any,b:any) => {
          return new Date(a.timeStampKeyVal).getTime() - new Date(b.timeStampKeyVal).getTime();
        } );
         for(let i=0;i<this.glucoseDetails.readings.length;i++){
           var currentDate = formatDate(this.glucoseDetails.readings[i].timeStampKeyVal, 'yyyy-MM-dd HH:mm:ss', 'en');
         var dateStamp = Date.parse(currentDate);
         if(this.glucoseDetails.readings[i].reading.toUpperCase() == 'FASTING' &&
          (this.glucoseDetails.readings[i].platform.toUpperCase() == "IOS"
           || this.glucoseDetails.readings[i].platform.toUpperCase() == "ANDROID")){
              this.fasting.push([dateStamp,this.glucoseDetails.readings[i].sugarLevel]);
               lastReading = this.glucoseDetails.readings[i].sugarLevel;
        } else if(this.glucoseDetails.readings[i].reading.toUpperCase() != 'FASTING'
         && this.glucoseDetails.readings[i].platform.toUpperCase() == "CGM"){ 
              this.cgm.push([dateStamp,this.glucoseDetails.readings[i].sugarLevel]);
               lastReading = this.glucoseDetails.readings[i].sugarLevel;
          } 
          else if(this.glucoseDetails.readings[i].reading.toUpperCase() == 'TIMELINE'){
            if(lastReading > 0){
             let img = "/assets/img/nutritionn.png";
             if(this.glucoseDetails.readings[i].type == "Sleep"){
                img = "/assets/img/sleepn.png";
             }
             if(this.glucoseDetails.readings[i].type == "Activity"){
                img = "/assets/img/activityn.png";
             }
              var r = {
            x:dateStamp,
            y: lastReading-1,
            type: this.glucoseDetails.readings[i].type,
            data: this.glucoseDetails.readings[i].data,
            events:{
  click: (e:any) => { 
    this.showInfo(e.point.type,e.point.data);
  }
            },
            marker: {
              height:25,
               width:25,
                symbol: 'url('+img+')'
            }
        };
              this.timeLines.push(r);
            }else{
              lastTimeLines.push(this.glucoseDetails.readings[i]);
            }
          }
           else{
            this.postMeal.push([dateStamp,this.glucoseDetails.readings[i].sugarLevel]);
             lastReading = this.glucoseDetails.readings[i].sugarLevel;
          }
          if(lastTimeLines.length > 0 && lastReading > 0){
                while(lastTimeLines.length > 0){
                  var timeLine  = lastTimeLines.pop();
                   
                   let img = "/assets/img/nutritionn.png";
             if(timeLine.type == "Sleep"){
                img = "/assets/img/sleepn.png";
             }
             if(timeLine.type == "Activity"){
                img = "/assets/img/activityn.png";
             }
                   var currentDate1 = formatDate(timeLine.timeStampKeyVal, 'yyyy-MM-dd HH:mm:ss', 'en');
         var dateStamp1 = Date.parse(currentDate1);
         
                    var r1 = {
            x:dateStamp1,
            y: lastReading-1,
            type: timeLine.type,
            data: timeLine.data,
             events:{
  click: (e:any) => { 
   
    this.showInfo(e.point.type,e.point.data);
  }
            },
            marker: {
               height:25,
             width:25,
                symbol: 'url('+img+')'
            }
        };
                   this.timeLines.push(r1);       
                }
              }
      }
    }
    
    
this.createChart(this.fasting,this.postMeal,this.cgm,this.timeLines,this.timeZone);
    }
 showInfo(type:any,data:any){
 this.showModal = true;
var e = document.getElementById('exampleModalCenter');
  if(type == "Activity"){
    this.modalImg = "/assets/img/activityn.png";
    this.modalType = type;
    this.modalSTime = formatDate(data.startTime,"hh:mm a","en");
    this.activityName = data.type;
    this.calsBurned = data.calsBurned;
    this.distCovered = (data.distanceCovered * 1.609).toFixed(1);
    if(this.memberbasic?.address?.country?.toUpperCase() === "UNITED STATES" 
      || this.memberbasic?.address?.country?.toUpperCase() === "CANADA")
    {
      this.distUnits = "miles";
      this.distCovered = data.distanceCovered;
    }
  }else if(type == "Sleep"){
    this.modalImg = "/assets/img/sleepn.png";
    this.modalType = type;
    this.modalSTime = formatDate(data.startTime,"hh:mm a","en");
    this.modalETime =formatDate(data.endTime,"hh:mm a","en");
    const hours = Math.floor(data.duration / 3600);
    const minutes = Math.floor((data.duration % 3600) / 60);
    this.modalDuration = hours + ' hrs ' + minutes + ' min';

  }else{
    this.modalImg = "/assets/img/nutritionn.png";
    this.modalType = "Nutrition";
    this.modalSTime = formatDate(data.time,"hh:mm a","en");
    this.foodName = data.name;
    this.foodCals = data.calories;
    this.foodCarbs = data.carbs;
    this.foodProteins = data.protein;
    this.foodFats = data.fats;
  }
  $('#exampleModalCenter').modal('show');  

 }
 closeModal(){
   $('#exampleModalCenter').modal('hide'); 
 }

  createChart(fasting:any,postMeal:any,cgm:any,timeLine:any,timeZone:any){
           this.chartOptions = {
      chart: {
        backgroundColor: '#F9F9F9',
        type: 'line',
        panKey: 'shift',
        panning:{
          enabled:true,
          type:'x'
        },
        zooming: {
            type: 'x'
        }
    },
    title : { text : 'Glucose Readings' },
      xAxis: [{
         crosshair: true,
        lineWidth: 2,
        type: 'datetime',
    }],
    yAxis: {
    
      lineWidth: 2,
      title: {
          text: 'Values (mg/dl)'
      }
    },
   credits: {
    enabled: false
  },
  
    tooltip: {
      formatter: function() {
        if (this.x !== undefined) {
          if(this.series.name == '' ){
            return false;
          };
          let conD = new Date(this.x).toLocaleString('en-US', { timeZone: timeZone });
          let d = moment(conD).format('ddd, MMM DD');
          let t = moment(conD).format('HH:mm:ss');
          return  d +' at '+t+'</b> <br> <b>' + this.y + ' mg/dl</b>';
        }
        return 'Sugar Level: ' + this.y + ' mg/dl';
      },
      backgroundColor: 'rgba(0,0,0, 0.6)',
      borderWidth: 1,
      shadow: true,
      style: {
        color: '#fff',
        fontSize: '10pt',
        fontFamily: 'Poppins, sans-serif'
      }
    },
    plotOptions: {
      line: {
     
          dataLabels: {
              enabled: false
          },
          enableMouseTracking: true
      },
      series: {
        marker: {
            symbol: 'circle',
            fillColor: 'transparent',
            enabled: true
          
           
        }
    },
  },
  series: [{
    name: 'Fasting',
    data: fasting,
    type: 'line',
    color: '#E58009'
    
  },
  {
    name: 'Post Meal',
    data: postMeal,
    type: 'line',
    color: '#089bab'
    
  },
   {
    name: 'CGM',
    data: cgm,
    type: 'line',
    color: '#212529'
    
  },
   {
        name: '',
        type: 'scatter',
        data: timeLine
    }
  
  
  ],
    }
      this.chartOptions.time={
        timezone: timeZone
    }
  }
}
