
<div class="col-md-12  mt-3">
<div class="col-md-6 foodLogs  mt-2">
    <div class="col-md-2 float">
         <img  (click)="showModal(foodAllInfo.foodImageUrl)" class="imgClass" src="{{foodAllInfo.foodImageUrl}}"/>
    </div>
    <div class="col-md-10 float">
        <div class="col-md-12 foodTitle">
            {{foodAllInfo.name}}
        </div>
        <div class="col-md-12 mt-3 padding" >

            <mat-list class="foodDetails" style="display:flex;">  
                <mat-list-item class="foodDetails borderRight"> {{foodAllInfo.logTime.split('T')[0]}} </mat-list-item>  
                <mat-list-item class=" foodDetails borderRight"> {{getDate(foodAllInfo.logTime.split('T')[1].split('.')[0])}}  </mat-list-item>  
                <!-- <mat-list-item class="foodDetails borderRight"> {{foodAllInfo.serving}} serving  </mat-list-item>   -->
                <mat-list-item class="foodDetails"> {{foodAllInfo.mealType}}  </mat-list-item>  
            </mat-list>  

        </div>
    </div>

    <div class="col-md-12 padding float" >

        <mat-list class="foodDetails" style="display:flex;">  
            <mat-list-item class="foodDetails "> Total Calories </mat-list-item>  
            <mat-list-item class=" foodDetails "> Protien  </mat-list-item>  
            <mat-list-item class="foodDetails "> Carbs  </mat-list-item>  
            <mat-list-item class="foodDetails"> Fats  </mat-list-item>  
           </mat-list>  

    </div>

    <div class="col-md-12 padding float" >

        <mat-list class="foodDetails" style="display:flex;">  
            <mat-list-item class="foodDetails "> <b>{{Math.round(foodAllInfo.total.calories)}}</b> cals </mat-list-item>  
            <mat-list-item class=" foodDetails "> <b>{{foodAllInfo.total.protein.toFixed(1)}}</b> grams  </mat-list-item>  
            <mat-list-item class="foodDetails "> <b>{{foodAllInfo.total.carbs.toFixed(1)}}</b> grams  </mat-list-item>  
            <mat-list-item class="foodDetails"> <b>{{foodAllInfo.total.fat.toFixed(1)}}</b> grams  </mat-list-item>  
           </mat-list>  

    </div>
    <div class="row" *ngIf="foodAllInfo.serving == null">
        <div class="col-md-9"></div>
         <div class="col-md-3"> <a [routerLink]="['/edit-food-log']" [queryParams]="{ id:foodAllInfo.id,memberId:member}" type="submit" class="btnClass">Edit</a></div>
    </div>
</div>

</div>

<div class="modal fade" id="imgModal1" tabindex="-1" role="dialog" aria-labelledby="imgModalLabel" aria-hidden="true">
   <div class="modal-dialog modal-lg " role="document">
     <div class="modal-content modal-size-style">
       <div class="modal-header">
         <h5 class="modal-title">Food Log</h5>
         <button type="button" class="close" (click)="closeModal()" aria-label="Close">
           <span aria-hidden="true">&times;</span>
         </button>
       </div>
       <div class="modal-body">
         <img width="100%" id="modalImg" [src]="" />
       </div>
       <div class="modal-footer">
         <button type="button" class="btn btn-secondary" (click)="closeModal()"  data-dismiss="modal">Close</button>
       </div>
     </div>
   </div>
 </div>



