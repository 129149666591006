import { Component, Input } from '@angular/core';
import { NotesComponent } from '../life-style/notes/notes.component';
import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-sleep',
  standalone: true,
  imports: [NotesComponent, HighchartsChartModule, CommonModule],
  templateUrl: './sleep.component.html',
  styleUrl: './sleep.component.css'
})

export class SleepComponent {
  @Input() sleep: any;
  remSleepPerct: any;
  deepSleepPerct: any;
  lightSleepPerct: any
  awakePerct: any;
  totalSleepHrs: any;
  totalSleepGoal: any;
  comparedHrs: any;
  comparedImg: any;
  deepSleepTime: any;
  remSleepTime: any;
  lightSleepTime: any;
  awakeTime: any;
  deepSleepStatus: any;
  remSleepStatus: any;
  lightSleepStatus: any;
  awakeStatus: any;
  deepStatusColor: any;
  remStatusColor: any;
  lightStatusColor: any;
  awakeStatusColor: any;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  ngOnChanges(): void {
    this.remSleepPerct = 0;
    this.deepSleepPerct = 0;
    this.lightSleepPerct = 0;
    this.awakePerct = 0;
    this.totalSleepHrs = '---';
    this.totalSleepGoal = '---';
    this.comparedHrs = '---';
    this.comparedImg = "/assets/img/flat.png";
    this.deepSleepTime = '---';
    this.remSleepTime = '---';
    this.lightSleepTime = '---';
    this.awakeTime = '---';
    this.deepSleepStatus = '';
    this.remSleepStatus = '';
    this.lightSleepStatus = '';
    this.awakeStatus = '';
    this.deepStatusColor = 'white';
    this.remStatusColor = 'white';
    this.lightStatusColor = 'white';
    this.awakeStatusColor = 'white';

    if (this.sleep && this.sleep.hasSleepsData) {
      this.remSleepPerct = parseFloat((this.sleep.totalRemSleepDuration / this.sleep.sleepDurationForAvg * 100).toFixed(1));
      this.deepSleepPerct = parseFloat((this.sleep.totalDeepSleepDuration / this.sleep.sleepDurationForAvg * 100).toFixed(1));
      this.lightSleepPerct = parseFloat((this.sleep.totalLightSleepDuration / this.sleep.sleepDurationForAvg * 100).toFixed(1));
      this.awakePerct = parseFloat((this.sleep.totalAwakeDuration / this.sleep.sleepDurationForAvg * 100).toFixed(1));
      this.deepSleepTime = this.getHrsMints(this.sleep.totalDeepSleepDuration);
      this.deepSleepStatus = this.sleep.deepSleepStatus;
      this.deepStatusColor = this.getColor(this.deepSleepStatus);
      this.remSleepTime = this.getHrsMints(this.sleep.totalRemSleepDuration);
      this.remSleepStatus = this.sleep.remSleepStatus;
      this.remStatusColor = this.getColor(this.remSleepStatus);
      this.lightSleepTime = this.getHrsMints(this.sleep.totalLightSleepDuration);
      this.lightSleepStatus = this.sleep.lightSleepStatus;
      this.lightStatusColor = this.getColor(this.lightSleepStatus);
      this.awakeTime = this.getHrsMints(this.sleep.totalAwakeDuration);
      this.awakeStatus = this.sleep.awakeStatus;
      this.awakeStatusColor = this.getColor(this.awakeStatus);
    }
    if (this.sleep && this.sleep.totalSleepMsg) {
      this.totalSleepHrs = this.getHrsMints(this.sleep.totalSleepDuration);
      this.totalSleepGoal = this.getHrsMints(this.sleep.totalSleepDurationGoal);
      if (this.sleep.diffFromPrevPeriod > 0) {
        this.comparedHrs = '+ ' + this.getHrsMints(this.sleep.diffFromPrevPeriod);
        this.comparedImg = "/assets/img/up1.png";
      } else if (this.sleep.diffFromPrevPeriod < 0) {
        this.comparedHrs = '- ' + this.getHrsMints(Math.abs(this.sleep.diffFromPrevPeriod));
        this.comparedImg = "/assets/img/down.png";
      } else if (this.sleep.diffFromPrevPeriod == 0) {
        this.comparedHrs = '0 Hrs';
      }
    }
    this.chartOptions = {
      chart: {
        backgroundColor: 'white',
        type: 'bar'
      },
      title: {
        text: ''
      },
      xAxis: {
        visible: false,
        categories: ['']
      },
      yAxis: {
        visible: false,
        title: {
          text: ''
        }
      },

      credits: {
        enabled: false
      },
      tooltip: {
        formatter: function () {
          if (this.y === 0) {
            return false;
          }
          return this.series.name + ' : ' + this.y + ' %';
        },
        backgroundColor: 'rgba(0,0,0, 0.6)',
        borderWidth: 1,
        shadow: true,
        style: {
          color: '#fff',
          fontSize: '10pt',
          fontFamily: 'Poppins, sans-serif'
        }
      },

      plotOptions: {

        series: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        }
      },
      series: [{
        type: 'bar',
        name: 'Deep',
        data: [this.deepSleepPerct],
        color: '#99FF99'
      }, {
        type: 'bar',
        name: 'REM',
        data: [this.remSleepPerct],
        color: '#21A4B6'
      }, {
        type: 'bar',
        name: 'Light',
        data: [this.lightSleepPerct],
        color: '#4787F6'
      }, {
        type: 'bar',
        name: 'Awake',
        data: [this.awakePerct],
        color: '#FFDA48'
      }]
    };
  }
  getColor(status: any) {
    if (status == 'Excellent') {
      return '#20AA4A';
    }
    else if (status == 'Good') {
      return '#D0AF00';
    }
    else if (status == 'Needs Attention') {
      return '#FF3939';
    }
  }
  getHrsMints(seconds: any) {

    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    return hours + ' hrs ' + minutes + ' mins';
  }
}
