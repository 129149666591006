import { Component } from '@angular/core';
import {MatListModule} from '@angular/material/list';
import { MemberDetailFormComponent } from './member-detail-form/member-detail-form.component';
import { NgIf } from '@angular/common';
import { PersonalityComponent } from './personality/personality.component';
import { LifeStyleComponent } from './life-style/life-style.component';
import { HealthComponent } from './health/health.component';
import { DietaryComponent } from './dietary/dietary.component';
import { FitnessComponent } from './fitness/fitness.component';
import { ActivatedRoute } from '@angular/router';
import { QuestionsService } from './questions.service';
import { AllFoodLogsComponent } from "./all-food-logs/all-food-logs.component";
import { MedicationComponent } from './medication/medication.component';
import { DietaryDetailsComponent } from './dietary-details/dietary-details.component';
import { DietaryPreferenceComponent } from './dietary-preference/dietary-preference.component';
import { LifestyleConditionComponent } from './lifestyle-condition/lifestyle-condition.component';
import { NgClass } from '@angular/common';
import { Router } from "@angular/router";
import { ProgressNotesComponent } from './progress-notes/progress-notes.component';
import { MealPlanHistoryComponent } from './mealplan-history/mealplan-history.component';
import { MemberService } from './member-service/MemberService ';
import { SleepGraphComponent } from './activity-graph/sleep-graph.component';
import { ActivityGraphComponent } from './sleep-graph/activity-graph.component';

@Component({
  selector: 'app-member-details',
  standalone: true,
  imports: [NgClass, LifestyleConditionComponent, MatListModule,
    DietaryPreferenceComponent, DietaryDetailsComponent, MedicationComponent,
    MemberDetailFormComponent, NgIf, PersonalityComponent, LifeStyleComponent,
    HealthComponent, DietaryComponent, FitnessComponent, AllFoodLogsComponent,
    ProgressNotesComponent, MealPlanHistoryComponent, SleepGraphComponent, ActivityGraphComponent],
  templateUrl: './member-details.component.html',
  styleUrl: './member-details.component.css'
})
export class MemberDetailsComponent {
  isSubMenu:any=0;
  subMenu:any=0;
  isMemberDairy:boolean=true;
  isLifeStyle:boolean=false;
  isPersonality:boolean=false;
  isHealth:boolean=false;
  isDietary:boolean=false;
  isfitness:boolean=false;
  mainTab: any ='Dairy';
  subTab:any='';
  health: any = [];
  memberName: string = '';
  stateObj:any;
  constructor(private memberService: MemberService, private route: ActivatedRoute, private router: Router){

  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      var memberId = this.route.snapshot.queryParamMap.get('id') || '';
      if (memberId) {
        this.getMemberById(memberId);
      }
    });
    var tabtype = this.route.snapshot.queryParamMap.get('islist') || "";
    if (tabtype=="5") {
      this.selectedTab(5, 7, "Monitor","food");
    }  
     const { stateObj } = window.history.state; 
     this.stateObj = stateObj;
    //this.question.getQuestion(this.route.snapshot.queryParamMap.get('id')).subscribe(response => { 
    //  //console.log(response);
      
    //  this.health = response.items.filter((questions:any) => questions.questionCategory === "HealthAndMedication");
    //  console.log(this.health);
    //});
    
  }
  getMemberById(id: string) {
    this.memberService.getMemberById(id).subscribe(data => {
      if (data) {        
        this.memberName = `${data.result.firstName} ${data.result.lastName}`;
      }
    });
  }

  selectedTab(type:any,subMenu:any,tab:any,flow?:any){
    this.mainTab = tab;
    this.isSubMenu = type;
    this.subMenu=subMenu;
    if(type== 2){
      this.subTab = 'Medication';
    }else  if(type== 5){
      this.subTab = 'activitygraph';
    }
    if(flow == "food"){
      this.subTab = 'Food';
    }
     if(type==1){
      this.isMemberDairy = true;
     }else{
      this.isMemberDairy = false;
    }
   
  }

  subSelectedTab(type:any,tab:any){
   this.diablesubSelected();
   this.subMenu=type;
   this.subTab =tab;
    // if(type == 1){
    //   this.isPersonality=true;
    // }
    // else if(type == 2){
    //   this.isLifeStyle=true;
    // }
    // else if(type == 3){
    //   this.isHealth=true;
    // }
    // else if(type == 4){
    //   this.isDietary=true;
    // }
    // else if(type == 5){
    //   this.isfitness=true;
    // }
  }

  diablesubSelected(){
    // this.isPersonality=false;
    // this.isLifeStyle=false;
    // this.isHealth=false;
    // this.isDietary=false;
    // this.isfitness=false;
    this.subMenu=0;
  }

backBtn(){
  this.router.navigate(['/members'],{state:{stateObj:this.stateObj}});
  }
  
}
