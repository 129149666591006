import { Component } from '@angular/core';
import {MatListModule} from '@angular/material/list';
import { QuestionOptionsComponent } from './question-options/question-options.component';
import { EnagramTestComponent } from './enagram-test/enagram-test.component';
import { MemberQuetionsComponent } from '../member-quetions/member-quetions.component';

@Component({
  selector: 'app-personality',
  standalone: true,
  imports: [MatListModule, QuestionOptionsComponent, EnagramTestComponent, MemberQuetionsComponent],
  templateUrl: './personality.component.html',
  styleUrl: './personality.component.css'
})
export class PersonalityComponent {

}
