import { AfterViewInit, Component, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { CommonModule, DatePipe } from '@angular/common';

import * as Highcharts from 'highcharts';
import { environment } from '../../../environments/environment';

import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, } from '@angular/forms';
import { HelperService } from '../../services/helper-service';
import { Questions } from '../../models/questions';
import { MemberService } from '../member-service/MemberService ';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { ToastService } from '../../services/toast.service';


@Component({
  selector: 'app-member-quetions',
  templateUrl: './member-quetions.component.html',
  standalone: true,
  imports: [CommonModule, MatListModule, FormsModule, MatExpansionModule],
  styleUrls: ['./member-quetions.component.css']
})


export class MemberQuetionsComponent {
  subTab: any = 'Medication';
  pdfSrc: string;
  activeTab: any;
  memberList: any = [];
  tZone: any;
  breakFastFood: any = [];
  snack1Food: any = [];
  lunchFood: any = [];
  snack2Food: any = [];
  dinnerFood: any = [];
  snack3Food: any = [];
  isImage: boolean;
  postData: any = [];
  postHealthAndMedQuestions: any = [];
  filtered: any
  getTodaysDate: any;
  getDateRange: any = []
  getTomorrowDate: any;
  PersonalityStressQuestion: any;
  PersonalityAnxietyQuestion: any;
  PersonalityEnneagramQuestion: any;
  DietaryRadioQuestion: any;
  DietaryDropDownQuestions: any;
  ingredient!: any;
  someval: any;
  fitnessQuestionsData: any
  model: any = {}
  fetchMemberId: any;
  savePersonalityBtn: boolean = false;
  pantryInfo: string;
  memberId: any;
  excerciseInfo: string;
  meetingsData: any;
  saveData: Questions[];
  mealNumbers: number;
  selectedRadio: any = { _id: null };
  selectedIndex: any = [];
  documents: any[] = [];
  firstPage: number = 0;
  totalRecords: number = 0;
  notAnswered: boolean = false;
  dateObjects: any;
  allDatesInRange: any
  allDatesInRangeBMI: any
  secondDate: any;
  secondDateBMI: any;
  todayMeetings: any[] = [];
  upcomingMeetings: any[] = []
  firstDate: any;
  firstDateBMI: any;
  rangeDates: Date[] | undefined;

  PersonalityQuestionPost: any = []
  DietaryQuestionPost: any = []
  FitnessQuestionDataPost: any = []
  LifestyleQuestionDataPost: any = []
  HealthAndMedicationQuestionDataPost: any = []
  DietaryQuestionDataPost: any = []
  selectedOptions: any = {};

  PersonalityTab: any
  commonQuestions: any = [];
  FitnessTab: any;
  LifestyleTab: any;
  LifestyleTabTextQuestions: any;
  notAnsweredQuestions: string[] = [];
  notAnsweredQuestionsStress: string[] = [];
  notAnsweredQuestionsAnxiety: string[] = [];
  LifestyleTabTimeQuestions: any;
  HealthAndMedicationTab: any;
  HealthAndMedicationListFields: any;
  HealthAndMedicationTextField: any;
  HealthAndMedicationMultiSelect: any;
  HealthAndMedicationPlainText: any;
  HealthAndMedicationMultiSelectWithKeys: any;
  DietaryMealsTable: any;
  DietaryTab: any;
  selectedAnswers: any = []
  medications: any[] = [];
  supplements: any[] = [];
  loopcommon: any
  healthMedFieldQ2: string;
  isSelectedAnswerBool: boolean = false
  isSelectedDietaryAnswerBool: boolean = false
  selectManual: any = [];
  foods2: any = [];
  isSelectAnswered: boolean = false
  checkQuestionsIdIsThereOrNot: any = [];
  generateChartId: any = 'MyChart';
  currentImageIndex = 0;
  LifestyleTabDropDownQuestions: any
  isFitnessAnswered: boolean = false
  isPersonlaityAnswered: boolean = false
  isLifestyleAnswered: boolean = false
  fastingData: any = []
  DietaryMealTable: any;
  selectedConditions: any = {};
  defaultDate: any;
  supplementsData: string[] = [''];
  formattedQualifications: string = '';
  selectedWakeUpTime: string = '';
  medicationsId: string;
  selectedBedTime: string = '';
  gulcoseData: any = [];
  gulcosePost: any;
  gulcoseFasting: any;
  options: any;
  postMeal: any = [];
  bmiValues: any = [];
  fasting: any = [];
  bmiReadings: any;
  bmiOptions: any;
  postDate: any = [];
  fastingDate: any = [];
  totalPostGulcose: any = 0;
  totalFasting: any = 0;
  weekStart: string;
  weekEnd: string;
  averagePostMeal: any = 0;
  averageFasting: any = 0;
  allFoodLog: any = [];
  form: FormGroup;
  allTabsIndices: number[];
  mealPlan: any = [];
  mealPlanTotalRecords: any = 0;
  visible: boolean = false;
  mealPlanHistory: any = [];
  maxDate = new Date();
  maxDateBMI = new Date();
  planFilter: any = '0';
  mealFasting: any = [];
  bmiWeightType: any = 1;
  weightByKg: any = [];
  kgWeight: any = [];
  weightByPound: any = [];
  BMIVals: any = [];
  Highcharts: typeof Highcharts = Highcharts; // required
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  chartOptions: Highcharts.Options = {}; // required
  chartOptionsGlucose: Highcharts.Options = {}; // required
  selectedMedications: any = "";
  selectedSupplements: any = "";
  selectedOptionsForDietary: any = {};
  familyHistoryData: any;
  optionsForEnegram: any = [];
  selectedFile: File;
  selectedPrescriptionFile: File;
  isSelectedFile: boolean = false;
  isReportUploadDisabled = false;
  isPrescriptionUploadDisabled = false;

  selectedFileName: string = '';
  fileType: string = '';

  selectedPrescriptionName: string = '';
  constructor(private toast: ToastService,private route: ActivatedRoute, private HelperService: HelperService, private MembersService: MemberService, private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef, private fb: FormBuilder, private datePipe: DatePipe) {


  }

  ngOnInit(): void {


    this.memberId = this.route.snapshot.queryParamMap.get('id') || '';

    this.defaultDate = new Date().toISOString().split('T')[0];


    for (let hour = 0; hour < 24; hour++) {
      const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
      const amPm = hour < 12 ? 'AM' : 'PM';

    }

    this.getMemberQuetions();
    //  this.fitnessQuestionsData = fitnessData


    this.getTodaysDate = new Date();
    this.getTomorrowDate = new Date(this.getTodaysDate.getTime() + (24 * 60 * 60 * 1000));


  }
  getMemberQuetions() {
    this.MembersService.GetMemberQuestionnaire(this.memberId).subscribe(data => {
      data = data.items;
      this.PersonalityTab = data.filter((questions: any) => questions.questionCategory === "Personality");
      this.PersonalityStressQuestion = this.PersonalityTab.filter((questions: any) => questions.questionSubCategory === 'StressTest');
      this.PersonalityAnxietyQuestion = this.PersonalityTab.filter((questions: any) => questions.questionSubCategory === 'AnxietyTest');
      this.PersonalityEnneagramQuestion = this.PersonalityTab.filter((questions: any) => questions.questionSubCategory === 'EnneagramTest');
      this.DietaryRadioQuestion = data.filter((questions: any) => questions.questionSubCategory === 'choice');
      this.DietaryDropDownQuestions = data.filter((questions: any) => questions.questionSubCategory === 'multiSelectWithKeys' && questions.questionSubCategory !== "choice");

      this.FitnessTab = data.filter((questions: any) => questions.questionCategory === "Fitness");
      this.selectedAnswers = this.PersonalityTab.map((questions: any) => questions.answer);
      this.LifestyleTab = data.filter((questions: any) => questions.questionCategory === "Lifestyle");
      this.LifestyleTabTextQuestions = data.filter((questions: any) => questions.questionCategory === "Lifestyle" && questions.dataType !== "time" && questions.dataType !== "multiSelectWithKeys");
      this.LifestyleTabTimeQuestions = data.filter((questions: any) => questions.questionCategory === "Lifestyle" && questions.dataType == "time");
      this.LifestyleTabDropDownQuestions = data.filter((questions: any) => questions.questionCategory === "Lifestyle" && questions.dataType == "multiSelectWithKeys");
      this.HealthAndMedicationTab = data.filter((questions: any) => questions.questionCategory === "HealthAndMedication");
      this.HealthAndMedicationTextField = data.filter((questions: any) => questions.questionCategory === "HealthAndMedication" && questions.isListField == false);
      this.HealthAndMedicationListFields = data.filter((questions: any) => questions.questionCategory === "HealthAndMedication" && questions.dataType == "multiText");
      this.HealthAndMedicationMultiSelect = data.filter((questions: any) => questions.questionCategory === "HealthAndMedication" && questions.dataType == "multiSelect");
      this.HealthAndMedicationPlainText = data.filter((questions: any) => questions.questionCategory === "HealthAndMedication" && questions.dataType == "plainText");
      this.HealthAndMedicationMultiSelectWithKeys = data.filter((questions: any) => questions.questionCategory === "HealthAndMedication" && questions.dataType == "MultiSelectWithKeys");
      this.DietaryMealsTable = data.filter((questions: any) => questions.questionCategory === "Dietary" && questions.questionSubCategory == "MealsGrid");
      const formGroups: any = {};
      this.HealthAndMedicationMultiSelect.forEach((question: any) => {
        const optionsArray = question.options.map((option: any) => this.createConditionItem(option));
        formGroups[question.question.toLowerCase()] = this.fb.array(optionsArray);
      });
      this.form = this.fb.group(formGroups);
      this.mapAnswersToFormControls();
      const medicinesList = this.HealthAndMedicationListFields[0].answer;

      // medicaiton list 
      this.medications = medicinesList;

      if (this.medications !== null) {
        this.selectedMedications = 'Yes';
      }
      const supplmentsList = this.HealthAndMedicationListFields[1].answer;
      // supplements list 
      this.supplements = supplmentsList;
      if (this.supplements !== null) {
        this.selectedSupplements = 'Yes';
      }

      this.DietaryTab = data.filter((questions: any) => questions.questionCategory === "Dietary" && questions.questionSubCategory == "plainText");
      this.notAnsweredQuestions = this.PersonalityEnneagramQuestion
        .filter((question: any) => question.answer === 'not answered')
        .map((question: any) => question.id);
      this.notAnsweredQuestionsStress = this.PersonalityStressQuestion
        .filter((question: any) => question.answer === 'not answered')
        .map((question: any) => question.id);
      this.notAnsweredQuestionsAnxiety = this.PersonalityAnxietyQuestion
        .filter((question: any) => question.answer === 'not answered')
        .map((question: any) => question.id);
      for (let i = 0; i < this.PersonalityTab.length; i++) {
        this.selectedOptions[this.PersonalityTab[i].id] = this.PersonalityTab[i].answer;
      }


    })

  }
  removeMedication(index: number) {
    this.medications.splice(index, 1);
  }
  removeSupplement(index: number) {
    this.supplements.splice(index, 1);
  }
  addSupplements(): void {
    if (!this.supplements) {
      this.supplements = [];
    }
    this.supplements.push('');
    this.cdr.detectChanges();
  }
  logMedications() {
    // console.log('Updated Medications:', this.medications);
  }
  logSupplements() {
    // console.log('Updated Supplements:', this.supplements);
  }
  trackByFn(index: number, item: string): number {
    return index;
  }
  trackByFnSupplements(index: number, item: string): number {
    return index;
  }
  addMedications(id: any): void {
    if (!this.medications) {
      this.medications = [];
    }
    this.medications.push('');
    this.cdr.detectChanges();
    this.medicationsId = id
  }
  ngAfterViewInit() {
    // this.createChart();
  }

  createConditionItem(option: string): FormControl {
    return this.fb.control(false);
  }
  mapAnswersToFormControls(): void {
    if (this.HealthAndMedicationMultiSelect) {
      this.HealthAndMedicationMultiSelect.forEach((question: any) => {
        const formArray = this.getFormArrayByName(question.question.toLowerCase());
        if (formArray) {
          const selectedOptions = question.answer || [];
          selectedOptions.forEach((selectedOption: any) => {
            const index = question.options.indexOf(selectedOption);
            if (index !== -1) {
              const control = formArray.at(index) as FormControl;
              if (control) {
                control.setValue(true);
              }
            }
          });
        }
      });
    }
  }
  externalTooltipHandler() {
    return 'hello'
  }

  getFormArrayByName(name: string): FormArray {
    return this.form.get(name) as FormArray;
  }

  toggleSelection(formArrayName: string, index: number): void {
    const formArray = this.getFormArrayByName(formArrayName);
    const control = formArray.at(index) as FormControl;
    control.setValue(!control.value);
  }
  logSelections(): void {
    const selections: any = {};

    if (this.HealthAndMedicationMultiSelect) {
      this.HealthAndMedicationMultiSelect.forEach((question: any) => {
        const formArray = this.getFormArrayByName(question.question.toLowerCase());

        if (formArray) {
          const selectedOptions = formArray.value
            .map((selected: any, index: any) => selected ? question.options[index] : null)
            .filter(Boolean);

          selections[question.question] = selectedOptions;
        }
      });
    }

    // console.log('Selections:', selections);
  }


  getOption(answer: any, question: any) {
    this.isSelectedAnswerBool = true;

    if (answer === 'not answered') {
      this.notAnswered = true;
    } else {
      this.notAnswered = false;
    }

    this.PersonalityQuestionPost.push({
      "questionId": question,
      "memberId": this.memberId,
      "answer": answer,
      "loggedInUserId": this.memberId
    });

    this.selectedOptions[question] = answer;
  }

  getOptionForDietary(answer: any, question: any) {
    this.isSelectedDietaryAnswerBool = true;
    this.DietaryQuestionPost.push({
      "questionId": question,
      "memberId": this.memberId,
      "answer": answer,
      "loggedInUserId": this.memberId
    })
    this.selectedOptions[question] = answer;
  }

  getFitness(question: any, answer: any) {
    this.FitnessQuestionDataPost.push({
      "questionId": question,
      "memberId": this.memberId,
      "answer": answer.target.value == 'on' ? '' : answer.target.value,
      "loggedInUserId": this.memberId
    })
  }
  saveFitnessQuestions() {
    this.isFitnessAnswered = true;
    this.MembersService.saveQuestionaire(this.FitnessQuestionDataPost).subscribe((data) => {
      this.MembersService.GetMemberQuestionnaire(this.route.snapshot.queryParamMap.get('id')).subscribe(data => {
        this.FitnessTab = data.items.filter((questions: any) => questions.questionCategory === "Fitness");
        let answeredQuestions = this.FitnessTab.map((items: any) => {
          return items.answer
        })
        if (!answeredQuestions.includes(null)) {
          this.toast.successMessage("Fitness answeres saved successfully");
        }
        else {
          this.toast.errorMessage("Some questions are not answered");
        }
      });
    });
  }


  getLifestyleAnswers(question: any, answer: any) {
    var formattedTime;
    if (answer.target.type === 'time') {
      const selectedTime = answer.target.value;
      const timeParts = selectedTime.split(':');
      const selectedDate = new Date();
      selectedDate.setHours(parseInt(timeParts[0], 10));
      selectedDate.setMinutes(parseInt(timeParts[1], 10));
      formattedTime = this.datePipe.transform(selectedDate, 'hh:mm a');
    }
    const answerValue = answer.target.type === 'time' ? formattedTime : answer.target.value;
    this.LifestyleQuestionDataPost.push({
      "questionId": question,
      "memberId": this.memberId,
      "answer": answerValue === 'on' ? '' : answerValue,
      "loggedInUserId": this.memberId
    });
  }

  saveLifestyleAnswers() {
    this.isLifestyleAnswered = true;
    this.MembersService.saveQuestionaire(this.LifestyleQuestionDataPost).subscribe((data) => {
      this.MembersService.GetMemberQuestionnaire(this.route.snapshot.queryParamMap.get('id')).subscribe(data => {
        this.LifestyleTab = data.items.filter((questions: any) => questions.questionCategory === "Lifestyle");
        let answeredQuestions = this.LifestyleTab.map((items: any) => {
          return items.answer
        })
        if (!answeredQuestions.includes(null)) {
          this.toast.successMessage("Lifestyle answeres saved successfully");
          this.isLifestyleAnswered = false;
        }
        else {
          this.toast.successMessage("Lifestyle answeres saved successfully");
          // this.toastr.warning("Some questions are not answered");
        }
      });
    });
  }

  getQuestionIdBasedOnKey(key: string): string {
    const mapping: any = {
      "grand parents": "6572fb5758eaad264a416c6c",
      mother: "6572fb6358eaad264a416c6d",
      father: "6572fb7058eaad264a416c6e",
      siblings: "6572fb7b58eaad264a416c6f"
    };

    return mapping[key] || '';
  }

  getOptionBasedOnIndex(questionKey: string, index: number): string {
    const optionsMapping: any = {
      "grand parents": ["Diabetes", "Heart Disease", "Cholestrol", "High BP", "Depression Anxiety"],
      "mother": ["Diabetes", "Heart Disease", "Cholestrol", "High BP", "Depression Anxiety"],
      "father": ["Diabetes", "Heart Disease", "Cholestrol", "High BP", "Depression Anxiety"],
      "siblings": ["Diabetes", "Heart Disease", "Cholestrol", "High BP", "Depression Anxiety"],
    };
    const options = optionsMapping[questionKey] || [];
    return options[index] || '';
  }


  getHealthAndMedicationAnswers(question: any, answer: any) {

    this.postHealthAndMedQuestions.push({
      "questionId": question,
      "memberId": this.memberId,
      "answer": answer.target.value == 'on' ? '' : answer.target.value,
      "loggedInUserId": this.memberId
    })
    //console.log(this.postHealthAndMedQuestions)
  }
  saveHealthAndMedicationAnswers() {

    if (!this.selectedFile) { }
    else {
      this.isPrescriptionUploadDisabled = false;
      this.isReportUploadDisabled = false;
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result as string;
        const parts = base64String.split(',');

        if (parts.length === 2 && parts[1].startsWith('J')) {
          const trimmedBase64 = parts[1];
        } else {
          console.error('Invalid Base64 string format.');
        }
        const fileData = {
          uploadFileS: parts[1],
          type: this.fileType,
          addedBy: this.memberId,
          memberId: this.memberId,
          reportName: this.selectedFileName,
          prescriptionName: this.selectedPrescriptionName
        };
        // console.log(fileData);
        this.MembersService.uploadFile(fileData).subscribe((data) => {
          this.toast.successMessage(this.fileType + " uploaded successfully");
          this.MembersService.GetLabWork(this.route.snapshot.queryParamMap.get('id'), 1).subscribe(data => {
            const documentsData = data['labWorkList'];
            this.documents = documentsData.sort((a: any, b: any) => {
              const dateA = new Date(a.createdDateTime);
              const dateB = new Date(b.createdDateTime);
              return dateB.getTime() - dateA.getTime();
            });
            this.totalRecords = data['totalCount'];
          })
        });
      };
      reader.readAsDataURL(this.selectedFile == null ? this.selectedPrescriptionFile : this.selectedFile);
    }

    // this.HealthAndMedicationQuestionDataPost.push({
    //   "questionId":"6513fe8fb26bb90cc03fbaa5",
    //   "memberId": this.members.id, 
    //   "answer": this.medications,
    //   "medicines":[],
    //   "loggedInUserId":this.memberId.id
    // })

    const formData = this.form.value;
    // this.postData.push(this.postHealthAndMedQuestions);
    //console.log(this.postData);
    //console.log(this.postHealthAndMedQuestions);
    this.postData = Object.keys(formData).map(key => {
      return {
        questionId: this.getQuestionIdBasedOnKey(key),
        memberId: this.memberId,
        answer: formData[key].map((selected: any, index: any) => selected ? this.getOptionBasedOnIndex(key, index) : null).filter(Boolean),
        loggedInUserId: this.memberId.id
      };
    });
    const medicationList = {
      "questionId": "6513fe8fb26bb90cc03fbaa5",
      "memberId": this.memberId,
      "answer": this.medications == null ? [] : this.medications,
      "loggedInUserId": this.memberId.id
    }
    const supplementList = {
      "questionId": "6513fe99b26bb90cc03fbaa6",
      "memberId": this.memberId,
      "answer": this.supplements == null ? [] : this.supplements,
      "loggedInUserId": this.memberId.id
    }
    this.postData = this.postData.concat(this.postHealthAndMedQuestions);

    this.postData.push(medicationList, supplementList);
    this.MembersService.saveQuestionaire(this.postData).subscribe((data) => {
      this.toast.successMessage("Health & medication answeres saved successfully");
    });
  }


  getDietary(question: any, answer: any, isRadio: any) {
    if (isRadio == 'radio') {
      this.DietaryQuestionDataPost.push({
        "questionId": answer,
        "memberId": this.memberId,
        "answer": question,
        "loggedInUserId": this.memberId
      })
    }
    else {
      this.DietaryQuestionDataPost.push({
        "questionId": question,
        "memberId": this.memberId,
        "answer": answer.target.value == 'on' ? '' : answer.target.value,
        "loggedInUserId": this.memberId
      })
    }


  }
  saveDietaryQuestions() {
    this.isFitnessAnswered = true;
    this.MembersService.saveQuestionaire(this.DietaryQuestionDataPost).subscribe((data) => {
      this.MembersService.GetMemberQuestionnaire(this.route.snapshot.queryParamMap.get('id')).subscribe(data => {
        // this.DietaryTab = data.filter((questions:any) => questions.questionCategory === "Dietary");  
        this.toast.successMessage("Dietary answeres saved successfully");
        let answeredQuestions = this.FitnessTab.map((items: any) => {
          return items.answer
        })
      });
    });
  }



  isSelected(questionId: string, option: string): any {
    return this.selectedOptions[questionId] === option;
  }

  isSelectedDietary(questionId: string, option: string): any {
    return this.selectedOptionsForDietary[questionId] === option;
  }


  saveQuestion() {
    this.isSelectAnswered = true;
    this.savePersonalityBtn = true;
    this.MembersService.saveQuestionaire(this.PersonalityQuestionPost).subscribe((saveData: any) => {
      this.MembersService.GetMemberQuestionnaire(this.route.snapshot.queryParamMap.get('id')).subscribe(data => {
        data = data.items;
        this.PersonalityEnneagramQuestion = data.filter((questions: any) => questions.questionSubCategory === "EnneagramTest");
        let answeredQuestions = this.PersonalityTab.map((items: any) => {
          return items.answer
        })
        this.PersonalityTab = data.filter((questions: any) => questions.questionCategory === "Personality");
        let personalityQuestions = this.PersonalityTab.map((items: any) => {
          return items.answer
        })
        this.PersonalityStressQuestion = data.filter((questions: any) => questions.questionSubCategory === "StressTest");
        let answeredQuestionsStress = this.PersonalityTab.map((items: any) => {
          return items.answer
        })
        this.PersonalityAnxietyQuestion = data.filter((questions: any) => questions.questionSubCategory === "AnxietyTest");
        let answeredQuestionsAxiety = this.PersonalityTab.map((items: any) => {
          return items.answer
        })

        this.notAnsweredQuestions = this.PersonalityEnneagramQuestion
          .filter((question: any) => question.answer === 'not answered')
          .map((question: any) => question.id);
        this.notAnsweredQuestionsStress = this.PersonalityStressQuestion
          .filter((question: any) => question.answer === 'not answered')
          .map((question: any) => question.id);
        this.notAnsweredQuestionsAnxiety = this.PersonalityAnxietyQuestion
          .filter((question: any) => question.answer === 'not answered')
          .map((question: any) => question.id);
        if (!answeredQuestions.includes(null)) {
          this.toast.successMessage("Personality answeres saved successfully");
          this.isSelectAnswered = false;
          this.savePersonalityBtn = false;
        }
        else if (!answeredQuestionsStress.includes(null)) {
          this.toast.successMessage("Personality answeres saved successfully");
          this.isSelectAnswered = false;
          this.savePersonalityBtn = false;
        }
        else if (!answeredQuestionsAxiety.includes(null)) {
          this.toast.successMessage("Personality answeres saved successfully");
          this.isSelectAnswered = false;
          this.savePersonalityBtn = false;
        }
        else if (personalityQuestions.includes(null)) {
          this.toast.successMessage("Personality answeres saved successfully");
          // this.toastr.warning("Some questions are not answered");
          this.savePersonalityBtn = false;
        }
        else {
          this.toast.successMessage("Personality answeres saved successfully");
        }
      })
    });





  }
  postCheckboxValues() {
    const selectedConditions: any = {};
    for (const condition of Object.keys(this.familyHistoryData.familyHistoryData.conditions)) {
      selectedConditions[condition] = {
        "Grand Parents": this.familyHistoryData.familyHistoryData.conditions[condition]["Grand Parents"],
        "Mother": this.familyHistoryData.familyHistoryData.conditions[condition]["Mother"],
        "Father": this.familyHistoryData.familyHistoryData.conditions[condition]["Father"],
        "Siblings": this.familyHistoryData.familyHistoryData.conditions[condition]["Siblings"]
      };
    }

  }
  subSelectedTab(type: any, tab: any) {
    this.getMemberQuetions();
    this.subTab = tab;

  }
  formatConversionHandler(inputTime: string): string {
    const date = new Date("2000-01-01 " + inputTime);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  //upload Lab Work
  onReportSelected(event: any) {
    const fileInput = event.target;
    this.isPrescriptionUploadDisabled = true;
    this.fileType = "report";
    if (fileInput.files.length > 0) {
      this.selectedFileName = fileInput.files[0].name;
      this.selectedFile = fileInput.files[0];
    } else {
      this.selectedFileName = '';
    }

    console.log(this.selectedFileName);
  }

  onPrescriptionSelected(event: any) {
    this.selectedPrescriptionName = '';
    const fileInput = event.target;
    this.isReportUploadDisabled = true;
    this.fileType = "prescription";
    if (fileInput.files.length > 0) {
      this.selectedPrescriptionName = fileInput.files[0].name;
      this.selectedFile = fileInput.files[0];
    } else {
      this.selectedFile = fileInput.files[0];
    }
  }



}
