<div class="row">
  <img class="imgBack" (click)="backBtn()" src="assets/img/backbtn.png" />
</div>
<app-meal-member-basic-details [memberDetail]="memberId"></app-meal-member-basic-details>
<div class="row mt-5 ml-2">
  <div class="title">All Meal Plans</div>
</div>
<div class="row mt-4 flex" style="padding: 0;">
  <div class="col">
    <mat-list role="list" class="flex">
      <mat-list-item class="col-md-3" role="listitem">
        <div class="tab" [ngClass]="{'active': isType === 1}">
          <div class="tabContent" (click)="getOngoingMeals()">Ongoing Meal Plan</div>
        </div>
      </mat-list-item>
      <mat-list-item class="col-md-3" role="listitem">
        <div class="tab" [ngClass]="{'active': isType === 2}">
          <div class="tabContent" (click)="getCompletedMeals()">Completed Meal Plan</div>
        </div>
      </mat-list-item>
      <mat-list-item class="col-md-3" role="listitem">
        <div class="tab" [ngClass]="{'active': isType === 3}">
          <div class="tabContent" (click)="getUpComingMeals()">Upcoming Meal Plan</div>
        </div>
      </mat-list-item>
      <mat-list-item class="col-md-3" role="listitem">
        <div class="tab" [ngClass]="{'active': isType === 4}">
          <div class="tabContent" (click)="getDraftMeals()">Draft Meal Plan</div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
<div class="row mt-4 title1">
  <div class="col">{{mealTitle}}</div>
</div>
<div class="row mt-4" *ngIf="meals.length < 1">
  <div class="col">
    <p class="labels">No Meal Plans Found</p>
  </div>
</div>
<div class="row" *ngIf="meals.length >0">
  <div class="col-md-4 mt-4" *ngFor="let meal of meals">
    <div class="tiles">
      <div class="row mt-2">
        <div class="col-md-2">
          <img style="width: 100%;" src="assets/img/mealimg.png" />
        </div>
        <div class="col-md-10">
          <div class="col-md-12 noPad labels"><label>{{meal.mealPlanName}}</label></div>
          <div class="col-md-12 noPad labelContent"><label>Date of Creation:
              {{formatDate(meal.createdDateTime)}} &nbsp;&nbsp; Time:
              {{formatTime(meal.createdDateTime)}}</label></div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-2"></div>
        <div class="col-md-10 dates left">
          Start Date: {{formatDate(meal.fromDate.split('T')[0])}} &nbsp;&nbsp;End Date:
          {{formatDate(meal.toDate.split('T')[0])}}
        </div>
      </div>
      <div class="row mt-4" *ngIf="isType == 4">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-sm-3">
              <button class="btn btnFirst btnLabel1" (click)="deleteMealPlan(meal.id)">
                Delete
              </button>
            </div>&nbsp;&nbsp;
            <div class="col-sm-3">
              <button class="btn btnSec btnLabel1" (click)="assignMealPlan(meal)">
                Assign
              </button>
            </div>&nbsp;&nbsp;
            <div class="col-sm-3">
              <button class="btn btnThird btnLabel"
                (click)="editMealPlan(meal.mealPlanId,meal.fromDate.split('T')[0],meal.toDate.split('T')[0], meal.id)">
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4" *ngIf="isType !=4 ">
        <div class="col-md-12">
          <div class="row">
            <div class="col-sm-4 mt-3" *ngIf="isType == 2">
              <span><img width="20" src="assets/img/completed.png" /></span>&nbsp;&nbsp;<span>Completed</span>
            </div>
            <div class="col-md-2"></div>
            <div class="col-sm-4" *ngIf="isType == 3">
              <button class="btn btnToDraft btnLabel1" (click)="moveToDraft(meal.id)">
                To Draft
              </button>
            </div>
            <div class="col-sm-4" *ngIf="isType == 1">
              <button class="btn btnToDraft btnLabel1" (click)="unassignMealPlan(meal.id)">
                Unassign
              </button>
            </div>
            <div class="col-sm-4 ml-4">
              <button class="btn btnView btnLabel"
                (click)="viewMealPlan(meal.mealPlanId,meal.fromDate.split('T')[0],meal.toDate.split('T')[0])">
                View
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="confirmAssign" tabindex="-1" role="dialog" aria-labelledby="confirmAssignLabel"
  aria-hidden="true" data-bs-backdrop="static">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content modal-size-style radius">
      <div class="modal-header text-center">
        <span class="title1  w-100">Meal plan already Exist!</span>
        <button type="button" class="close" aria-label="Close" data-bs-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body textCenter padding">
        <p class="modalText">You already have an active meal plan for the selected date.
          Would you like to remove or change the date for the existing meal
          plan?</p>
        <br />
        <span><button class="btnConfirm btnLabel" (click)="showcheckAssign()">Yes,
            Update the existing meal plan</button></span><span>
          <button class="btnConfirm1 btnLabel1" (click)="closeConfirmAssign()">No,
            Keep the existing meal plan</button> </span>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="checkAssign" tabindex="-1" role="dialog" aria-labelledby="checkAssignLabel"
  aria-hidden="true" data-bs-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content modal-size-style radius">
      <div class="modal-header text-center">
        <span class="title1  w-100">Confirm Date</span>
        <button type="button" class="close" aria-label="Close" data-bs-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body textCenter">
        <p class="modalText">New Meal Plan: {{this.newMealPlan?.mealPlanName}}</p>
        <p class="modalSubText">{{this.newMealPlan?.fromDate.split('T')[0]}} -
          {{this.newMealPlan?.toDate.split('T')[0]}}
        </p>
        <p class="modalText">Existing Meal Plan: {{this.exisitngMealPlanName}}</p>
        <p class="modalSubText">{{this.existingMealPlan?.fromDate.split('T')[0]}} -
          {{this.existingMealPlan?.toDate.split('T')[0]}}
        </p>
        <p class="modalText">Please select new end date for existing meal plan</p>
        <p><mat-form-field>
            <mat-label>Pick end date</mat-label>
            <input matInput [matDatepicker]="picker2" #dateRangeEnd2 [(ngModel)]="currentDate" [min]="minDate"
              (dateChange)="setMemMealDate(dateRangeEnd2)">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field></p>
        <p class="modalSubText">You End Date will be updated to {{memMealDate}} </p>
        <button class="col-md-2 btn btnSec btnLabel1" (click)="updateAndAssign()">Confirm</button>
      </div>
    </div>
  </div>
</div>