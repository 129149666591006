import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MemberService } from '../member-service/MemberService ';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lifestyle-condition',
  standalone: true,
  imports: [MatTableModule, CommonModule],
  templateUrl: './lifestyle-condition.component.html',
  styleUrl: './lifestyle-condition.component.css'
})
export class LifestyleConditionComponent {
  todayMeetings: any[] = [];
  upcomingMeetings: any[] = [];
  meetingsData: any;
  dataSource1 = new MatTableDataSource<any>;
  dataSource2 = new MatTableDataSource<any>;
  constructor(private memberService: MemberService, private route: ActivatedRoute) {

  }
  ngOnInit(): void {
    this.memberService.GetMeetings(this.route.snapshot.queryParamMap.get('id')).subscribe(data => {
      this.meetingsData = data['result'];
      this.dataSource1 = this.meetingsData;
      const today = new Date();
      const tomorrow = new Date();

      tomorrow.setDate(tomorrow.getDate() + 1);
      this.meetingsData.forEach((event: any) => {
        let m = event.eventDate.split('T')[0].split('-');
        let mnth = m[1];
        if (mnth[0] == '0') {
          mnth = mnth.replace(mnth[0], '');
        }
        const eventDate = new Date(+m[0], +mnth - 1, +m[2]);
        if (eventDate.toDateString() === today.toDateString()) {
          this.todayMeetings.push(event);
        } else if (eventDate > today) {
          this.upcomingMeetings.push(event);
          //this.dataSource2 = this.upcomingMeetings;
        }
      });
    });
  }
}
