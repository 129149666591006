import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedServiceService } from '../../services/shared-service.service';
import { Questions } from '../../models/questions';
import { LabWork, Meetings } from '../../models/lab-work';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  private apiBaseUrl: string;

  constructor(private http: HttpClient, private sharedService: SharedServiceService) {
    this.apiBaseUrl = sharedService.baseUrl();
  }


  // Get member by ID
  getMemberById(id: string): Observable<any> {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = `${this.apiBaseUrl}members/GetMemberById?memberId=${id}`;
    return this.http.get(url, { headers });
  }

  // Update member details
  updateMember(id: string, memberData: any): Observable<any> {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = `${this.apiBaseUrl}members?memberId=${id}`;
    return this.http.put<any>(url, memberData, { headers });
  }

  GetMemberQuestionnaire(id: any): Observable<any> {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + 'questionnaires?memberId=' + id;
    return this.http.get(url, { headers });
  }

  saveQuestionaire(questionsData: any) {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + 'questionnaires?memberId';
    return this.http.post<Questions>(url, questionsData, { headers })
  }
  GetLabWork(memberid: any, page: any): Observable<any> {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + 'LabWork/GetLabWork?memberId=' + memberid + '&pageNo=' + page + '&pageSize=5';
    return this.http.get<LabWork>(url, { headers });
  }
  GetMeetings(memberid: any): Observable<any> {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + 'events/GetEventByMemberId' + '?memberId=' + memberid;
    return this.http.get<Meetings>(url, { headers });
  }
  addProgressPlan(data: any) {
    const headers = this.sharedService.createAuthorizationHeader();
    return this.http.post(this.apiBaseUrl + 'progressNotes', data, { headers });
  }

  getProgressPlan(id: any, page: any) {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + 'progressNotes/GetProgressNotesByMemberId?memberId=' + id + '&pageNo=' + page + '&pageSize=10';
    return this.http.get(url, { headers });
  }
  getMemberMealPlan(id: any, filter: any, page: any) {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + 'mealplan/GetMemberMealPlanHistory?memberId=' + id + '&pageNo=' + page + '&pageSize=10&status=' + filter + '';
    return this.http.get(url, { headers });
  }
  uploadFile(formData: any) {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.sharedService.getapiBaseUrlOld() + 'LabWork';
    return this.http.post(url, formData, { headers });
  }
  getActivityMemberById(id: string, sdate: string, edate: string): Observable<any> {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = `${this.apiBaseUrl}healthData/activitybyMemberId?memberId=${id}&startDate=${sdate}&endDate=${edate}`;
    return this.http.get(url, { headers });
  }
  getWorkoutActivityMemberById(id: string, sdate: string, edate: string): Observable<any> {
    const headers = this.sharedService.createAuthorizationHeader();
    const url = `${this.apiBaseUrl}healthData/getworkoutacivity?memberId=${id}&startDate=${sdate}&endDate=${edate}`;
    return this.http.get(url, { headers });
  }
}
