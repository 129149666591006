<mat-list role="list" style="display: inline-flex;">
  <mat-list-item role="listitem">
    <div class="tab1" [ngClass]="{'activeTab1': subTab === 'Medication'}" (click)="subSelectedTab(1,'Medication')">
      <!-- <div class="activeTabContent1">Personality</div> -->
      <div class="tabContent1" [ngClass]="{'activeTabContent1': subTab === 'Medication'}">Personality</div>
    </div>
  </mat-list-item>
  <mat-list-item role="listitem">
    <!-- <div class="tab1" (click)="subSelectedTab(2)">
        <div class="tabContent1">LifeStyle </div>
    </div> -->
    <div class="tab1" [ngClass]="{'activeTab1': subTab === 'Diab'}" (click)="subSelectedTab(2,'Diab')">
      <div class="tabContent1" [ngClass]="{'activeTabContent1': subTab === 'Diab'}">Lifestyle</div>
    </div>
  </mat-list-item>
  <mat-list-item role="listitem">
    <div class="tab1" [ngClass]="{'activeTab1': subTab === 'Diet'}" (click)="subSelectedTab(3,'Diet')">
      <!-- <div class="tabContent1">Health & Medication</div> -->
      <div class="tabContent1" [ngClass]="{'activeTabContent1': subTab === 'Diet'}">Health & Medication</div>
    </div>
  </mat-list-item>
  <mat-list-item role="listitem">
    <div class="tab1" [ngClass]="{'activeTab1': subTab === 'Life'}" (click)="subSelectedTab(4,'Life')">
      <div class="tabContent1" [ngClass]="{'activeTabContent1': subTab === 'Life'}">Dietary Habits </div>
    </div>
  </mat-list-item>
  <mat-list-item role="listitem">
    <div class="tab1" [ngClass]="{'activeTab1': subTab === 'Fitness'}" (click)="subSelectedTab(5,'Fitness')">
      <div class="tabContent1" [ngClass]="{'activeTabContent1': subTab === 'Fitness'}">Fitness </div>
    </div>
  </mat-list-item>
</mat-list>

<div class="container-fluid" *ngIf="subTab=='Medication'">
  <div class="card">
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="iq-card">


            <div class="iq-card-header d-flex justify-content-between">

              <div class="iq-header-title">
                <h4 class="card-title">Stress Test</h4>
                <h6 class="mt-2" style="font-size: 15px;">
                  For each question choose from the following alternatives:
                </h6>
              </div>
            </div>

            <div class="iq-card-body">
              <div class="form-group">

                <div class="form-group col-md-12 p-3" style="background:#f4f4f4;border-radius: 15px;" *ngFor="let question of PersonalityStressQuestion">

                  <label for="city">{{question.question}}</label>

                  <div class="d-flex flex-row justify-content-around" style="width:30%">
                    <span class="d-flex align-items-end">Never</span>
                    <div class="d-flex flex-column options_Styles" *ngFor="let option of question.options" (click)="getOption(option,question.id)">
                      <p class="ml-2">{{option}}</p>
                      <!-- <p-radioButton class="radio_Styles" name="q2"  [value]="option" [checked]="selectedRadio._id==option"></p-radioButton> -->
                      <input type="radio" name="{{question.id}}" id="{{option}}" [checked]="!isSelectedAnswerBool ? option == question.answer : null || isSelected(question.id, option)" />
                    </div>

                    <span class="d-flex align-items-end">Very Often</span>

                  </div>
                  <div style="margin-top:16px;">
                    <input type="checkbox" [checked]="notAnsweredQuestionsStress.includes(question.id)" (click)="getOption('not answered', question.id)" style="transform: scale(1.2); margin-right: 8px;">
                    <span class="ml-1">Not Answered</span>
                  </div>

                </div>


                <div class="iq-header-title">

                  <h4 class="card-title mt-4">
                    Anxiety Test (GAD-7)
                  </h4>
                  <h6 class="mt-2" style="font-size: 15px;">
                    Over the last two weeks, how often have you been bothered by the following problems?
                    Not at all-0  Several days-1 More than half the days-2 Nearly every day-3
                  </h6>
                </div>
                <hr>
                <div class="form-group col-md-12 p-3" style="background:#f4f4f4;border-radius: 15px;" *ngFor="let question of PersonalityAnxietyQuestion">

                  <label for="city">{{question.question}}</label>

                  <div class="d-flex flex-row justify-content-around" style="width:30%">
                    <span class="d-flex align-items-end">Never</span>
                    <div class="d-flex flex-column options_Styles" *ngFor="let option of question.options" (click)="getOption(option,question.id)">
                      <p class="ml-2">{{option}}</p>

                      <!-- <p-radioButton class="radio_Styles" name="q2"  [value]="option" [checked]="selectedRadio._id==option"></p-radioButton> -->
                      <input type="radio" name="{{question.id}}" id="{{option}}" [checked]="!isSelectedAnswerBool ? option == question.answer : null || isSelected(question.id, option)" />
                    </div>

                    <span class="d-flex align-items-end">Very Often</span>

                  </div>
                  <div style="margin-top:16px;">
                    <input type="checkbox" [checked]="notAnsweredQuestionsAnxiety.includes(question.id)" (click)="getOption('not answered', question.id)" style="transform: scale(1.2); margin-right: 8px;">
                    <span class="ml-1">Not Answered</span>
                  </div>

                </div>


                <!-- ENAGRAM TEST ---------------------------------- -->
                <div class="iq-header-title">
                  <h4 class="card-title mt-4">
                    Enneagram Test
                  </h4>
                </div>
                <hr>

                <div class="form-group col-md-12 p-3 enneagram_stylings" style="background:#f4f4f4;border-radius: 15px;" *ngFor="let question of PersonalityEnneagramQuestion">
                  <label for="city">{{question.question}}</label>

                  <div class="d-flex flex-row justify-flex-start" style="width:100%;height:200px">

                    <ol class="ol1">
                      <li class="li1" *ngFor="let option of question.options" [ngClass]="{ active: !isSelectedAnswerBool ? option == question.answer : null || isSelected(question.id, option)}">
                        <div class=""  
                             (click)="getOption(option,question.id)"
                             >
                          {{option}}
                        </div>
                      </li>

                    </ol>



                  </div>
                  <div>
                    <div style="margin-top:16px;">
                      <input type="checkbox" [checked]="notAnsweredQuestions.includes(question.id)" (click)="getOption('not answered', question.id)" style="transform: scale(1.2); margin-right: 8px;">
                      <span class="ml-1">Not Answered</span>
                    </div>
                  </div>

                </div>

                <div class="form-group col-md-6 ">
                  <div class="flex-auto mt-3">
                    <button type="button" (click)="saveQuestion()" class="btn btn-primary" [disabled]="savePersonalityBtn">Save</button>
                    <span *ngIf="savePersonalityBtn" style="margin-left:10px;">Saving answers. Please wait...</span>
                  </div>
                </div>
              </div>



            </div>

          </div>
        </div>

      </div>
    </div>

  </div>
</div>
<div class="container-fluid" *ngIf="subTab=='Diab'">
  <div class="card">
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="iq-card">
            <div class="iq-card-body">
              <div class="form-group ">

                <div class="form-group col-md-12 p-3" style="background:#f4f4f4;border-radius: 15px;" *ngFor="let item of LifestyleTabTextQuestions">
                  <label>{{item.question}}</label>
                  <div class="d-flex flex-row justify-flex-start" style="width:100%;">
                    <input type="text" id={{item.id}} (change)="getLifestyleAnswers(item.id,$event)" [value]="item.answer" class="form-control" placeholder="Enter your answer">
                  </div>
                </div>

                <div class="form-group col-md-12 p-3" style="background:#f4f4f4;border-radius: 15px;" *ngFor="let item of LifestyleTabTimeQuestions">
                  <label>{{item.question}}</label>
                  <div class="d-flex flex-row justify-content-between" style="width:100%;">
                    <input type="time" class="form-control" (change)="getLifestyleAnswers(item.id,$event)" [value]="formatConversionHandler(item.answer)" placeholder="Enter your answer">
                  </div>
                  <div *ngIf="isLifestyleAnswered == true ? item.answer==null : ''" style="margin-top:10px;">
                    <input type="checkbox" (click)="getLifestyleAnswers(item.id,$event)" /> <span style="color:red;margin-left:10px;">Not Answered!</span>
                  </div>
                </div>

                <div class="form-group col-md-12 p-3" style="background:#f4f4f4;border-radius: 15px;" *ngFor="let item of LifestyleTabDropDownQuestions">
                  <label>{{item.question}}</label>
                  <div class="d-flex flex-row justify-flex-start" style="width:100%;">
                    <select class="form-control" (change)="getLifestyleAnswers(item.id,$event)" [(ngModel)]="item.answer">
                      <option>select from option</option>
                      <option *ngFor="let option of item.options" [value]="option.val">{{option.key}}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group col-md-6 ">
                  <div class="flex-auto mt-3">
                    <button (click)="saveLifestyleAnswers()" type="submit" class="btn btn-primary">Save</button> <span></span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
  </div>
</div>
<div class="container-fluid" *ngIf="subTab=='Diet'">
  <div class="card">
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="iq-card">
            <div class="iq-card-body">
              <div class="form-group">
                UPLOAD REPORT
                <div class="form-group col-md-12 p-3" style="background:#f4f4f4;border-radius: 15px;">
                  <label for="report">Upload</label>
                  <div class="d-flex flex-row justify-flex-start file_upload_styles" style="width:100%;">
                    <label for="file-upload-report" class="custom-file-upload">
                      <i class="fa fa-cloud-upload" style="margin-right: 10px;"></i>Upload Report
                    </label>
                    <input id="file-upload-report" type="file" (change)="onReportSelected($event)" [disabled]="isReportUploadDisabled" />
                    <!--<div class="label_file_upload">{{ selectedFileName }}</div>-->
                    <span style=" margin-top: 4px;">or</span>
                    <label style="margin-left: 20px;" for="file-upload-prescription" class="custom-file-upload">
                      <i class="fa fa-cloud-upload" style="margin-right: 10px;"></i>Upload Prescription
                    </label>
                    <input id="file-upload-prescription" type="file" (change)="onPrescriptionSelected($event)" [disabled]="isPrescriptionUploadDisabled" />
                    <!--<div class="label_file_upload">{{ selectedPrescriptionName }}</div>-->
                  </div>
                </div>
                <div class="form-group col-md-12 p-3" style="background:#f4f4f4;border-radius: 15px;" *ngFor="let item of HealthAndMedicationTextField">
                  <label for="city">{{item.question}}</label>
                  <div class="d-flex flex-row justify-flex-start" style="width:100%;">
                    <input type="text" id={{item.id}} (change)="getHealthAndMedicationAnswers(item.id,$event)" [value]="item.answer" class="form-control" placeholder="Enter your answer">
                  </div>
                </div>
                <form >
                  <div class="form-group col-md-12 p-3 healthAndMedication_stylings" style="background:#f4f4f4;border-radius: 15px;">
                    <label for="report">Do you have any family history of chronic diseases, such as heart disease or diabetes, or health conditions such as high blood pressure or high cholesterol?</label>
                   
                  
                  <details *ngFor="let question of HealthAndMedicationMultiSelect">
                    <summary> {{ question.question }}</summary>
                    <!--<ol><li  *ngFor="let item of getFormArrayByName(question.question.toLowerCase()).controls; let i = index" [ngClass]="{'selected': item.value}"
   onclick="toggleSelection(question.question.toLowerCase(), i)">
    <p >{{ question.options[i] }}</p>
  </li></ol>-->
                    <div class="d-flex flex-row justify-flex-start" style="width:100%;height:48px">
                      <ol class="ol">
                        <li class="li" *ngFor="let item of getFormArrayByName(question.question.toLowerCase()).controls; let i = index"
                            [ngClass]="{'selected': item.value}"
                            (click)="toggleSelection(question.question.toLowerCase(), i)">
                          {{ question.options[i] }}
                        </li>
                      </ol>
                    </div>
                  </details>
                     
                  </div>
                </form>



                <div class="form-group col-md-12 p-3" style="background:#f4f4f4;border-radius: 15px;" *ngFor="let item of HealthAndMedicationPlainText">
                  <label>{{item.question}}</label>
                  <div class="d-flex flex-row justify-flex-start" style="width:100%;">
                    <input type="text" id={{item.id}} (change)="getHealthAndMedicationAnswers(item.id,$event)" [value]="item.answer" class="form-control" placeholder="Enter your answer">
                  </div>
                </div>


                <div class="form-group col-md-12 p-3" style="background:#f4f4f4;border-radius: 15px;" *ngFor="let item of HealthAndMedicationMultiSelectWithKeys">
                  <label>{{item.question}}</label>
                  <div class="d-flex flex-row justify-flex-start" style="width:100%;">
                    <select class="form-control" (change)="getHealthAndMedicationAnswers(item.id,$event)" [(ngModel)]="item.answer">
                      <option disabled="true">select from option</option>
                      <option *ngFor="let option of item.options" [value]="option.val">{{option.key}}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group col-md-12 p-3 " style="background:#f4f4f4;border-radius: 15px;">


                  <label>{{HealthAndMedicationListFields[0].question}}</label>
                  <select class="form-control" [(ngModel)]="selectedMedications">
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                  <div *ngIf="selectedMedications=='Yes'">
                    <div class="d-flex flex-row justify-content-between" style="width:100%;margin-bottom: 10px;margin-top:10px;" *ngFor="let meds of medications;let i = index;trackBy: trackByFn">
                      <input type="text" style="width: 90%;" [(ngModel)]="medications[i]" class="form-control" placeholder="Enter medicines" (ngModelChange)="logMedications()">
                      <button (click)="removeMedication(i)" class="btn btn-danger">Remove</button>
                    </div>
                    <button (click)="addMedications(HealthAndMedicationListFields[0].id)" class="btn btn-success">Add</button>
                  </div>
                </div>

                <div class="form-group col-md-12 p-3 " style="background:#f4f4f4;border-radius: 15px;">

                  <label>{{HealthAndMedicationListFields[1].question}}</label>
                  <select class="form-control" [(ngModel)]="selectedSupplements">
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                  <div *ngIf="selectedSupplements=='Yes'">
                    <div class="d-flex flex-row justify-content-between" style="width:100%;margin-bottom: 10px;margin-top:10px;" *ngFor="let input of supplements;let i = index;trackBy: trackByFnSupplements">
                      <input type="text" [(ngModel)]="supplements[i]" style="width: 90%;" class="form-control" placeholder="Enter supplements" (ngModelChange)="logSupplements()">

                      <button (click)="removeSupplement(i)" class="btn btn-danger">Remove</button>
                    </div>
                    <button (click)="addSupplements()" class="btn btn-success">Add</button>
                  </div>
                </div>

                <div class="form-group col-md-6 ">
                  <div class="flex-auto mt-3">
                    <button type="submit" (click)="saveHealthAndMedicationAnswers()" class="btn btn-primary">Save</button> <span></span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="subTab=='Life'">
  <div class="card">
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="iq-card">
            <div class="iq-card-body">
              <div class="form-group">

                <div class="form-group col-md-12 p-3" style="background:#f4f4f4;border-radius: 15px;" *ngFor="let item of DietaryMealsTable">
                  <!--<label for="city">{{item.question}}</label>-->
                  <!--<div class="d-flex flex-row justify-flex-start" style="width:100%;">
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">Meal</th>
                          <th scope="col">Time</th>
                          <th scope="col">Information</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>breakfast</td>
                          <td>08:00 AM</td>
                          <td>4 glass water, soacked almonds</td>
                        </tr>
                      </tbody>

                    </table>
                  </div>-->
                </div>
                <div class="form-group col-md-12 p-3" style="background:#f4f4f4;border-radius: 15px;" *ngFor="let item of DietaryTab">
                  <label for="city">{{item.question}}</label>
                  <div class="d-flex flex-row justify-flex-start" style="width:100%;">
                    <input type="text" id={{item.id}} (change)="getDietary(item.id,$event,null)" [value]="item.answer" class="form-control" placeholder="Enter your answer">
                  </div>
                </div>

                <div class="form-group col-md-12 p-3" style="background:#f4f4f4;border-radius: 15px;" *ngFor="let question of DietaryRadioQuestion">

                  <label for="city">{{question.question}}</label>

                  <div class="d-flex flex-row justify-content-around" style="width:30%">
                    <span class="d-flex align-items-end">Never</span>
                    <div class="d-flex flex-column options_Styles" *ngFor="let option of question.options" (click)="getDietary(option,question.id,'radio')">
                      <p class="ml-2">{{option}}</p>
                      <input type="radio" name="{{question.id}}" id="{{option}}" [checked]="!isSelectedDietaryAnswerBool ? option == question.answer : null || isSelectedDietary(question.id, option)" />
                    </div>

                    <span class="d-flex align-items-end">Very Often</span>

                  </div>
                </div>

                <div class="form-group col-md-12 p-3" style="background:#f4f4f4;border-radius: 15px;" *ngFor="let item of DietaryDropDownQuestions">
                  <label for="city">{{item.question}}</label>
                  <div class="d-flex flex-row justify-flex-start" style="width:100%;">
                    <select class="form-control" (change)="getDietary(item.id,$event,'')" [(ngModel)]="item.answer">
                      <option>select from option</option>
                      <option *ngFor="let option of item.options" [value]="option">{{option}}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group col-md-6 ">
                  <div class="flex-auto mt-3">
                    <button type="submit" (click)="saveDietaryQuestions()" class="btn btn-primary">Save</button> <span></span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<div class="container-fluid" *ngIf="subTab=='Fitness'">
  <div class="card">
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="iq-card">
            <div class="iq-card-body">
              <div class="form-group">
                <div class="form-group col-md-12 p-3" style="background:#f4f4f4;border-radius: 15px;" *ngFor="let item of FitnessTab">
                  <label for="city">{{item.question}}</label>
                  <div class="d-flex flex-row justify-flex-start" style="width:100%;">
                    <input type="text" id={{item.id}} (change)="getFitness(item.id,$event)" [value]="item.answer"    class="form-control" placeholder="Enter your answer">
                  </div>
                  <div *ngIf="isFitnessAnswered == true ? item.answer==null : ''" style="margin-top:10px;">
                    <input type="checkbox" (click)="getFitness(item.id,$event)" /> <span style="color:red;margin-left:10px;">Not Answered!</span>
                  </div>
                </div>

                <div class="form-group col-md-6 ">
                  <div class="flex-auto mt-3">
                    <button (click)="saveFitnessQuestions()" type="submit" class="btn btn-primary">Save</button> <span></span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
