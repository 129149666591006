import { Component,LOCALE_ID } from '@angular/core';
import {MatListModule} from '@angular/material/list';
import { AllLogsComponent } from './all-logs/all-logs.component';
import { TotalNutriComponent } from './total-nutri/total-nutri.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatLabel } from '@angular/material/form-field';
import { MatHint } from '@angular/material/form-field';
import { MatDateRangeInput } from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import { FoodService } from './food.service';
import { FormsModule } from '@angular/forms';
import { 
  formatDate 
 } 
  from '@angular/common'; 
  import { NgIf } from '@angular/common';
  import { NgFor } from '@angular/common';
  import { NgClass } from '@angular/common';
  import { EditFoodComponent } from './edit-food/edit-food.component';
  import { ActivatedRoute } from '@angular/router';
  
@Component({
  selector: 'app-all-food-logs',
  standalone: true,
  imports: [FormsModule,EditFoodComponent,NgClass,NgFor,NgIf,MatNativeDateModule,MatInputModule,MatListModule,AllLogsComponent,TotalNutriComponent,MatDatepickerModule,MatFormFieldModule,MatLabel,MatHint,MatDateRangeInput],
  templateUrl: './all-food-logs.component.html',
  styleUrl: './all-food-logs.component.css'
})
export class AllFoodLogsComponent {
  selectedDateRange: { start: Date | null, end: Date | null } = { start: null, end: null };

  allData:any=[];
  foodDetails:any=[];
  currentDate:any = formatDate(Date.now(), 'yyyy-MM-dd','en-US');
  endDate:any = formatDate(Date.now(), 'yyyy-MM-dd','en-US');
  selectedType:any='All';
  isEdit:any=1;
  memberId:any;
  memberName:any;
  isList:any;
  currentDateFormat = new Date(this.currentDate).toLocaleString('en-us',{day:'numeric',month:'short', year:'numeric'})
  constructor(private FoodService:FoodService,private route:ActivatedRoute) {
  }
  // Add these properties to the class
  private readonly DATE_STORAGE_KEY = 'foodLogDateRange';
  
  ngOnInit(): void {
    this.isList = this.route.snapshot.queryParamMap.get('islist');
    this.memberId = this.route.snapshot.queryParamMap.get('id');
    this.memberName= this.route.snapshot.queryParamMap.get('name');
    
    if (this.isList == 5){  
     // this.foodDetails =localStorage.getItem('foodData');
      //console.log( data);
      // this.foodDetails = JSON.parse(this.foodDetails);
      this.selectedType =  localStorage.getItem('selectedType');
      this.selectedType = JSON.parse(this.selectedType);
      // this.allData = localStorage.getItem('wholeData');
      // this.allData = JSON.parse(this.allData);

      this.currentDate = localStorage.getItem('startDate');
      this.currentDate = formatDate(JSON.parse(this.currentDate), 'yyyy-MM-dd','en-US');
      
      this.endDate = localStorage.getItem('endDate');
      this.endDate =  formatDate(JSON.parse(this.endDate), 'yyyy-MM-dd','en-US');

      const savedDates = this.getSavedDateRange();
      if (savedDates.fromDt && savedDates.toDt) {
        // Convert strings to Date objects
        let from = savedDates.fromDt.split('-');
        let to = savedDates.toDt.split('-');
        this.selectedDateRange = {
          start: new Date(from[0],from[1],from[2]),
          end: new Date(to[0],to[1],to[2])
        };
        this.getFoodLog(savedDates.fromDt, savedDates.toDt);
      }
     
      
      
    }else{
      this.destorySession();
      this.getFoodLog(this.currentDate,this.currentDate);
    }
    
  }


  getFoodLog(fromDt:any,toDt:any){
    localStorage.setItem(this.DATE_STORAGE_KEY, JSON.stringify({ fromDt, toDt }));
    
    let id = this.route.snapshot.queryParamMap.get('id') || '';
    this.FoodService.getFoodLogs(id,fromDt,toDt).subscribe(response => {    
      this.foodDetails = response;
      this.allData=  response;
      //this.allData = this.allData.foodLogs;
      this.foodDetails = this.foodDetails.foodLogs;
      //console.log( this.foodDetails);
      if (this.isList == 5){  
          this.foodFilter(this.selectedType);
      }
    });
  }
  // Add method to get saved dates
getSavedDateRange(): { fromDt: any, toDt: any } {
  const savedRange = localStorage.getItem(this.DATE_STORAGE_KEY);
  if (savedRange) {
      return JSON.parse(savedRange);
  }
  return { fromDt: null, toDt: null }; // Default values if none saved
}


  filterByDate(StartDate:HTMLInputElement,endDate:HTMLInputElement){
      // console.log(this.dateFormat(StartDate.value));
      // console.log(this.dateFormat(endDate.value));
     // this.destorySession();
     if(StartDate.value != '' && endDate.value != ''){
     this.currentDate = StartDate.value;
     this.endDate = endDate.value;
    // this.foodDetails = [];
      this.getFoodLog(this.dateFormat(StartDate.value),this.dateFormat(endDate.value));
     }
      

  }
destorySession(){
 // localStorage.setItem('foodData','');
  localStorage.setItem('selectedType','');
 // localStorage.setItem('wholeData','');
  localStorage.setItem('currentDate','');
  localStorage.setItem('endDate','');
  //localStorage.setItem('storedData','');
  

}
  dateFormat(date:any){
    return formatDate(date, 'yyyy-MM-dd','en-US');
  }

  foodFilter(type:any){
      //console.log(type);
      //this.destorySession();
      this.selectedType=type;
      this.foodDetails=[];
      //console.log(this.allFoodDetails); 
      
      if(type == 'All'){
        this.foodDetails = this.allData.foodLogs;
      }else{
      let foods = this.allData.foodLogs;
      console.log(foods); 
      let allFood:any=[];
      
      for(let j=0;j<foods.length;j++){
        allFood.push({foodLogsInfo:[]});
          let calories=0;
          let protien=0; 
          let carbs=0;
          let fat=0;
          const foodData  = foods[j].foodLogsInfo.filter((x:any) => x.mealType == type);
          //this.foodDetails[j].foodLogsInfo = [];
         // console.log(foodData);
          for(let i =0;i<foodData.length;i++){
                calories = calories + foodData[i].total.calories;
                protien = protien + foodData[i].total.protein;
                carbs = carbs + foodData[i].total.carbs;
                fat = fat + foodData[i].total.fat;
                allFood[j].foodLogsInfo.push(foodData[i]);

               // allFood[j].logTime=
              } 
              allFood[j].date=foods[j].date;
              allFood[j].totalCalories = calories;
              allFood[j].totalProteins = protien;
              allFood[j].totalCarbs = carbs;
              allFood[j].totalFats = fat;    
      }
      //console.log(allFood);
      //this.foodDetails = [];
      this.foodDetails = allFood;
      //console.log( this.foodDetails);
    }
     // console.log(this.allData);
      // for(let j=0;j<foods.length;j++){
      //   let calories=0;
      //   let protien=0;
      //   let carbs=0;
      //   let fat=0;
      //   const foodData  = foods[j].foodLogsInfo.filter((x:any) => x.mealType == type);
      //   this.foodDetails[j].foodLogsInfo = [];
      //   console.log(this.allFoodDetails); 
      //   for(let i =0;i<foodData.length;i++){
      //     calories = calories + foodData[i].total.calories;
      //     protien = protien + foodData[i].total.protein;
      //     carbs = carbs + foodData[i].total.carbs;
      //     fat = fat + foodData[i].total.fat;
      //     this.foodDetails[j].foodLogsInfo.push(foodData[i]);
      //   }     
      //   this.foodDetails[j].totalCalories =    calories;
      //   this.foodDetails[j].totalProteins =    protien;
      //   this.foodDetails[j].totalCarbs =    carbs;
      //   this.foodDetails[j].totalFats =    fat;
      // }
     
     // console.log(this.foodDetails);
}

ngOnDestroy() {
  
  //  localStorage.setItem('foodData', JSON.stringify(this.foodDetails));
   // localStorage.setItem('wholeData', JSON.stringify(this.allData));
    localStorage.setItem('selectedType', JSON.stringify(this.selectedType));
    localStorage.setItem('startDate', JSON.stringify(this.currentDate));
    localStorage.setItem('endDate', JSON.stringify(this.endDate));
  
}
}
