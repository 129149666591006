
<table mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="CreatedDateTime">
    <th mat-header-cell *matHeaderCellDef> Date Uploaded </th>
    <td mat-cell *matCellDef="let element">{{element.createdDateTime | date: 'dd-MM-yyyy' }}  </td>
  </ng-container>
  <ng-container matColumnDef="Type">
    <th mat-header-cell *matHeaderCellDef> Document Type </th>
    <td mat-cell *matCellDef="let element"> {{element.type}}</td>
  </ng-container>
  <ng-container matColumnDef="Actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let row" class="flex" style="padding: 10px;">
      <!--<a href="{{row.uploadFileURL}}" class="memberDashboard1" target="_blank" rel="noopener noreferrer">View</a>-->
      <a data-toggle="modal" class="memberDashboard1" data-target="#labWorkModal" (click)="openModal(row.uploadFileURL)">View</a> 
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div style="text-align:center;display:flex;">
  <mat-paginator #paginator
                 (page)="pageChangeEvent($event)"
                 [length]="this.totalRecords"
                 [pageSize]="pageSize"
                 [pageIndex]="0"
                 showFirstLastButton>
  </mat-paginator>
</div>

<div class="modal fade" id="labWorkModal" tabindex="-1" role="dialog" aria-labelledby="labWorkModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg " role="document">
    <div class="modal-content modal-size-style">
      <div class="modal-header">
        <h5 class="modal-title" id="labWorkModalLabel">View Document</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img *ngIf="isImage" width="100%" [src]="modalDocumentURL" />
        <iframe *ngIf="isImage==false" id="frameStyle" [src]="modalDocumentURL" frameborder="0" width="100%" height="500px"></iframe>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
