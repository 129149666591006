<div class="row">
    <img *ngIf="isback == 2" style="width:6%" (click)="backBtn()" src="assets/img/backbtn.png" />
  </div>
  <!--<app-meal-member-basic-details [memberDetail]="memberId"></app-meal-member-basic-details>-->
  <div class="row mt-5 ml-2" *ngIf="showTitle">
    <div class="title"> {{meals[0].mealPlan.planName}}</div>   
  </div>
  <div *ngIf="isback == 1">
    
    <!-- Ongoing and Completed Meal Plans -->
    <div >
      
      <div *ngFor="let mealDt of mealDate; let j = index">
        <div   class="subTitle mt-4" *ngIf="!meals[0].unassigned && mealResult[j].length > 0">
          <span class="smallTitle">Meal Plan Assign From</span> {{ getDateFormat(mealDt.startDt) }}
          <span class="smallTitle">To</span> {{ getDateFormat(mealDt.endDt) }}
        </div>
        <div *ngIf="mealResult.length > 0">
          <div class="row mt-5" *ngFor="let meal of mealResult[j]; let i = index">
            <div class="col-md-12 mt-4">
              <div class="subTitle capitalize">{{ meal.day }}</div>
            </div>
            <div class="mt-4" *ngIf="meal.meals.length < 1">
              <h5>No Meal For {{ meal.day }}</h5>
            </div>
            <div class="col-6 mt-3" *ngFor="let recipe of meal.meals">
              <div class="meal" *ngIf="!recipe.isFasting">
                <div class="row flex">
                  <div class="col mealType">{{ recipe.recipes[0].recipeType }}</div>
                  <div class="col time" style="text-align: right;" *ngIf="recipe.mealtime">
                    {{ getDate(recipe.mealtime) }}
                  </div>
                </div>
                <div class="row flex mt-4">
                  <div class="col">
                    <div class="col-md-12 ml-4">
                      <div class="mealName">
                        {{ recipe.recipes[0].recipeName }},
                        {{ getType(recipe.recipes[0].measure, recipe.recipes[0].measurementUnitValue, recipe.recipes[0].unit) }}
                      </div>
                    </div>
                    <div class="row mt-5">
                      <div class="col-3">
                        <div class="col-md-12 flex">
                          <img class="imgClass" src="/assets/img/Ellipse 137.png" /> &nbsp;
                          <div class="nutriTitle mt-1">Calories</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.recipes[0].cals }}</div>
                        <div class="col-md-12 mt-2 unit ml-4">cal</div>
                      </div>
                      <div class="col-3">
                        <div class="col-md-12 flex">
                          <img class="imgClass" src="/assets/img/Ellipse 138.png" /> &nbsp;
                          <div class="nutriTitle mt-1">Protein</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">
                          <ng-container *ngIf="recipe?.recipes?.length > 0; else noProtein">
                              {{ recipe.recipes[0]?.protien || 'N/A' }}
                          </ng-container>
                          <ng-template #noProtein>
                              No protein data available
                          </ng-template>
                      </div>
                        <div class="col-md-12 mt-2 unit ml-4">grams</div>
                      </div>
                      <div class="col-3">
                        <div class="col-md-12 flex">
                          <img class="imgClass" src="/assets/img/Ellipse 139.png" /> &nbsp;
                          <div class="nutriTitle mt-1">Carbs</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.recipes[0].carbs }}</div>
                        <div class="col-md-12 mt-2 unit ml-4">grams</div>
                      </div>
                      <div class="col-3">
                        <div class="col-md-12 flex">
                          <img class="imgClass" src="/assets/img/Ellipse 140.png" /> &nbsp;
                          <div class="nutriTitle mt-1">Fat</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-3">{{ recipe.recipes[0].fat }}</div>
                        <div class="col-md-12 mt-2 unit ml-3">grams</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 textAlign mt-4 flex">
                  <button class="btn btnClass" (click)="viewMealPlan(recipe.recipes[0].recipeId)">View More Details</button>
                </div>
              </div>
              <div class="meal" *ngIf="recipe.isFasting">
                <div class="col-md-12">
                  <div class="col mealType">Fasting</div>
                </div>
                <div class="row flex mt-4">
                  <div class="col-2">
                    <img src="/assets/img/fastingIcon.png" />
                  </div>
                  <div class="col-9 ml-1">
                    <div class="row">
                      <div class="col-sm">
                        <div class="col-md-12 flex">
                          <div class="nutriTitle mt-2">Start Time</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.fastings[0].fromTime }}</div>
                      </div>
                      <div class="col-sm">
                        <div class="col-md-12 flex">
                          <div class="nutriTitle mt-2">End Time</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.fastings[0].toTime }}</div>
                      </div>
                      <div class="col-sm">
                        <div class="col-md-12 flex">
                          <div class="nutriTitle mt-2">Duration</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.fastings[0].duration }}h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Draft Meal Plans -->
    <div *ngIf="isType === 3">
      <div *ngFor="let mealDt of draft; let j = index">
        <div class="subTitle mt-4" *ngIf="draft.length > 0">
          <span class="smallTitle">Meal Plan Assign From</span> {{ getDateFormat(draft[j].fromDate) }}
          <span class="smallTitle">To</span> {{ getDateFormat(draft[j].toDate) }}
        </div>
        <div *ngIf="draft.length > 0">
          <div class="row mt-5" *ngFor="let meal of draft[j].meals; let i = index">
            <div class="col-md-12 mt-4">
              <div class="subTitle capitalize">{{ meal.day }}</div>
            </div>
            <div class="mt-4" *ngIf="meal.meals.length < 1">
              <h5>No Meal For {{ meal.day }}</h5>
            </div>
            <div class="col-6 mt-3" *ngFor="let recipe of meal.meals">
              <div class="meal" *ngIf="!recipe.isFasting">
                <div class="row flex">
                  <div class="col mealType">{{ recipe.recipes[0].recipeType }}</div>
                  <div class="col time" style="text-align: right;" *ngIf="recipe.mealtime">
                    {{ getDate(recipe.mealtime) }}
                  </div>
                </div>
                <div class="row flex mt-4">
                  <div class="col">
                    <div class="col-md-12 ml-4">
                      <div class="mealName">
                        {{ recipe.recipes[0].recipeName }},
                        {{ getType(recipe.recipes[0].measure, recipe.recipes[0].measurementUnitValue, recipe.recipes[0].unit) }}
                      </div>
                    </div>
                    <div class="row mt-5">
                      <div class="col-3">
                        <div class="col-md-12 flex">
                          <img class="imgClass" src="/assets/img/Ellipse 137.png" /> &nbsp;
                          <div class="nutriTitle mt-1">Calories</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.recipes[0].cals }}</div>
                        <div class="col-md-12 mt-2 unit ml-4">cal</div>
                      </div>
                      <div class="col-3">
                        <div class="col-md-12 flex">
                          <img class="imgClass" src="/assets/img/Ellipse 138.png" /> &nbsp;
                          <div class="nutriTitle mt-1">Protein</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.recipes[0].protien }}</div>
                        <div class="col-md-12 mt-2 unit ml-4">grams</div>
                      </div>
                      <div class="col-3">
                        <div class="col-md-12 flex">
                          <img class="imgClass" src="/assets/img/Ellipse 139.png" /> &nbsp;
                          <div class="nutriTitle mt-1">Carbs</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.recipes[0].carbs }}</div>
                        <div class="col-md-12 mt-2 unit ml-4">grams</div>
                      </div>
                      <div class="col-3">
                        <div class="col-md-12 flex">
                          <img class="imgClass" src="/assets/img/Ellipse 140.png" /> &nbsp;
                          <div class="nutriTitle mt-1">Fat</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-3">{{ recipe.recipes[0].fat }}</div>
                        <div class="col-md-12 mt-2 unit ml-3">grams</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 textAlign mt-4 flex">
                  <button class="btn btnClass" (click)="viewMealPlan(recipe.recipes[0].recipeId)">View More Details</button>
                </div>
              </div>
              <div class="meal" *ngIf="recipe.isFasting">
                <div class="col-md-12">
                  <div class="col mealType">Fasting</div>
                </div>
                <div class="row flex mt-4">
                  <div class="col-2">
                    <img src="/assets/img/fastingIcon.png" />
                  </div>
                  <div class="col-9 ml-1">
                    <div class="row">
                      <div class="col-sm">
                        <div class="col-md-12 flex">
                          <div class="nutriTitle mt-2">Start Time</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.fastings[0].fromTime }}</div>
                      </div>
                      <div class="col-sm">
                        <div class="col-md-12 flex">
                          <div class="nutriTitle mt-2">End Time</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.fastings[0].toTime }}</div>
                      </div>
                      <div class="col-sm">
                        <div class="col-md-12 flex">
                          <div class="nutriTitle mt-2">Duration</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.fastings[0].duration }}h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
  
        <div class="text-right">
          <button 
          class="btn btnClass" 
          (click)="assignMealPlan(mealDt.memberId, mealDt.mealPlanId)">
          Assign Meal Plan
        </button>
        </div>
     
      </div>
    </div>
  
    <div *ngIf="isType === 4">
      <div *ngFor="let mealDt of upcomingmeal; let j = index">
        <div class="subTitle mt-4" *ngIf="upcomingmeal.length > 0">
          <span class="smallTitle">Meal Plan Assign From</span> {{ getDateFormat(upcomingmeal[j].fromDate) }}
          <span class="smallTitle">To</span> {{ getDateFormat(upcomingmeal[j].toDate) }}
        </div>
        <div *ngIf="upcomingmeal.length > 0">
          <div class="row mt-5" *ngFor="let meal of upcomingmeal[j].meals; let i = index">
            <div class="col-md-12 mt-4">
              <div class="subTitle capitalize">{{ meal.day }}</div>
            </div>
            <div class="mt-4" *ngIf="meal.meals.length < 1">
              <h5>No Meal For {{ meal.day }}</h5>
            </div>
            <div class="col-6 mt-3" *ngFor="let recipe of meal.meals">
              <div class="meal" *ngIf="!recipe.isFasting">
                <div class="row flex">
                  <div class="col mealType">{{ recipe.recipes[0].recipeType }}</div>
                  <div class="col time" style="text-align: right;" *ngIf="recipe.mealtime">
                    {{ getDate(recipe.mealtime) }}
                  </div>
                </div>
                <div class="row flex mt-4">
                  <div class="col">
                    <div class="col-md-12 ml-4">
                      <div class="mealName">
                        {{ recipe.recipes[0].recipeName }},
                        {{ getType(recipe.recipes[0].measure, recipe.recipes[0].measurementUnitValue, recipe.recipes[0].unit) }}
                      </div>
                    </div>
                    <div class="row mt-5">
                      <div class="col-3">
                        <div class="col-md-12 flex">
                          <img class="imgClass" src="/assets/img/Ellipse 137.png" /> &nbsp;
                          <div class="nutriTitle mt-1">Calories</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.recipes[0].cals }}</div>
                        <div class="col-md-12 mt-2 unit ml-4">cal</div>
                      </div>
                      <div class="col-3">
                        <div class="col-md-12 flex">
                          <img class="imgClass" src="/assets/img/Ellipse 138.png" /> &nbsp;
                          <div class="nutriTitle mt-1">Protein</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.recipes[0].protien }}</div>
                        <div class="col-md-12 mt-2 unit ml-4">grams</div>
                      </div>
                      <div class="col-3">
                        <div class="col-md-12 flex">
                          <img class="imgClass" src="/assets/img/Ellipse 139.png" /> &nbsp;
                          <div class="nutriTitle mt-1">Carbs</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.recipes[0].carbs }}</div>
                        <div class="col-md-12 mt-2 unit ml-4">grams</div>
                      </div>
                      <div class="col-3">
                        <div class="col-md-12 flex">
                          <img class="imgClass" src="/assets/img/Ellipse 140.png" /> &nbsp;
                          <div class="nutriTitle mt-1">Fat</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-3">{{ recipe.recipes[0].fat }}</div>
                        <div class="col-md-12 mt-2 unit ml-3">grams</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 textAlign mt-4 flex">
                  <button class="btn btnClass" (click)="viewMealPlan(recipe.recipes[0].recipeId)">View More Details</button>
                </div>
              </div>
              <div class="meal" *ngIf="recipe.isFasting">
                <div class="col-md-12">
                  <div class="col mealType">Fasting</div>
                </div>
                <div class="row flex mt-4">
                  <div class="col-2">
                    <img src="/assets/img/fastingIcon.png" />
                  </div>
                  <div class="col-9 ml-1">
                    <div class="row">
                      <div class="col-sm">
                        <div class="col-md-12 flex">
                          <div class="nutriTitle mt-2">Start Time</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.fastings[0].fromTime }}</div>
                      </div>
                      <div class="col-sm">
                        <div class="col-md-12 flex">
                          <div class="nutriTitle mt-2">End Time</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.fastings[0].toTime }}</div>
                      </div>
                      <div class="col-sm">
                        <div class="col-md-12 flex">
                          <div class="nutriTitle mt-2">Duration</div>
                        </div>
                        <div class="col-md-12 mt-2 val ml-4">{{ recipe.fastings[0].duration }}h</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  </div>
  
  <div *ngIf="draft.length === 0 && isType === 3">
      No Meals in Draft!!!
  </div>
  
  <div *ngIf="isback == 2">
    <div class="title mt-4">{{ viewDetials.recipeName }}</div>
    <div class="content mt-4">{{ viewDetials.shortDescription }}</div>
    <div class="col-md-12 mt-4 flex" style="padding: 0;"></div>
    <div class="col-md-6">
      <mat-list role="list" style="display: flex;">
        <mat-list-item class="col-md-3 tab" [ngClass]="{'active': tabtype === 1}" (click)="tabShow(1)" role="listitem">
          <div>
            <div class="tabContent">Ingredients</div>
          </div>
        </mat-list-item>
        <mat-list-item class="col-md-4 tab" [ngClass]="{'active': tabtype === 2}" role="listitem" (click)="tabShow(2)">
          <div>
            <div class="tabContent">Cooking Methods</div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
    <div class="row contentList mt-5">{{ viewDetials.recipeName }}</div>
    <div class="row mt-2" *ngIf="isIngredient">
      <mat-list>
        <mat-list-item *ngFor="let ingredient of viewDetials.ingredients">
          <span class="contentList" matListItemTitle>
              <ng-container *ngIf="!ingredient.isEditing && isType === 3">
                  {{ ingredient.qty }} {{ ingredient.measureUnit }} {{ ingredient.ingredientName }}
                  <button class="edit-btn" (click)="ingredient.isEditing = true">
                    <i class="far fa-edit"></i>
                  </button>
                </ng-container>
            <ng-container *ngIf="ingredient.isEditing && isType === 3">
              <input [(ngModel)]="ingredient.qty" placeholder="Quantity" />
              <input [(ngModel)]="ingredient.measureUnit" placeholder="Unit" />
              <span class="ingredient-name">{{ ingredient.ingredientName }}</span>
              <button class="save-btn" (click)="ingredient.isEditing = false">
                  <i class="fas fa-save"></i>
                </button>
            </ng-container>
            <ng-container *ngIf="isType !== 3">
              {{ ingredient.qty }} {{ ingredient.measureUnit }} {{ ingredient.ingredientName }}
            </ng-container>
          </span>
        </mat-list-item>
        <mat-list-item  *ngIf="isType === 3">
          <button class="btn btn-primary save-all-btn" (click)="saveAllIngredients()">
              <i class="fas fa-save"></i> Save All
            </button>
      </mat-list-item>
  </mat-list>
  
  
  
    </div>
    <div class="row mt-2" *ngIf="isCooking">
      <mat-list>
        <mat-list-item *ngFor="let cook of viewDetials.preparationSteps">
          <span class="contentList mt-3" matListItemTitle>
            Step {{ cook.step }} : {{ cook.description }}
          </span>
        </mat-list-item>
      </mat-list>
    </div>
    
  </div>
