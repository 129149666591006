import { Component } from '@angular/core';
import { MealMemberBasicDetailsComponent } from '../../meal-plan/meal-member-basic-details/meal-member-basic-details.component';
import { MealPlanServiceV2 } from '../meal-plan-v2.service';
import { MealPlanService } from '../../meal-plan/meal-plan.service';
import { ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgClass, formatDate, NgFor } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDateRangeInput } from '@angular/material/datepicker';
import { MatLabel } from '@angular/material/form-field';
import { MatHint } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { ToastService } from '../../services/toast.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SharedServiceService } from '../../services/shared-service.service';
import { PreferenceComponent } from '../../meal-plan/preference/preference.component';
import { NgIf } from '@angular/common';
import { Router } from "@angular/router";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
declare var $: any;

@Component({
  selector: 'view-edit-plan',
  standalone: true,
  imports: [NgxMaterialTimepickerModule, MatProgressSpinnerModule, NgIf, PreferenceComponent, MealMemberBasicDetailsComponent, FormsModule, NgClass, NgFor, MatDateRangeInput, MatDatepickerModule, MatFormFieldModule, MatLabel, MatHint, MatNativeDateModule, MatInputModule],
  templateUrl: './view-edit-plan.component.html',
  styleUrl: './view-edit-plan.component.css'
})

export class ViewEditPlanComponent {

  id: any;
  memberId: any;
  selectedMealIds: any = [];
  selectedRecipeIds: any = [];
  fromDate: any;
  toDate: any;
  mode: any = 1;
  memberMealId: any;
  isLoading: any = 2;
  userMessage: string = '';
  planDetails: any = [];
  userId: any;
  chatResponse: any;
  error: any = 0;
  showHideChatBot: any = false;
  existingMealPlan: any;
  exisitngMealPlanName: any;
  newMealPlan: any;
  memMealDate: string = '';
  currentDate: any = '';
  minDate: any;
  maxDate: any;
  mealPlanName: any;
  fastingDate: any;
  fastingIndex: any;
  selectFastingId: any;
  fastingDay: any;
  fastingStartTime: any;
  fastingEndTime: any;
  editRecipeObj: any;
  flow:number=0;
  messages: { sender: string; text: string; id: number }[] = [];
  constructor(private mealServiceV2: MealPlanServiceV2, private mealService: MealPlanService, private route: ActivatedRoute, private router: Router,
    private toast: ToastService, private SharedService: SharedServiceService) { }

  ngOnInit(): void {
    this.memberId = this.route.snapshot.queryParamMap.get('memberId');
    this.id = this.route.snapshot.queryParamMap.get('id');
    this.fromDate = this.route.snapshot.queryParamMap.get('fromDate');
    this.toDate = this.route.snapshot.queryParamMap.get('toDate');
    this.mode = this.route.snapshot.queryParamMap.get('mode');
    this.memberMealId = this.route.snapshot.queryParamMap.get('memberMealId');
    var flow = this.route.snapshot.queryParamMap.get('flow');
    if(flow != null){
      this.flow = parseInt(flow);
    }
    this.isLoading = 1;
    this.mealServiceV2.getMealPlanDetails(this.id, this.fromDate, this.toDate).subscribe(data => {
      this.isLoading = 2;
      if (data === 1) {
        this.error = 1;
        this.toast.errorMessage("Sorry, there was an error while processing your request. Please try again!");
      } else {
        this.error = 0;
        this.planDetails = data;
        this.mealPlanName = this.planDetails.mealPlanName;
        this.planDetails = this.planDetails.response;
      }
    });
    this.SharedService.checkLogin().subscribe({
      next: (isLoggedIn: boolean) => {
        let user = this.SharedService.getLoggedInUser();
        this.userId = user.id;
      },
      error: (error) => {
        this.toast.errorMessage("Some Error Occurred. Please try again!");
        console.error('Error occurred during login check:', error);
      }
    });

  }
  changeImg(id: any) {
    var collapse = document.getElementById(id + 'col');
    collapse?.classList.toggle('collapse');
    var img = document.getElementById(id) as HTMLImageElement;
    if (img.src.includes('down1.png')) {
      img.src = 'assets/img/up2.png';
    } else {
      img.src = 'assets/img/down1.png';
    }
  }

  sendMessage() {
    if (this.userMessage.trim() === '') return;
    this.messages.push({ sender: 'user', text: this.userMessage.trim(), id: new Date().getTime() });
    let chatHistory = [];
    let startIndex = 0;
    if (this.messages.length > 1) {
      if (this.messages.length > 5) {
        startIndex = this.messages.length - 5;
      }
      for (let i = startIndex; i < this.messages.length; i++) {
        let msg = this.messages[i];
        if (msg.sender === 'assistant') {
          chatHistory.push({ role: 'assistant', content: msg.text });
        } else {
          chatHistory.push({ role: 'user', content: msg.text });
        }
      }
    }
    this.messages.push({ sender: 'assistant', text: 'Processing...', id: new Date().getTime() + 1 });
    this.scrollToBottom(100);
    let payload = {
      "user_message": this.userMessage.trim(),
      "plan_id": this.id,
      "userId": this.userId,
      "memberId": this.memberId,
      "chat_history": chatHistory,
      "selected_meal_ids": this.selectedMealIds,
      "selected_recipe_ids": this.selectedRecipeIds
    };
    this.mealServiceV2.sendMessage(payload).subscribe(response => {
      this.isLoading = 2;
      if (response === 1) {
        this.messages.pop();
        this.messages.push({
          sender: 'assistant',
          text: 'Sorry, there was an error while processing your request. Please try again!',
          id: new Date().getTime() + 1
        });
      } else {
        let resp: any = response;
        this.messages.pop();
        this.messages.push({ sender: 'assistant', text: resp.response_message, id: new Date().getTime() + 1 });
        if (resp.function_type === 'edit_meal_plan') {
          this.planDetails = [];
          this.mealServiceV2.getMealPlanDetails(this.id, this.fromDate, this.toDate).subscribe(data => {
            if (data === 1) {
              this.error = 1;
              this.toast.errorMessage("Sorry, there was an error while processing your request. Please try again!");
            } else {
              this.error = 0;
              this.planDetails = data;
              this.mealPlanName = this.planDetails.mealPlanName;
              this.planDetails = this.planDetails.response;
              this.selectedMealIds = [];
              this.selectedRecipeIds = [];
            }
          });
        }
      }
      this.scrollToBottom(200);
    });
    this.userMessage = '';
  }

  scrollToBottom(timeout: any) {
    setTimeout(() => {
      let id = this.messages[this.messages.length - 1];
      const chatContainer = document.getElementById(id?.id + "");
      chatContainer?.scrollIntoView();
    }, timeout);

  }
  openChatBot() {
    this.showHideChatBot = true
  }
  hideChatBot() {
    this.showHideChatBot = false;
  }
  backBtn() {
    this.router.navigate(['/v2/all-meals'], {
      queryParams: {
        memberId: this.memberId,
        isType: 4
      }
    });
  }
  selectImg(e: any, type: any) {
    if (e.target.src.indexOf("unselect.png") > 0) {
      e.target.src = 'assets/img/select.png';
      var ids = e.target.id.split('_');
      if (type == 'fasting') {
        if (!this.selectedMealIds.includes(ids[0])) {
          this.selectedMealIds.push(ids[0]);
        }
      } else {
        if (!this.selectedMealIds.includes(ids[0])) {
          this.selectedMealIds.push(ids[0]);
        }
        if (!this.selectedRecipeIds.includes(ids[1])) {
          this.selectedRecipeIds.push(ids[1]);
        }
      }
    } else {
      e.target.src = 'assets/img/unselect.png';
      var ids = e.target.id.split('_');
      if (type == 'fasting') {
        this.selectedMealIds = this.selectedMealIds.filter((item: any) => item
          !== ids[0]);
      } else {
        this.selectedMealIds = this.selectedMealIds.filter((item: any) => item !== ids[0]);
        this.selectedRecipeIds = this.selectedRecipeIds.filter((item: any) => item !== ids[1]);
      }
    }
  }
  convertSecondsToHoursAndMinutes(seconds: any) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    if (hours == 0) {
      return minutes + " mins";
    }
    return hours + " hr(s)" + " " + minutes + " min(s)";
  }

  assignMealPlan() {
    this.mealService.checkIsPlanAssign(this.memberId, this.fromDate, this.toDate).subscribe((response: any) => {
      let isExistPlan: any;
      isExistPlan = response;
      if (isExistPlan.isExist == true) {
        this.newMealPlan = { id: this.id, fromDate: this.fromDate, toDate: this.toDate, mealPlanName: this.mealPlanName };
        this.existingMealPlan = isExistPlan.mealPlan;
        this.exisitngMealPlanName = isExistPlan.mealPlanName;
        let fromDate = this.existingMealPlan.fromDate.split('T')[0];
        this.minDate = new Date(fromDate.split('-')[0], fromDate.split('-')[1] - 1, fromDate.split('-')[2]);
        let toDate = this.existingMealPlan.toDate.split('T')[0];
        this.maxDate = new Date(toDate.split('-')[0], toDate.split('-')[1] - 1, toDate.split('-')[2]);
        this.memMealDate = '';
        this.currentDate = '';
        $('#confirmAssign').modal('show');
      } else {
        let payload = {
          newObjectId: this.memberMealId,
          reqType: 'fromDraft'
        };
        this.mealServiceV2.updateMemberMealPlan(payload).subscribe((response: any) => {
          this.toast.successMessage('Meal Plan Assigned Successfully');
          this.router.navigate(['/v2/all-meals'], {
            queryParams: {
              memberId: this.memberId,
              isType: 4
            }
          });
        });
      }
    });
  }
  showcheckAssign() {
    $('#confirmAssign').modal('hide');
    $('#checkAssign').modal('show');
  }
  closeConfirmAssign() {
    $('#confirmAssign').modal('hide');
    this.router.navigate(['/v2/all-meals'], {
      queryParams: {
        memberId: this.memberId,
        isType: 4
      }
    });
  }

  setMemMealDate(date: HTMLInputElement) {
    var d = formatDate(date.value, 'yyyy-MM-dd', 'en-US');
    this.memMealDate = '';
    if (new Date(this.currentDate) < this.minDate) {
      this.toast.errorMessage('End date must be greater than from date.');
      return;
    }
    if (new Date(this.currentDate) > this.maxDate) {
      this.toast.errorMessage('End date must be less than to date.');
      return
    }
    let fromDate = this.newMealPlan.fromDate.split('T')[0];
    let newMealFromDate = new Date(fromDate.split('-')[0], fromDate.split('-')[1] - 1, fromDate.split('-')[2]);
    if (new Date(this.currentDate) >= newMealFromDate) {
      this.toast.errorMessage('End date must be less than new meal plan start date.');
      return;
    }
    this.memMealDate = d;
  }

  updateAndAssign() {
    if (this.memMealDate == '') {
      this.toast.errorMessage('Please select the end date.');
      return;
    }
    let payload = {
      currentObjectId: this.existingMealPlan.id,
      newObjectId: this.memberMealId,
      date: this.memMealDate,
      reqType: 'fromDraft'
    };
    this.mealServiceV2.updateMemberMealPlan(payload).subscribe(response => {
      if (response == 1) {
        this.toast.errorMessage('Some Exception Occurred. Please try again!');
      } else {
        this.toast.successMessage('Meal Plan Assigned Successfully');
        $('#checkAssign').modal('hide');
        this.router.navigate(['/v2/all-meals'], {
          queryParams: {
            memberId: this.memberId,
            isType: 4
          }
        });
      }
    });
  }

  openFastingModal(index: any, date: any, day: any, fastData: any) {
    this.fastingStartTime = '';
    this.fastingIndex = index;
    this.fastingEndTime = '';
    this.selectFastingId = '';
    if (fastData && fastData != null) {
      let d = new Date('01/01/1992 ' + fastData.fromTime);
      let hrs = d.getHours();
      let mins = d.getMinutes();
      this.fastingStartTime = (hrs < 10 ? '0' + hrs : hrs) + ':' + (mins < 10 ? '0' + mins : mins);
      d = new Date('01/01/1992 ' + fastData.toTime);
      hrs = d.getHours();
      mins = d.getMinutes();
      this.fastingEndTime = (hrs < 10 ? '0' + hrs : hrs) + ':' + (mins < 10 ? '0' + mins : mins);
      this.selectFastingId = fastData.id;
    }
    this.fastingDate = date;
    this.fastingDay = day;
    $('#fastingModal').modal('show');
  }

  saveFastingWindow() {
    if (this.fastingStartTime == '' || this.fastingEndTime == '') {
      this.toast.errorMessage('Please select fasting start/end time.');
      return;
    }
    var timeStart = new Date('01/01/1992 ' + this.fastingStartTime);
    var timeEnd = new Date('01/01/1992 ' + this.fastingEndTime);
    if (timeEnd <= timeStart) {
      this.toast.errorMessage('End time should be greater than start time.');
      return;
    }
    let meal = this.planDetails[this.fastingIndex];
    if (this.isFastingExists(meal.fastings, timeStart, timeEnd)) {
      this.toast.errorMessage('Fasting already added for this time.');
      return;
    }
    if (this.isRecipeExists(meal.meals, timeStart, timeEnd)) {
      this.toast.errorMessage('Recipe already there for this time.');
      return;
    }

    let payload = {
      mealPlanId: this.id,
      userId: this.userId,
      day: this.fastingDay,
      date: this.fastingDate,
      mealId: this.selectFastingId,
      fastingDetails: {
        fromTime: timeStart.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
        toTime: timeEnd.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
      }
    };

    this.isLoading = 1;
    this.mealServiceV2.addFasting(payload).subscribe(response => {
      if (response == 1) {
        this.isLoading = 2;
        this.toast.errorMessage('Some Exception Occurred. Please try again!');
      } else {
        this.toast.successMessage('Fasting Window Added/Updated Successfully');
        $('#fastingModal').modal('hide');
        this.mealServiceV2.getMealPlanDetails(this.id, this.fromDate, this.toDate).subscribe(data => {
          if (data === 1) {
            this.isLoading = 2;
            this.error = 1;
            this.toast.errorMessage("Sorry, there was an error while processing your request. Please try again!");
          } else {
            this.isLoading = 2;
            this.error = 0;
            this.planDetails = data;
            this.mealPlanName = this.planDetails.mealPlanName;
            this.planDetails = this.planDetails.response;
          }
        });
      }
      this.selectedMealIds = [];
      this.selectedRecipeIds = [];
    });
  }

  isRecipeExists(mealRecipe: any, newStartTime: Date, newEndTime: Date) {
    if (mealRecipe && mealRecipe.length > 0) {
      for (let i = 0; i < mealRecipe.length; i++) {
        let recipe = mealRecipe[i];
        let recipeTime = new Date('01/01/1992 ' + recipe.mealTime);
        if (recipeTime.getTime() == newStartTime.getTime() || recipeTime.getTime() == newEndTime.getTime()) {
          return true;
        }
        if (recipeTime < newEndTime && recipeTime > newStartTime) {
          return true;
        }
      }
    }
    return false;
  }

  isFastingExists(fastings: any, newStartTime: any, newEndTime: any) {
    if (fastings && fastings.length > 0) {
      for (let i = 0; i < fastings.length; i++) {
        let fasting = fastings[i];
        let fastingStart = new Date('01/01/1992 ' + fasting.fromTime);
        let fastingEnd = new Date('01/01/1992 ' + fasting.toTime);
        if (this.selectFastingId != fasting.id && ((fastingStart.getTime() == newStartTime.getTime() && fastingEnd.getTime() == newEndTime.getTime()) ||
          (fastingStart < newEndTime && fastingEnd > newStartTime))) {
          return true;
        }
      }
    }
    return false;
  }

  viewEditRecipe = (plan: any, meal: any, recipe: any) => {
    this.editRecipeObj = {
      recipeId: recipe.recipeId,
      mealId: meal.mealId,
      mealPlanId: this.id,
      date: plan.date,
      day: plan.day,
      userId: this.userId,
      recipeType: recipe.recipeType,
      recipeName: recipe.recipeName,
      nutrition: {
        calories: recipe.nutrition.calories,
        protein: recipe.nutrition.protein,
        carbs: recipe.nutrition.carbs,
        fats: recipe.nutrition.fat
      }, ingredients: [], stores: [], brand: {
        brandName: '',
        barCode: '',
      },
      cookingMethods: [], restaurant: {
        name: '',
        location: '',
        website: '',
        phoneNumber: '',
      }
    };
    let recipeData = recipe.diyRecipeData;
    if (recipe.recipeType == 'Restaurant') {
      recipeData = recipe.restaurantRecipeData;
    } else if (recipe.recipeType == 'Packaged') {
      recipeData = recipe.packagedRecipeData;
    }

    if (recipeData.ingredients && recipeData.ingredients.length > 0) {
      for (let i = 0; i < recipeData.ingredients.length; i++) {
        this.editRecipeObj.ingredients.push({
          name: recipeData.ingredients[i].ingredientName,
          quantity: recipeData.ingredients[i].measurement.quantity,
          unit: recipeData.ingredients[i].measurement.unit
        });
      }
    }
    if (recipe.recipeType == 'DIY') {
      if (recipe.diyRecipeData.instructions && recipe.diyRecipeData.instructions.length > 0) {
        for (let i = 0; i < recipe.diyRecipeData.instructions.length; i++) {
          this.editRecipeObj.cookingMethods.push({ method: recipe.diyRecipeData.instructions[i] });
        }
      }
    }
    if (recipe.recipeType == 'Packaged') {
      recipe.packagedRecipeData.stores.forEach((store: any) => {
        this.editRecipeObj.stores.push({ storeName: store.storeName, location: store.location, website: store.website });
      }
      );
      if (recipe.packagedRecipeData.brand != null) {
        this.editRecipeObj.brand.brandName = recipe.packagedRecipeData.brand;
        this.editRecipeObj.brand.barCode = recipe.packagedRecipeData.barcode;
      }
    } if (recipe.recipeType == 'Restaurant') {
      this.editRecipeObj.restaurant.name = recipe.restaurantRecipeData.restaurantName;
      this.editRecipeObj.restaurant.location = recipe.restaurantRecipeData.location;
      this.editRecipeObj.restaurant.website = recipe.restaurantRecipeData.website;
      this.editRecipeObj.restaurant.phoneNumber = recipe.restaurantRecipeData.phoneNumber;
    }
    $('#editModal').modal('show');
  }

  closeEditModal() {
    $('#editModal').modal('hide');
  }

  updateRecipe() {
    console.log(this.editRecipeObj);
    if (!this.isValidToProceed()) {
      return;
    }
    let cookingMethods = [];
    if (this.editRecipeObj.recipeType == 'DIY' && this.editRecipeObj.cookingMethods.length > 0) {
      for (let i = 0; i < this.editRecipeObj.cookingMethods.length; i++) {
        cookingMethods.push(this.editRecipeObj.cookingMethods[i].method);
      }
      this.editRecipeObj.cookingMethods = cookingMethods;
    }
    this.isLoading = 1;
    this.mealServiceV2.updateRecipe(this.editRecipeObj).subscribe(response => {
      if (response == 1) {
        this.toast.errorMessage('Some Exception Occurred. Please try again!');
        this.isLoading = 2;
      } else {
        this.toast.successMessage('Recipe Updated Successfully');
        $('#editModal').modal('hide');
        this.mealServiceV2.getMealPlanDetails(this.id, this.fromDate, this.toDate).subscribe(data => {
          this.isLoading = 2;
          if (data == 1) {
            this.error = 1;
            this.toast.errorMessage("Sorry, there was an error while processing your request. Please try again!");
          } else {
            this.error = 0;
            this.planDetails = data;
            this.mealPlanName = this.planDetails.mealPlanName;
            this.planDetails = this.planDetails.response;

          }
        });
      }
      this.selectedMealIds = [];
      this.selectedRecipeIds = [];
    });
  }

  isValidToProceed() {
    if (this.editRecipeObj.recipeName == null || this.editRecipeObj.recipeName.trim() == '') {
      this.toast.errorMessage('Please enter recipe name!');
      return false;
    }
    if (this.editRecipeObj.nutrition.calories == null || this.editRecipeObj.nutrition.protein == null
      || this.editRecipeObj.nutrition.carbs == null || this.editRecipeObj.nutrition.fats == null) {
      this.toast.errorMessage('Please enter nutrition values!');
      return false
    }
    if (this.editRecipeObj.ingredients.length == 0) {
      this.toast.errorMessage('Please add ingredients!');
      return false;
    }
    if (this.editRecipeObj.ingredients.length > 0) {
      for (let i = 0; i < this.editRecipeObj.ingredients.length; i++) {
        if (this.editRecipeObj.ingredients[i].name == null || this.editRecipeObj.ingredients[i].name.trim() == ''
          || this.editRecipeObj.ingredients[i].quantity <= 0 || this.editRecipeObj.ingredients[i].unit == null ||
          this.editRecipeObj.ingredients[i].unit.trim() == '') {
          this.toast.errorMessage('Please enter all/valid ingredient details!');
          return false;
        }
        if (this.editRecipeObj.ingredients[i].unit.match(/\d+/g)) {
          this.toast.errorMessage('Ingredients unit must be grams, ml, cup etc!');
          return false;
        }
      }
    }
    if (this.editRecipeObj.recipeType == 'DIY') {
      if (this.editRecipeObj.cookingMethods.length == 0) {
        this.toast.errorMessage('Please add cooking methods!');
        return false;
      }
      if (this.editRecipeObj.cookingMethods.length > 0) {
        for (let i = 0; i < this.editRecipeObj.cookingMethods.length; i++) {
          if (this.editRecipeObj.cookingMethods[i].method == null || this.editRecipeObj.cookingMethods[i].method.trim() == '') {
            this.toast.errorMessage('Please enter all cooking methods!');
            return false;
          }
        }
      }
    }
    if (this.editRecipeObj.recipeType == 'Restaurant') {
      if (this.editRecipeObj.restaurant.name == null || this.editRecipeObj.restaurant.name.trim() == '' ||
        this.editRecipeObj.restaurant.location == null || this.editRecipeObj.restaurant.location.trim() == '') {
        this.toast.errorMessage('Please enter restaurant details!');
        return false;
      }
      if (this.editRecipeObj.restaurant.phoneNumber != null && this.editRecipeObj.restaurant.phoneNumber.trim() != ''
        && !/^(?:(?:\+91|91)[\s\-]?)?[789]\d{9}$|^(?:\+1[\s\-]?)?(\(?\d{3}\)?[\s\-]?)?\d{3}[\s\-]?\d{4}$/.test(this.editRecipeObj.restaurant.phoneNumber)) {
        this.toast.errorMessage('Please enter valid phone number!');
        return false;
      }
    }
    if (this.editRecipeObj.recipeType == 'Packaged') {
      if (this.editRecipeObj.brand.brandName == null || this.editRecipeObj.brand.brandName.trim() == '') {
        this.toast.errorMessage('Please enter brand details!');
        return false;
      }
      if (this.editRecipeObj.stores.length == 0) {
        this.toast.errorMessage('Please add stores!');
        return false;
      }
      if (this.editRecipeObj.stores.length > 0) {
        for (let i = 0; i < this.editRecipeObj.stores.length; i++) {
          if (this.editRecipeObj.stores[i].storeName == null || this.editRecipeObj.stores[i].storeName.trim() == ''
            || this.editRecipeObj.stores[i].location == null || this.editRecipeObj.stores[i].location.trim() == '') {
            this.toast.errorMessage('Please enter all store details!');
            return false;
          }
        }
      }
    }
    return true;
  }

  deleteIngr(index: any) {
    this.editRecipeObj.ingredients.splice
      (index, 1);
  }
  addIngr() {
    this.editRecipeObj.ingredients.push({
      name: '',
      quantity: 0,
      unit: ''
    });
  }
  deleteStore(index: any) {
    this.editRecipeObj.stores.splice
      (index, 1);
  }
  addStore() {
    this.editRecipeObj.stores.push({ storeName: '', location: '', website: '' });
  }
  deleteMethod(index: any) {
    this.editRecipeObj.cookingMethods.splice
      (index, 1);
  }
  addMethod() {
    this.editRecipeObj.cookingMethods.push({ method: '' });
  }

  inputChange(e: any) {
    if (/[0-9]/.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\d/g, '');
    }
    if (e.target.value.match(/\./g) && e.target.value.match(/\./g).length > 0) {
      e.target.value = e.target.value.replace(/\./g, '');
    }
  }
}
