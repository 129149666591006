import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { NgIf,NgFor } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-score',
  standalone: true,
  imports: [NgIf,NgFor],
  templateUrl: './score.component.html',
  styleUrl: './score.component.css'
})
export class ScoreComponent {
  @Input() scores:any;
  @Input() member:any;
  workoutsData:any;

openWorkoutsModal(){
  this.workoutsData = this.scores.strainData;
  if(this.workoutsData){
    $('#workouts').modal('show');
  }
}
}
