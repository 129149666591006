<div class="col-md-10 float">
<!-- <mat-checkbox >Post meal</mat-checkbox> &nbsp;<mat-checkbox >Fasting</mat-checkbox> -->
<div class="col mt-3">
<highcharts-chart 
  [Highcharts]="Highcharts"
  [options]="chartOptions"
  style="width: 100%; height: 100%; display: block;"
></highcharts-chart>
</div>

<app-glucose-value [details]="glucoseDetails"></app-glucose-value>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content gmodalcontent">
      <div class="modal-header">
       <img src={{modalImg}} height="8%" width="8%"> &nbsp;&nbsp;<h5 class="modal-title mtitle" id="exampleModalLongTitle">{{modalType}}</h5>
       <p *ngIf="modalType === 'Sleep'" class="spmodal">{{modalSTime}} - {{modalETime}}</p>
       <p *ngIf="modalType !== 'Sleep'" class="pmodal">{{modalSTime}}</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       <div class="row smtitle" *ngIf="modalType == 'Sleep'">
          Total Sleep Duration
       </div>
       <div class="row smbody" *ngIf="modalType == 'Sleep'">
        {{modalDuration}}
       </div>
       <div class="row smtitle"  *ngIf="modalType == 'Activity'">
        <div class="col-md-4">
          Activity Name
        </div>
          <div class="col-md-4">
         Calories Burned
        </div>
          <div class="col-md-4">
          Distance Covered
        </div>
       </div>
        <div class="row smbody"  *ngIf="modalType == 'Activity'">
        <div class="col-md-4">
          {{activityName}}
        </div>
          <div class="col-md-4">
        {{calsBurned}} kcal
        </div>
          <div class="col-md-4">
         {{distCovered}} {{distUnits}}
          </div>
       </div>
       
        <div class="row fmname"  *ngIf="modalType == 'Nutrition'">
          {{foodName}}
        </div>
        <div class="row fmtitle"  *ngIf="modalType == 'Nutrition'">
        <div class="col-md-3">
         <img src="/assets/img/calories.png" width="12%" height="80%"/> &nbsp;Calories
        </div>
          <div class="col-md-3">
          <img src="/assets/img/protien.png" width="14%" height="83%"/> &nbsp;Proteins
        </div>
          <div class="col-md-3">
           <img src="/assets/img/carbs.png" width="13%" height="82%"/> &nbsp;Carbs
        </div>
        <div class="col-md-3">
           <img src="/assets/img/fat.png" width="17%" height="86%"/> &nbsp;Fats
        </div>
       </div>
       <div class="row smbody"  *ngIf="modalType == 'Nutrition'">
        <div class="col-md-3">
          {{foodCals}} cal
        </div>
          <div class="col-md-3">
          {{foodProteins}} grams
        </div>
          <div class="col-md-3">
          {{foodCarbs}} grams
        </div>
        <div class="col-md-3">
          {{foodFats}} grams
        </div>
       </div>
      </div>
      <br/>
      <br/>
    </div>
  </div>
</div>
</div>

<!-- <div class="col-md-4 float ">
    <div class="noteSection">Notes Section</div>
    <app-notes></app-notes>
</div> -->