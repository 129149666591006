import { Component } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MealPlanServiceV2 } from '../meal-plan-v2.service';
import { MealPlanService } from '../../meal-plan/meal-plan.service';
import { ActivatedRoute } from '@angular/router';
import { NgFor } from '@angular/common';
import { NgIf, formatDate } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { Router } from "@angular/router";
import { ToastService } from '../../services/toast.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDateRangeInput } from '@angular/material/datepicker';
import { MatLabel } from '@angular/material/form-field';
import { MatHint } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MealMemberBasicDetailsComponent } from '../../meal-plan/meal-member-basic-details/meal-member-basic-details.component';
declare var $: any;

@Component({
  selector: 'app-all-meals',
  standalone: true,
  imports: [MealMemberBasicDetailsComponent, NgxMaterialTimepickerModule, MatDatepickerModule, MatHint, MatFormFieldModule, MatLabel, MatInputModule, MatNativeDateModule, MatDateRangeInput, MatListModule, NgFor, NgIf, NgClass, FormsModule],
  templateUrl: './all-meals.component.html',
  styleUrl: './all-meals.component.css'
})

export class AllMealsComponentV2 {
  memberId: any;
  meals: any = [];
  isType: any = 1;
  mealTitle = 'Draft Meals';
  existingMealPlan: any;
  exisitngMealPlanName: any;
  newMealPlan: any;
  memMealDate: string = '';
  currentDate: any = '';
  minDate: any;
  maxDate: any;
  constructor(private toast: ToastService, private mealService: MealPlanService, private mealService2: MealPlanServiceV2,
    private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    this.memberId = this.route.snapshot.queryParamMap.get('memberId');
    this.route.queryParams.subscribe(params => {
      if (params['isType']) {
        this.isType = Number(params['isType']);
        if (this.isType == 4) {
          this.getDraftMeals();
        }
      }
    });
  }

  getDraftMeals(): void {
    this.isType = 4;
    this.mealTitle = 'Draft Meal Plan';
    this.getAllMeals('draft');

  }
  getUpComingMeals(): void {
    this.isType = 3;
    this.mealTitle = 'Upcoming Meal Plan';
    this.getAllMeals('upcoming');

  }
  getOngoingMeals(): void {
    this.isType = 1;
    this.mealTitle = 'Ongoing Meal Plan';
    this.getAllMeals('ongoing');

  }
  getCompletedMeals(): void {
    this.isType = 2;
    this.mealTitle = 'Completed Meal Plan';
    this.getAllMeals('completed');
  }

  getAllMeals(type: string) {
    this.meals = [];
    this.mealService2.getAllMeals(this.memberId, formatDate(Date.now(), 'yyyy-MM-dd', 'en-US'), type)
      .subscribe(response => {
        this.meals = response;
        if (this.meals && this.meals.length > 0 && type == 'draft') {
          this.meals = this.meals.filter((meal: any) => new Date(meal.toDate) >= new Date(formatDate(Date.now(), 'yyyy-MM-dd', 'en-US')));
        }
      });
  }
  formatDate(d: any) {
    return formatDate(d, 'dd MMM yyyy', 'en-US');
  }
  deleteMealPlan(id: any) {
    this.mealService2.deleteMealPlan(id).subscribe(response => {
      this.toast.successMessage('Meal Plan Deleted Successfully');
      this.getAllMeals('draft');
    });
  }
  formatTime(d: any) {
    return new Date(d).toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
  }
  editMealPlan(id: any, fromDate: any, toDate: any, memberMealId: any) {
    this.router.navigate(['/view-edit-plan'], {
      queryParams: {
        memberId: this.memberId,
        id: id,
        memberMealId: memberMealId,
        fromDate: fromDate,
        toDate: toDate,
        mode: 1
      }
    });
  }
  backBtn() {
    this.router.navigate(['/meal-plans']);
  }
  viewMealPlan(id: any, fromDate: any, toDate: any) {
    this.router.navigate(['/view-edit-plan'], {
      queryParams: {
        memberId: this.memberId,
        id: id,
        fromDate: fromDate,
        toDate: toDate,
        mode: 0
      }
    });
  }

  moveToDraft(id: any) {
    let payload = {
      newObjectId: id,
      reqType: 'toDraft'
    };
    this.mealService2.updateMemberMealPlan(payload).subscribe(response => {
      if (response == 1) {
        this.toast.errorMessage('Some Exception Occurred. Please try again!');
      } else {
        this.toast.successMessage('Meal Plan Moved to Draft Successfully');
        this.getDraftMeals();
      }
    });
  }

  unassignMealPlan(id: any) {
    let payload = {
      currentObjectId: id,
      date: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
      reqType: 'toDraft'
    };
    this.mealService2.updateMemberMealPlan(payload).subscribe(response => {
      if (response == 1) {
        this.toast.errorMessage('Some Exception Occurred. Please try again!');
      } else {
        this.toast.successMessage('Meal Plan unassigned & moved to drafts successfully');
        this.getDraftMeals();
      }
    });
  }
  assignMealPlan(meal: any) {
    let fromDate = meal.fromDate.split('T')[0];
    let toDate = meal.toDate.split('T')[0];
    this.mealService.checkIsPlanAssign(this.memberId, fromDate, toDate).subscribe((response: any) => {
      let isExistPlan: any;
      isExistPlan = response;
      if (isExistPlan.isExist == true) {
        this.newMealPlan = meal;
        this.existingMealPlan = isExistPlan.mealPlan;
        this.exisitngMealPlanName = isExistPlan.mealPlanName;
        let fromDate = this.existingMealPlan.fromDate.split('T')[0];
        this.minDate = new Date(fromDate.split('-')[0], fromDate.split('-')[1] - 1, fromDate.split('-')[2]);
        let toDate = this.existingMealPlan.toDate.split('T')[0];
        this.maxDate = new Date(toDate.split('-')[0], toDate.split('-')[1] - 1, toDate.split('-')[2]);
        this.memMealDate = '';
        this.currentDate = '';
        $('#confirmAssign').modal('show');
      } else {
        let payload = {
          newObjectId: meal.id,
          reqType: 'fromDraft'
        };
        this.mealService2.updateMemberMealPlan(payload).subscribe((response: any) => {
          this.toast.successMessage('Meal Plan Assigned Successfully');
          this.getDraftMeals();
        });
      }
    });
  }
  showcheckAssign() {
    $('#confirmAssign').modal('hide');
    $('#checkAssign').modal('show');
  }
  closeConfirmAssign() {
    $('#confirmAssign').modal('hide');
  }
  setMemMealDate(date: HTMLInputElement) {
    var d = formatDate(date.value, 'yyyy-MM-dd', 'en-US');
    this.memMealDate = '';
    if (new Date(this.currentDate) < this.minDate) {
      this.toast.errorMessage('End date must be greater than from date.');
      return;
    }
    if (new Date(this.currentDate) > this.maxDate) {
      this.toast.errorMessage('End date must be less than to date.');
      return
    }
    let fromDate = this.newMealPlan.fromDate.split('T')[0];
    let newMealFromDate = new Date(fromDate.split('-')[0], fromDate.split('-')[1] - 1, fromDate.split('-')[2]);
    if (new Date(this.currentDate) >= newMealFromDate) {
      this.toast.errorMessage('End date must be less than new meal plan start date.');
      return;
    }
    this.memMealDate = d;
  }
  updateAndAssign() {
    if (this.memMealDate == '') {
      this.toast.errorMessage('Please select the end date.');
      return;
    }
    let payload = {
      currentObjectId: this.existingMealPlan.id,
      newObjectId: this.newMealPlan.id,
      date: this.memMealDate,
      reqType: 'fromDraft'
    };
    this.mealService2.updateMemberMealPlan(payload).subscribe(response => {
      if (response == 1) {
        this.toast.errorMessage('Some Exception Occurred. Please try again!');
      } else {
        this.toast.successMessage('Meal Plan Assigned Successfully');
        $('#checkAssign').modal('hide');
        this.getDraftMeals();
      }
    });
  }
}
