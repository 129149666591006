<div class="memberPartern">
    <app-basic-details [screentype]="1" [memberMealDetails]="memberMealDetails"
        [selectedTab]="selectedTab"></app-basic-details>
    <div class="row mt-4" *ngIf="selectedTab!='tab2'">
        <div class="col-sm">

            <div class="col-md-12 labels">
                Remaining Meals
            </div>
            <div class="col-md-12 mt-2 labelContent">
                {{memberMealDetails.mealPlanId!='' ? memberMealDetails.remainingMeals+' Meals':'---'}}
            </div>

        </div>

        <div class="col-sm">
            <div class="col-md-12 labels">
                Due Date

            </div>
            <div class="col-md-12 mt-2 labelContent">
                {{memberMealDetails.mealPlanId!='' ? this.date:'---'}}
            </div>

        </div>
    </div>
    <div class="row mt-4" *ngIf="selectedTab!='tab2'">
        <div class="col-sm">
            <button class="btn btnFirst btnLabel" (click)="setInputs(memberMealDetails.memberId,'create')">Create Meal
                Plan</button>

        </div>

        <div class="col-sm">
            <button class="btn btnSec btnLabel ml-2" (click)="setInputs(memberMealDetails.memberId,'view')">
                View Meal Plan
            </button>
        </div>
    </div>
</div>

<div class="modal fade" id="confirm" tabindex="-1" role="dialog" aria-labelledby="confirmLabel" aria-hidden="true"
    data-bs-backdrop="static">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-size-style radius">
            <div class="modal-header">
                <h5 class="modal-title confirmInfo">Please confirm!</h5>
                <button type="button" class="close" aria-label="Close" data-bs-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body padding">
                <p class="confirmInfo mt-1">We have enhanced the process of creating and managing meal plans.</p>
                <p class="infoBody mt-2">Would you like to be redirected to the new view?</p>
                <input type="hidden" id="memId" value="">
                <input type="hidden" id="redirectId" value="">
                <div class="row mt-4">
                    <div class="col-md-2"></div>
                    <div class="col-md-3">
                        <button data-bs-dismiss="modal" class="btn btnFirst btnLabel" (click)="modalNo()">No</button>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-3">
                        <button data-bs-dismiss="modal" class="btn btnFirst btnLabel" (click)="modalYes()">Yes</button>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>