import { Component, Input } from '@angular/core';
import { BasicDetailsComponent } from '../basic-details/basic-details.component';
import { MatCardModule } from '@angular/material/card';
import { CommonModule, formatDate, NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { Router } from "@angular/router";
declare var $: any;

@Component({
  selector: 'app-member-meal-details',
  standalone: true,
  imports: [BasicDetailsComponent, MatCardModule, CommonModule, NgIf, RouterLink],
  templateUrl: './member-meal-details.component.html',
  styleUrl: './member-meal-details.component.css'
})
export class MemberMealDetailsComponent {
  @Input() memberMealDetails: any;
  @Input() selectedTab: any;
  date: any = "";
  constructor(private route: Router) { }
  ngOnInit(): void {
    let d = this.memberMealDetails.mealDueDate.split('T')[0];
    d = d.split('-');
    this.date = formatDate(new Date(d[0], d[1] - 1, d[2]), 'dd MMM yyyy', 'en-US');

  }
  setInputs(id: any, modal: any) {
    var input = document.getElementById('memId') as HTMLInputElement;
    var redirectTo = document.getElementById('redirectId') as HTMLInputElement;
    input.value = id;
    if (modal == 'create') {
      redirectTo.value = 'create';
    } else {
      redirectTo.value = 'view';
    }
    $('#confirm').modal('show');
  }

  modalYes() {
    var input = document.getElementById('memId') as HTMLInputElement;
    var redirectTo = document.getElementById('redirectId') as HTMLInputElement;
    if(redirectTo.value == 'create'){
    this.route.navigate(['/v2/create-plan'], {
      queryParams: {
        memberId: input.value
      }
    });
  }else{
     this.route.navigate(['/v2/all-meals'], {
      queryParams: {
        memberId: input.value,
        isType:4
      }
    });
  }
  }
  modalNo() {
    var input = document.getElementById('memId') as HTMLInputElement;
    var redirectTo = document.getElementById('redirectId') as HTMLInputElement;
    if(redirectTo.value == 'create'){
    this.route.navigate(['/create-plan'], {
      queryParams: {
        memberId: input.value
      }
    });
  }else{
     this.route.navigate(['/all-meal-plans'], {
      queryParams: {
        memberId: input.value,
        isType: 1
      }
    });
  }
  }
}
