<div class="row mt-4 mr-3" style="display:flex">
    <div class="col-sm scoreDiv ml-2" style="display: flex;">
        <div class="col float">
            <img class="img" src="/assets/img/sleep.png" />
        </div>
        <div class="col-sm float scoreContent">
            <p class="scoreContent">Sleep Score</p>
            <p class="scoreNo"><span *ngIf="scores.sleepData == null">---</span> <span
                    *ngIf="scores.sleepData != null">{{scores.sleepData.score}}</span></p>
        </div>
    </div>

    <div class="col-sm scoreDiv ml-2" style="display: flex;">
        <div class="col float">
            <img class="img" src="/assets/img/nutri.png" />
        </div>
        <div class="col float scoreContent">
            <p class="scoreContent">Nutrition Score</p>
            <p class="scoreNo"><span *ngIf="scores.nutritionData == null">---</span> <span
                    *ngIf="scores.nutritionData != null">{{scores.nutritionData.score}}</span></p>
        </div>
    </div>


    <div class="col-sm scoreDiv ml-2" style="display: flex;">
        <div class="col float">
            <img class="img" src="/assets/img/activity.png" />
        </div>
        <div class="col float scoreContent">
            <p class="scoreContent">Activity Score</p>
            <p class="scoreNo"><span *ngIf="scores.activityData == null">---</span> <span
                    *ngIf="scores.activityData != null">{{scores.activityData.score}}</span></p>
        </div>
    </div>

    <div class="col-sm scoreDiv ml-2" style="display: flex;">
        <div class="col float">
            <img class="img" src="/assets/img/glucose.png" />
        </div>
        <div class="col float scoreContent">
            <p class="scoreContent">Glucose Score</p>
            <p class="scoreNo"><span *ngIf="scores.glycemicData == null">---</span> <span
                    *ngIf="scores.glycemicData != null">{{scores.glycemicData.score}}</span></p>
        </div>
    </div>

    <!-- <div class="col scoreDiv ml-2" style="display: flex;">
        <div class="col float">
            <img class="img" src="/assets/img/glucose.png"/>
        </div>
        <div class="col float scoreContent">
            <p class="scoreContent">Focus Score</p>
            <p class="scoreNo">98</p>
        </div>
    </div> -->
</div>
<div class="row mt-4 mr-2" style="display:flex;">
    <div class="col-sm-3 scoreDiv ml-2" style="display: flex;cursor: pointer;"  (click)="openWorkoutsModal()">
        <div class="col float">
            <img class="img" src="/assets/img/strain.png" />
        </div>
        <div class="col-sm float scoreContent">
            <p class="scoreContent">Strain Score</p>
            <p class="scoreNo"><span *ngIf="scores.strainData == null">---</span> <span
                    *ngIf="scores.strainData != null">{{scores.strainData.avgScore}}</span></p>
        </div>
    </div>
     <div class="col-sm-3 scoreDiv ml-2" style="display: flex;">
        <div class="col float">
            <img class="img" src="/assets/img/recovery.png" />
        </div>
        <div class="col-sm float scoreContent">
            <p class="scoreContent">Recovery Score</p>
            <p class="scoreNo"><span *ngIf="scores.recoveryData == null">---</span> <span
                    *ngIf="scores.recoveryData != null">{{scores.recoveryData.score}}</span></p>
        </div>
    </div>
</div>

<div class="modal fade" id="workouts" tabindex="-1" role="dialog" aria-labelledby="workoutsLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content modal-size-style radius">
            <div class="modal-header">
                <span class="title">Workouts Details</span> <span class="timeZone">Timezone: {{member.timeZone}}</span>
                <button type="button" class="close" aria-label="Close" data-bs-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body textCenter padding">
                <table class="table table-striped">
                    <thead>
                        <tr>
                           
                            <th scope="col" style="border-top: none;">Type</th>
                            <th scope="col" style="border-top: none;">Start Time</th>
                            <th scope="col" style="border-top: none;">End Time</th>
                            <th scope="col" style="border-top: none;">Score</th>
                        </tr>
                    </thead>
                    <tbody  *ngIf="workoutsData!=null">
                        <tr *ngFor="let workout of workoutsData.workoutDetails">
                            <th scope="row">{{workout.type}}</th>
                            <td>{{workout.startTime.split('T')[0]}} {{workout.startTime.split('T')[1]}} </td>
                            <td>{{workout.endTime.split('T')[0]}} {{workout.endTime.split('T')[1]}}</td>
                            <td>{{workout.score}}/21</td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btnConfirm btnLabel" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>