

<div>
  <div>
    <div class="col-md-12">
      <button class="btn btn-primary" (click)="openForm()" type="button">Add Notes</button>
    </div>

    <div class="col-md-10 mt-2">
      <form #f="ngForm" (ngSubmit)="addProgressNotes()" *ngIf="isAddNotes || isShowNotes">
        <div class="form-group col-md-12 mt-1">
          <label for="lname"><strong>Call Summary</strong><span class="color">*</span>:</label>
          <textarea class="col-md-12" row="10" [(ngModel)]="progressNotes.callSummary" [ngModelOptions]="{standalone: true}">

                                 </textarea>
        </div>

        <div class="form-group col-md-12 mt-1">
          <label for="lname"><strong>Outcome</strong><span class="color">*</span>:</label>
          <textarea class="col-md-12" row="10" [(ngModel)]="progressNotes.outCome" [ngModelOptions]="{standalone: true}">

                                 </textarea>
        </div>

        <div class="form-group col-md-12 mt-1">
          <label for="lname"><strong>Plan Of Action</strong><span class="color">*</span>:</label>
          <textarea class="col-md-12" row="10" [(ngModel)]="progressNotes.planOfAction" [ngModelOptions]="{standalone: true}">

                                 </textarea>
        </div>

        <div class="form-group col-md-12 mt-1">
          <button type="submit" class="btn btn-primary" *ngIf="isAddNotes">Save</button> &nbsp; &nbsp; <button type="button" (click)="closeForm()" class="btn btn-danger">Close</button>
        </div>

      </form>
    </div>
    <div class="col-md-12">

      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="Date">
          <th mat-header-cell *matHeaderCellDef> Date</th>
          <td mat-cell *matCellDef="let element">{{element.createdDateTime.split('T')[0] }}  </td>
        </ng-container>
        <ng-container matColumnDef="Summary">
          <th mat-header-cell *matHeaderCellDef> Summary </th>
          <td mat-cell *matCellDef="let element"> {{element.callSummary.slice(0, 100) }}...</td>
        </ng-container>
        <ng-container matColumnDef="Outcome">
          <th mat-header-cell *matHeaderCellDef> Outcome </th>
          <td mat-cell *matCellDef="let element"> {{element.outCome.slice(0, 100) }}...</td>
        </ng-container>
        <ng-container matColumnDef="ActionPlan">
          <th mat-header-cell *matHeaderCellDef> Action Plan </th>
          <td mat-cell *matCellDef="let element"> {{element.planOfAction.slice(0, 100) }}...</td>
        </ng-container>
        <ng-container matColumnDef="AddedBy">
          <th mat-header-cell *matHeaderCellDef> Added By </th>
          <td mat-cell *matCellDef="let element"> {{element.userFirstName }} {{element.userLastName}}</td>
        </ng-container>
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row let i = index" class="flex" style="padding: 10px;">
            <button class="btn btn-primary" (click)="getNotesView(row.id)">View Details</button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
<div style="text-align:center;display:flex;">
  <mat-paginator #paginator
                 (page)="pageChangeEvent($event)"
                 [length]="this.progressNoteList['totalCount']"
                 [pageSize]="pageSize"
                 [pageIndex]="0"
                 showFirstLastButton>
  </mat-paginator>
</div>
