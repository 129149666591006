
<div>
  <details>
    <summary> Today's Meetings</summary>
    <div class="form-group col-md-12 p-3 mt-2 meeting_cards_styles"  *ngFor="let meeting of todayMeetings">

      <div class="d-flex flex-row justify-content-between" style="width:100%;">
        <div style="display: flex;flex-direction: column;">
          <span style="font-weight: 600;">{{meeting.eventTime}}</span>
          <label>{{meeting.title}}</label>
        </div>
        <div>
          <span>  {{meeting.eventDescription}}</span>
        </div>
        <div>
          <button type="button" class="btn btn-primary"><a [href]="meeting.meetingLink" style="color: white;" target="_blank">Join</a></button>

        </div>

      </div>
    </div>

  </details>
  <details>
    <summary> Upcomming Meetings</summary>

    <div class="form-group col-md-12 p-3 mt-2 meeting_cards_styles"  *ngFor="let meeting of upcomingMeetings">

      <div class="d-flex flex-row justify-content-between" style="width:100%;">
        <div style="display: flex;flex-direction: column;">
          <span style="font-weight: 600;">{{meeting.eventDate.split('T')[0]}} &nbsp;&nbsp;{{meeting.eventTime}}</span>
          <label>{{meeting.title}}</label>
        </div>
        <div>
          <span>  {{meeting.eventDescription}}</span>
        </div>
        <div>
          <button type="button" class="btn btn-primary"><a [href]="meeting.meetingLink" style="color: white;" target="_blank">Join</a></button>

        </div>

      </div>
    </div>
  </details>

</div>
