<div class="row">
  <img class="imgBack" (click)="backBtn()" src="assets/img/backbtn.png" />
</div>

<div class="row mt-5">
  <div class="col-md-12 col-lg-6">
    <div class="title">{{memberName}}</div>
  </div>
</div>
<div id="content-page" class="content-page">

  <div class="container-fluid">

    <div class="row">
      <div class="col-md-12 ">
        <mat-list role="list" style="display: flex;">
          <mat-list-item role="listitem">
            <div class="tab" [ngClass]="{'activeTab': mainTab === 'Dairy'}" (click)="selectedTab(1,1,'Dairy')">
              <div class="tabContent" [ngClass]="{'activeTabContent': mainTab === 'Dairy'}">Member Dairy</div>
            </div>
          </mat-list-item>
          <mat-list-item role="listitem">
            <div class="tab" [ngClass]="{'activeTab': mainTab === 'Ques'}" (click)="selectedTab(2,1,'Ques')">
              <div class="tabContent" [ngClass]="{'activeTabContent': mainTab === 'Ques'}">Questionnaire </div>
            </div>
          </mat-list-item>
          <mat-list-item role="listitem">
            <div class="tab" [ngClass]="{'activeTab': mainTab === 'Lab'}" (click)="selectedTab(3,3,'Lab')">
              <!--<div class="tab" [ngClass]="{'activeTab': mainTab === 'Lab'}">-->
              <div class="tabContent" [ngClass]="{'activeTabContent': mainTab === 'Lab'}">Lab Work </div>
            </div>
          </mat-list-item>
          <mat-list-item role="listitem">
            <div class="tab" [ngClass]="{'activeTab': mainTab === 'Meet'}" (click)="selectedTab(4,4,'Meet')">
              <!--<div class="tab" [ngClass]="{'activeTab': mainTab === 'Meet'}" >-->
              <div class="tabContent" [ngClass]="{'activeTabContent': mainTab === 'Meet'}">Meeting </div>
            </div>
          </mat-list-item>
          <mat-list-item role="listitem">
            <div class="tab" [ngClass]="{'activeTab': mainTab === 'Monitor'}" (click)="selectedTab(5,6,'Monitor')">

              <div class="tabContent" [ngClass]="{'activeTabContent': mainTab === 'Monitor'}">Monitoring </div>
            </div>
          </mat-list-item>
        </mat-list>
      </div>

    </div>


    <div class="row mt-4" *ngIf="isSubMenu == 2">
      <div class="col-md-12 ">
      </div>

    </div>


    <div class="row mt-4" *ngIf="isSubMenu == 5">
      <div class="col-md-12 ">
        <mat-list role="list" style="display: inline-flex;">
          <mat-list-item role="listitem">
            <div class="tab1" [ngClass]="{'activeTab1': subTab === 'activitygraph'}" (click)="subSelectedTab(6,'activitygraph')">
              <div class="tabContent1" [ngClass]="{'activeTabContent1': subTab === 'activitygraph'}">Activity Graph </div>
            </div>
          </mat-list-item>
          <mat-list-item role="listitem">
            <div class="tab1" [ngClass]="{'activeTab1': subTab === 'sleepgraph'}" (click)="subSelectedTab(8,'sleepgraph')">

              <div class="tabContent1" [ngClass]="{'activeTabContent1': subTab === 'sleepgraph'}">Sleep graph </div>
            </div>
          </mat-list-item>
          <mat-list-item role="listitem">
            <div class="tab1" [ngClass]="{'activeTab1': subTab === 'Food'}" (click)="subSelectedTab(7,'Food')">
              <div class="tabContent1" [ngClass]="{'activeTabContent1': subTab === 'Food'}">Food Logging </div>
            </div>
          </mat-list-item>

          <mat-list-item role="listitem">
            <div class="tab1" [ngClass]="{'activeTab1': subTab === 'Meal'}" (click)="subSelectedTab(9,'Meal')">

              <div class="tabContent1" [ngClass]="{'activeTabContent1': subTab === 'Meal'}">Meal Plan History</div>
            </div>
          </mat-list-item>
          <mat-list-item role="listitem">
            <div class="tab1" [ngClass]="{'activeTab1': subTab === 'Note'}" (click)="subSelectedTab(10,'Note')">
              <!--<div class="tab1" [ngClass]="{'activeTab1': subTab === 'Note'}">-->
              <div class="tabContent1" [ngClass]="{'activeTabContent1': subTab === 'Note'}">Progress Notes </div>
            </div>
          </mat-list-item>
        </mat-list>
      </div>

    </div>

    <div class="row mt-5" *ngIf="isMemberDairy">
      <div class="col-md-12 col-lg-6">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
          <div class="iq-card-body">
            <div class="title">Member Information</div>

          </div>


        </div>
      </div>

    </div>

    <div class="row mt-4" style="display: flex;" *ngIf="isMemberDairy">
      <div class="col-md-12" style="display: flex;">
        <app-member-detail-form></app-member-detail-form>
      </div>
    </div>

    <div class="row mt-4" *ngIf="subMenu== 1 && !isMemberDairy">
      <div class="col-md-12">
        <app-personality></app-personality>
      </div>
    </div>
    <div class="col-md-12 mt-4" *ngIf="subMenu ==2">
      <!-- <app-life-style></app-life-style> -->
      <app-dietary-details></app-dietary-details>
    </div>

    <div class="" *ngIf="subMenu == 3">
      <!-- <app-health></app-health> -->
      <app-dietary-preference></app-dietary-preference>
    </div>

    <div class="col-md-12" *ngIf="subMenu == 4">
      <!-- <app-dietary></app-dietary> -->
      <app-lifestyle-condition></app-lifestyle-condition>
    </div>

    <div class="row mt-4" *ngIf="subMenu == 5">
      <app-fitness></app-fitness>
    </div>
    <div class="col-md-12 mt-4" style="width:100%" *ngIf="subMenu == 6">
      <activity-graph-graph></activity-graph-graph>
    </div>
    <div class="col-md-12 mt-4" style="width:100%" *ngIf="subMenu == 7">
      <app-all-food-logs></app-all-food-logs>
    </div>
    <div class="col-md-12 mt-4" style="width:100%" *ngIf="subMenu == 8">
      <app-sleep-graph></app-sleep-graph>
    </div>
    <div class="col-md-12 mt-4" style="width:100%" *ngIf="subMenu == 9">
      <app-mealplan-history></app-mealplan-history>
    </div>
    <div class="col-md-12 mt-4" style="width:100%" *ngIf="subMenu == 10">
      <app-progress-notes></app-progress-notes>
    </div>

  </div>
</div>
