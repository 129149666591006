import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CountryModel } from '../model/countries';
@Injectable({
  providedIn: 'root'
})
export class HelperService {
  private apiBaseUrl = environment.apiBaseUrl;
  cities: any = [];
  states: any = [];
  state: any;
  doctor: any;
  userRole: any = [];
  countries: any = {
  };
  constructor(private http: HttpClient) { }
  getToken() {
    const token = localStorage.getItem('token');
    return token?.replace(/['"]+/g, '');
  }

  createAuthorizationHeader() {
    var token = this.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'X-ApiAccess-Key': environment.apiKey
    });
    return headers;
  }
  getCountryStateForAddForm(code: any) {
    const headers = this.createAuthorizationHeader();
    const url = this.apiBaseUrl + 'users/countries?country=' + code;
    return this.http.get<CountryModel>(url, { headers }).pipe(
      map((country: CountryModel) => {
        this.countries = country;
        if (code == 'india' || code == 'India') {
          var cityList = this.countries[0].states.filter(function (v: any, i: any) {
            return v['name'] === 'Andaman and Nicobar Islands';
          });
        } else {
          var cityList = this.countries[0].states.filter(function (v: any, i: any) {
            return v['name'] === 'Alabama';
          });
        }

        this.cities = cityList[0].cities;
        this.states = this.countries[0].states;
        return [this.countries, this.cities, this.states]
      })
    );

  }

  getCountryStateForEditForm(state: any, code: any) {
    console.log(state);
    console.log(code);
    const headers = this.createAuthorizationHeader();
    const url = this.apiBaseUrl + 'users/countries?country=' + code;

    return this.http.get<CountryModel>(url, { headers }).pipe(
      map((country: CountryModel) => {
        this.countries = country;

        var cityList = this.countries[0].states.filter(function (v: any, i: any = state) {
          return v['name'] === state;
        });

        this.cities = cityList[0].cities;
        this.states = this.countries[0].states;
        console.log(this.cities);
        console.log(this.states);
        return [this.countries, this.cities, this.states]
      })
    );

  }

  getloggedUserRole() {
    this.userRole = localStorage.getItem('user');
    this.userRole = JSON.parse(this.userRole);
    return this.userRole.userRoles[0].userRole;
  }
  formatDates(date: Date): string {
    console.log(date);
    //const day = date.getDate().toString().padStart(2, '0');
    //const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
    //const year = date.getFullYear();
    //return `${year}-${month}-${day}`;
    return "";
  }

}
