<div id="wait" *ngIf="isLoading == 1">
    <mat-spinner></mat-spinner>
</div>

<!-- <app-basic-details  [mealMember]="memberDetails" [screentype] = "2" [selectedTab]=""></app-basic-details> -->
<app-meal-member-basic-details [memberDetail]="memberId"></app-meal-member-basic-details>
<div class="row">

    <img class="imgBack" *ngIf="this.backType < 3" (click)="backButton(this.backType)" src="assets/img/backbtn.png" />
</div>
<hr class="mt-5">
<div class="createMealDiv" *ngIf="iscreateMeal">
    <div class="row mt-5">
        <div class="col">
            <div class="title">Daily Target Calories</div>
            <div class="subTitle mt-4">Check the values on the fields</div>
            <div class="flex mt-4">
                <div>
                    <div>Daily Target Calories</div>
                    <div><input type="text" matInput number class="form-control textField textContent mt-2"
                            (keyup)="changeDailyTarget()" [(ngModel)]="dailyTarget"
                            [ngModelOptions]="{standalone: true}" placeholder="TDEE" /></div>
                </div>
                <div>
                    <div>Calorie Deficit Goal</div>
                    <div class="rightBorder">
                        <input type="text" [(ngModel)]="deficit" (keyup)="changeDailyTarget()"
                            [ngModelOptions]="{standalone: true}" placeholder="Calorie Deficit Goal"
                            class="form-control textField textContent" />
                    </div>
                </div>


            </div>
        </div>
        <div class="col ">
            <div class="title">Meal Allocations</div>
            <div class="subTitle mt-4">Set the start and end date of the meal plan</div>
            <div class="mt-4"></div>
            <div class="flex mt-5 rightBorder">
                <mat-form-field>
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [rangePicker]="picker" [min]="todayDate">
                        <input matStartDate placeholder="Start date" #dateRangeStart>
                        <input matEndDate placeholder="End date" #dateRangeEnd
                            (dateChange)="getSelectedDate(dateRangeStart, dateRangeEnd)">
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <!-- <input type="date" class="col-md-6 form-control textField textContent" placeholder="Select Date Range"/>  -->

                <!-- <input type="text" placeholder="Meals per day" class="form-control textField textContent"/> -->
            </div>
        </div>
        <div class="col">
            <div class="title">Meal Plan Name</div>
            <div class="subTitle mt-4">Type the meal plan name below</div>
            <div class="mt-4"></div>
            <div class="flex mt-5">
                <div><input type="text" matInput number class="form-control textField textContent mt-2"
                        [(ngModel)]="mealPlanName" [ngModelOptions]="{standalone: true}" placeholder="Type Here" />
                </div>
            </div>
        </div>
    </div>

    <hr class="mt-5">
    <div class="row mt-5">
        <div class="title col-md-12">Generate Meal For</div>
        <div class="mainSub col-md-12 mt-2">Select multiple category</div>
    </div>

    <div class="row mt-4">
        <div class="col-2">
            <div class="colDiv " [ngClass]="{'mealActive': isBreakfast === 1}">
                <div class="titleName">Breakfast</div>
                <div class="crossMain">
                    <div class="cross" (click)="removeMealType(1,'Breakfast')" *ngIf="isBreakCross == 1">X</div>
                    <div class="cross" (click)="addMealType(1,'Breakfast')" *ngIf="isBreakCross == 2">+</div>
                </div>
            </div>
        </div>

        <div class="col-2 marginForSmallWindow">
            <div class="colDiv" [ngClass]="{'mealActive': isLunch === 1}">
                <div class="titleName">Lunch</div>
                <div class="crossMain">
                    <div class="cross" *ngIf="isLunchCross == 1" (click)="removeMealType(2,'Lunch')">X</div>
                    <div class="cross" *ngIf="isLunchCross == 2" (click)="addMealType(2,'Lunch')">+</div>
                </div>
            </div>
        </div>

        <div class="col-2 marginForSmallWindow">
            <div class="colDiv" [ngClass]="{'mealActive': isDinner === 1}">
                <div class="titleName">Dinner</div>
                <div class="crossMain">
                    <div class="cross" *ngIf="isDinnerCross == 1" (click)="removeMealType(3,'Dinner')">X</div>
                    <div class="cross" *ngIf="isDinnerCross == 2" (click)="addMealType(3,'Dinner')">+</div>
                </div>
            </div>
        </div>

        <div class="col-2 marginForSmallWindow">
            <div class="colDiv" [ngClass]="{'mealActive': isSnack === 1}">
                <div class="titleName">Snack</div>
                <div class="crossMain">
                    <div class="cross" *ngIf="isSnackCross == 1" (click)="removeMealType(4,'Snack')">X</div>
                    <div class="cross" *ngIf="isSnackCross == 2" (click)="addMealType(4,'Snack')">+</div>
                </div>
            </div>
        </div>
    </div>

    <hr class="mt-5">

    <div class="row mt-5">
        <div class="title">Meal and Macros breakdown</div>
    </div>

    <div class="row mt-4">
        <div class="col rightBorder" *ngFor="let foodType of this.meal;let i=index;">
            <div class="mealTitle">For {{foodType.type}}</div>
            <div class="flex  mt-3">
                <div>
                    <div>Percentage</div>
                    <div>
                        <input type="text" class="form-control textField textContent" value="{{foodType.percentage}}%"
                            placeholder="35%" />
                    </div>
                </div>


                &nbsp;&nbsp;
                <div>
                    <div>Calories</div>
                    <div>
                        <input type="text" [(ngModel)]="foodType.calories" [ngModelOptions]="{standalone: true}"
                            placeholder="950 kcal" class="form-control textField textContent" />
                    </div>
                </div>

            </div>
            <!-- <div class="mealTitle mt-3">Protien & Carbs</div> -->
            <div class="flex  mt-3">
                <div>
                    <div>Protien</div>
                    <div>
                        <input type="text" class="form-control textField textContent"
                            [(ngModel)]="foodType.macroBreakdown.protein" [ngModelOptions]="{standalone: true}"
                            placeholder="35%" />
                    </div>
                </div>
                &nbsp;&nbsp;
                <div>
                    <div>Carbs</div>
                    <div>
                        <input type="text" placeholder="10%" [(ngModel)]="foodType.macroBreakdown.carbs"
                            [ngModelOptions]="{standalone: true}" class="form-control textField textContent" />
                    </div>
                </div>

            </div>

            <!-- <div class="mealTitle mt-3">Fat</div> -->
            <div class=" padding  mt-3 flex">
                <div>
                    <div>Fat</div>
                    <div>
                        <input type="text" class="form-control textField textContent"
                            [(ngModel)]="foodType.macroBreakdown.fat" [ngModelOptions]="{standalone: true}"
                            placeholder="35%" />
                    </div>
                </div>
                &nbsp;&nbsp;

                <div>
                    <div>Time</div>
                    <div>
                        <input matInput (blur)="checkTime($event,i)" class="form-control textField textContent"
                            [(ngModel)]="foodType.time" [ngModelOptions]="{standalone: true}" placeholder="HH:MM"
                            type="time-24" ampm={false} [ngxTimepicker]="picker" [format]="24" placeholder="HH:MM">
                        <ngx-material-timepicker #picker [format]="24"></ngx-material-timepicker>
                        <!-- <input type="time" class="form-control textField textContent" (blur)="checkTime($event,i)" [(ngModel)]="foodType.time" [ngModelOptions]="{standalone: true}" placeholder="HH:MM"/>  -->
                    </div>
                </div>

            </div>
        </div>

    </div>


    <hr class="mt-5">

    <app-preference [allPrefer]="allPrefer" [selectedType]="checkSelectedMealType"></app-preference>

    <div class="col-md-12 mt-5 textAlign">
        <button class="btn btnClass" (click)="createMealPlan()">Generate Meal Plan</button>
    </div>
</div>

<div class="modal fade" id="createConfirm" tabindex="-1" role="dialog" aria-labelledby="createConfirmLabel"
    aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content modal-size-style radius">
            <div class="modal-body text-center mt-3 modalPadding">
                <p class="modalTitle">Meal Plan Moved to Draft Section</p>
                <p class="modalSubTitle">The meal plan has been successfully relocated to the draft section, where it is
                    now available for review and edits before finalization.</p>
                <div class="mt-4">
                    <button data-bs-dismiss="modal" class="btn btnFirst btnLabel"
                        (click)="continueClick()">Continue</button>
                </div>
            </div>
        </div>
    </div>
</div>