import { Component, Input } from '@angular/core';
import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MemberDashboardService } from '../../member-dashboard/member-dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { SleepDataList } from '../../models/sleep-data';

@Component({
  selector: 'app-sleep-graph',
  templateUrl: './sleep-graph.component.html',
  standalone: true,
  imports: [
    FormsModule,
    MatTableModule,
    CommonModule,
    HighchartsChartModule,
    MatFormFieldModule,
    MatDatepickerModule,
  ],
  styleUrls: ['./sleep-graph.component.css'],
})
export class SleepGraphComponent {

  dates: string[] = [];
  endDate: string = new Date().toISOString().split('T')[0];
  startDate: string = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
  currentDateBmi: any;
  previousDateBmi: any;
  sleep: any = [];
  remSleepPerct: number[] = [];
  deepSleepPerct: number[] = [];
  lightSleepPerct: number[] = [];
  awakePerct: number[] = [];
  totalSleepDurationPerct: number[] = [];
  remSleepHrs: number[] = [];
  deepSleepHrs: number[] = [];
  lightSleepHrs: number[] = [];
  awakeHrs: number[] = [];
  totalSleepDurationHrs: number[] = [];
  totalSleepDurationText: string[] = [];
  startTimeText: string[] = [];
  endTimeText: string[] = [];
  deepSleepTime: any;
  remSleepTime: any;
  lightSleepTime: any;
  awakeTime: any;
  memberId: any;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions1: Highcharts.Options = {}; chartOptions2: Highcharts.Options = {};
  constructor(private dashboard: MemberDashboardService, private route: ActivatedRoute) {
    var d = new Date();
    var p = new Date(new Date().setDate(new Date().getDate() - 7));
    this.currentDateBmi = d
    this.previousDateBmi = p;
  }
  ngOnInit(): void {
    this.memberId = this.route.snapshot.queryParamMap.get('id') || '';
    this.createChart();
    this.getSleepData();
  }
  ngOnChanges(): void {

    this.memberId = this.route.snapshot.queryParamMap.get('id') || '';

    this.deepSleepTime = '---';
    this.remSleepTime = '---';
    this.lightSleepTime = '---';
    this.awakeTime = '---';
  }

  getSleepData() {
    this.dashboard.getGraphSleepData(this.memberId, this.startDate, this.endDate).subscribe((response: SleepDataList) => {



      this.dates = response.result.map((item) => item.dateKey);
      this.deepSleepPerct = response.result.map((item) => Number(Math.round(item.totalDeepSleepDuration / item.totalSleepDuration * 100)));
      this.lightSleepPerct = response.result.map((item) => Number(Math.round(item.totalLightSleepDuration / item.totalSleepDuration * 100)));
      this.awakePerct = response.result.map((item) => Number(Math.round(item.totalAwakeDuration / item.totalSleepDuration * 100)));
      this.remSleepPerct = response.result.map((item) => Number(Math.round(item.totalRemSleepDuration / item.totalSleepDuration * 100)));
      this.totalSleepDurationPerct = response.result.map((item) => Number(Math.round((item.totalSleepDuration / (24 * 60 * 60)) * 100).toFixed(2)));

      //this.deepSleepHrs = response.result.map((item) => Number(this.convertSecondsToDecimalHours(item.totalDeepSleepDuration)));
      //this.lightSleepHrs = response.result.map((item) => Number(this.convertSecondsToDecimalHours(item.totalLightSleepDuration)));
      //this.awakeHrs = response.result.map((item) => Number(this.convertSecondsToDecimalHours(item.totalAwakeDuration)));
      //this.remSleepHrs = response.result.map((item) => Number(this.convertSecondsToDecimalHours(item.totalRemSleepDuration)));
      this.totalSleepDurationHrs = response.result.map((item) => Number(this.convertSecondsToDecimalHours(item.totalSleepDuration)));

      //this.deepSleepPerct = Math.round(this.sleep.totalDeepSleepDuration / this.sleep.totalSleepDuration * 100);
      //this.lightSleepPerct = Math.round(this.sleep.totalLightSleepDuration / this.sleep.totalSleepDuration * 100);
      //this.awakePerct = Math.round(this.sleep.totalAwakeDuration / this.sleep.totalDurationForAvg * 100);
      this.totalSleepDurationText = response.result.map((item) => this.getHrsMints(item.totalSleepDuration));
      this.startTimeText = response.result.map((item) => item.sleepStartTime);
      this.endTimeText = response.result.map((item) => item.sleepEndTime);

      this.deepSleepTime = response.result.map((item) => this.getHrsMints(item.totalDeepSleepDuration)); 
      this.remSleepTime = response.result.map((item) => this.getHrsMints(item.totalRemSleepDuration)); 
      this.lightSleepTime = response.result.map((item) => this.getHrsMints(item.totalLightSleepDuration));
      this.awakeTime = response.result.map((item) => this.getHrsMints(item.totalAwakeDuration)); 


      this.createChart();
    });

  }

  createChart() {
    this.chartOptions2 = {
      chart: {
        backgroundColor: 'white',
        type: 'column'
      },
      title: {
        text: ''
      },
      xAxis: { type: 'datetime', categories: this.dates },
      yAxis: {
        min: 0, max: 100, title: {
          text: 'Percentage for a day'
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter: function () {
          const customData = this.point.options.custom ?? {};     
          if (this.y === 0) {
            return false;
          }
          return `
      <div>
        <span style="font-size: 12px; color: #fefefe;"><b>${this.series.name}</b></span><br>
        <span style="font-size: 12px; color: #fefefe;">Percentage: ${this.y}%</span><br>
        <span style="font-size: 12px; color: #fefefe;">Duration: <b>${customData['timeHrs'] || 'N/A'}</b></span>
        
      </div>`;
         
        },
        backgroundColor: 'rgba(0,0,0, 0.6)',
        borderWidth: 1,
        shadow: true,
        style: {
          color: '#fff',
          fontSize: '10pt',
          fontFamily: 'Poppins, sans-serif'
        }
      },
      plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
      series: [{
        type: 'column',
        name: 'Deep Sleep',
        data: this.deepSleepPerct.map((value: number, index: number) => ({
          x: index,
          y: value,
          custom: {
            timeHrs: this.deepSleepTime[index],
            
          }
        })) as Highcharts.PointOptionsObject[],
        color: '#99FF99'
      }
        , {
        type: 'column',
          name: 'REM Sleep',
          
          data: this.remSleepPerct.map((value: number, index: number) => ({
            x: index,
            y: value,
            custom: {
              timeHrs: this.remSleepTime[index],

            }
          })) as Highcharts.PointOptionsObject[],
        color: '#21A4B6'
      }, {
        type: 'column',
          name: 'Light Sleep', 

          data: this.lightSleepPerct.map((value: number, index: number) => ({
            x: index,
            y: value,
            custom: {
              timeHrs: this.lightSleepTime[index],

            }
          })) as Highcharts.PointOptionsObject[],
        color: '#4787F6'
      }, {
        type: 'column',
          name: 'Awake Time',
          data: this.awakePerct.map((value: number, index: number) => ({
            x: index,
            y: value,
            custom: {
              timeHrs: this.awakeTime[index],

            }
          })) as Highcharts.PointOptionsObject[],
        color: '#FFDA48'
      }
      ]
    };


    this.chartOptions1 = {
      chart: {
        backgroundColor: 'white',
        type: 'column'
      },
      title: {
        text: ''
      },
      xAxis: { type: 'datetime', categories: this.dates },
      yAxis: {
        min: 0,
        max: 24,  // Maximum limit for 24 hours
        tickInterval: 4,  // Sections of 2 hours
        title: {
          text: 'Hours'
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          const customData = this.point.options.custom ?? {};              
          return `
      <div>
        <span style="font-size: 12px; color: #fefefe;"> Sleep Duration: <b>${customData['info'] || 'N/A'}</b></span>
        <br/>
         <span style="font-size: 12px; color: #fefefe;"> StartTime:   <b>${customData['info1'] || 'N/A'}</b></span>
         <br>
          <span style="font-size: 12px; color: #fefefe;">EndTime:    <b>${customData['info2'] || 'N/A'}</b></span>
      </div>
    `;
        },
        backgroundColor: 'rgba(0,0,0, 0.6)',
        borderWidth: 1,
        shadow: true,
        style: {
          color: '#fff',
          fontSize: '10pt',
          fontFamily: 'Poppins, sans-serif'
        }
      },
      plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
      series: [{
        type: 'column',
        name: 'Total sleep in 24 hrs',
        data: this.totalSleepDurationHrs.map((value: number, index: number) => ({
          x: index,
          y: value,
          custom: {
            info: this.totalSleepDurationText[index],
            info1: this.startTimeText[index],
            info2: this.endTimeText[index]
          } 
        })) as Highcharts.PointOptionsObject[],
        color: '#99FF99'
      }
      ]
    };
  }

  getHrsMints(seconds: any) {

    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    return hours + ' hrs ' + minutes + ' mins';
  }
  filterByDateSleepGrpah(startDateInput: HTMLInputElement, endDateInput: HTMLInputElement): void {
    const start = startDateInput.value;
    const end = endDateInput.value;
    if (start && end) {
      this.startDate = start;
      this.endDate = end;
      this.getSleepData()
    }
  }
  convertSecondsToDecimalHours(seconds: number): number {
    return +(seconds / 3600).toFixed(2);
  }
}
