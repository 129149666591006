import { Component } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { TotalNutriComponent } from '../total-nutri/total-nutri.component';
import { FoodService } from '../food.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Updatefoodlogs } from '../../../models/updatefoodlogs';
import { RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastService } from '../../../services/toast.service';

import {
  formatNumber
}
  from '@angular/common';


import { NgIf } from '@angular/common';
@Component({
  selector: 'app-edit-food',
  standalone: true,
  imports: [NgIf, MatListModule, TotalNutriComponent, FormsModule, RouterLink, MatTableModule, MatProgressSpinnerModule],
  templateUrl: './edit-food.component.html',
  styleUrl: './edit-food.component.css'
})
export class EditFoodComponent {
  foodId: any;
  foodDetails: any = [];
  foodTotalCal: any = [];
  perGramTotal: any = [];
  qty: any = [];
  memberId: any;
  displayedColumns: string[] = ['Ingredients', 'Number of Servings', 'Serving Unit', 'Serving Size', 'Proteins', 'Carbs', 'Fats'];
  dataSource = new MatTableDataSource;
  allData: any = [];
  realTotalVal: any = [];
  isLoading: any = 2;
  constructor(private FoodService: FoodService, private route: ActivatedRoute, private datePipe: DatePipe, private toast: ToastService) {
  }

  ngOnInit(): void {
    this.memberId = this.route.snapshot.queryParamMap.get('memberId');
    this.getFoodLogs();
    // this.FoodService.getFoodById(this.route.snapshot.queryParamMap.get('id')).subscribe(response => {    
    //   // this.allData1  = response;
    //   // this.allData  = this.allData1.foodLog;
    //   this.foodDetails = response; 

    //   this.foodDetails =  this.foodDetails.foodLog;
    //   this.setItemOriginalValue(this.foodDetails.foodItems);
    //   //console.log( this.foodDetails);
    //   this.dataSource = this.foodDetails.foodItems;


    //  this.setTotalValue();

    //   // this.perGramTotal={
    //   //   'oneCalories': (this.foodDetails.total.calories/this.foodDetails.serving.size).toFixed(1),
    //   //   'oneProtien': (this.foodDetails.total.protein/this.foodDetails.serving.size).toFixed(1),
    //   //   'oneCarb': (this.foodDetails.total.carbs/this.foodDetails.serving.size).toFixed(1),
    //   //   'oneFat': (this.foodDetails.total.fat/this.foodDetails.serving.size).toFixed(1)
    //   // };

    //   this.realTotalVal={
    //     'cal':this.foodDetails.total.calories,
    //     'pro':this.foodDetails.total.protein,
    //     'carb':this.foodDetails.total.carbs,
    //     'fat':this.foodDetails.total.fat
    //   };

    //  // this.qty=this.foodDetails.serving.quantity;
    //   //console.log(this.perGramTotal);
    //   //this.foodTotalCal
    //   //console.log(this.foodDetails );
    // });
  }

  getFoodLogs() {
    this.FoodService.getFoodById(this.route.snapshot.queryParamMap.get('id')).subscribe(response => {
      // this.allData1  = response;
      // this.allData  = this.allData1.foodLog;
      this.foodDetails = response;

      this.foodDetails = this.foodDetails.foodLog;
      this.setItemOriginalValue(this.foodDetails.foodItems);
      console.log(this.foodDetails);
      this.dataSource = this.foodDetails.foodItems;


      this.setTotalValue();

      // this.perGramTotal={
      //   'oneCalories': (this.foodDetails.total.calories/this.foodDetails.serving.size).toFixed(1),
      //   'oneProtien': (this.foodDetails.total.protein/this.foodDetails.serving.size).toFixed(1),
      //   'oneCarb': (this.foodDetails.total.carbs/this.foodDetails.serving.size).toFixed(1),
      //   'oneFat': (this.foodDetails.total.fat/this.foodDetails.serving.size).toFixed(1)
      // };

      this.realTotalVal = {
        'cal': this.foodDetails.total.calories,
        'pro': this.foodDetails.total.protein,
        'carb': this.foodDetails.total.carbs,
        'fat': this.foodDetails.total.fat
      };

      // this.qty=this.foodDetails.serving.quantity;
      //console.log(this.perGramTotal);
      //this.foodTotalCal
      //console.log(this.foodDetails );
    });
  }

  setItemOriginalValue(data: any) {
    for (let i = 0; i < data.length; i++) {
      this.allData[i] = [];
      this.allData[i].push({ 'qty': data[i].serving.quantity, 'unit': data[i].serving.unit, 'size': data[i].serving.size, 'cal': data[i].nutrition.calories, 'pro': data[i].nutrition.protein, 'carbs': data[i].nutrition.carbs, 'fat': data[i].nutrition.fat })
      this.calculateVal(i);

    }
    // console.log(this.allData);
  }
  setTotalValue() {
    this.foodTotalCal = {
      'totalCalories': this.foodDetails.total.calories,
      'totalProteins': this.foodDetails.total.protein,
      'totalCarbs': this.foodDetails.total.carbs,
      'totalFats': this.foodDetails.total.fat
    };
  }
  getDate(selectedTime: any) {
    const timeParts = selectedTime.split(':');
    const selectedDate = new Date();
    selectedDate.setHours(parseInt(timeParts[0], 10));
    selectedDate.setMinutes(parseInt(timeParts[1], 10));
    return this.datePipe.transform(selectedDate, 'hh:mm a');
  }

  updateFoodLog() {
    // console.log(this.foodDetails);
    let food: any = [];
    let qty: any;
    food = this.foodDetails;
    let unitVal = 1;
    this.isLoading = 1;
    for (let i = 0; i < food.foodItems.length; i++) {
      if (food.foodItems[i].serving.unit == 'ounces') {
        unitVal = 28.35;
      } else if (food.foodItems[i].serving.unit == 'cups') {
        unitVal = 28.35 * 8;
      }
      if (food.foodItems[i].serving.unit == 'ounces' || food.foodItems[i].serving.unit == 'cups') {
        food.foodItems[i].nutrition.calories = food.foodItems[i].nutrition.calories / unitVal;
        food.foodItems[i].nutrition.protein = food.foodItems[i].nutrition.protein / unitVal;
        food.foodItems[i].nutrition.carbs = food.foodItems[i].nutrition.carbs / unitVal;
        food.foodItems[i].nutrition.fat = food.foodItems[i].nutrition.fat / unitVal;
      }

      qty = food.foodItems[i].serving.quantity;
      food.foodItems[i].nutrition.calories = food.foodItems[i].nutrition.calories / qty;
      food.foodItems[i].nutrition.protein = food.foodItems[i].nutrition.protein / qty;
      food.foodItems[i].nutrition.carbs = food.foodItems[i].nutrition.carbs / qty;
      food.foodItems[i].nutrition.fat = food.foodItems[i].nutrition.fat / qty;
    }
    //console.log(food);
    this.FoodService.updateFoodLog(food).subscribe(response => {
      this.isLoading = 2;
      //this.toast.success('Food logs updated successfully.');
      // setTimeout(window.location.href="todo", 2000);
      if (response == 1) {
        this.toast.errorMessage('Some exception occurred. Please try again!');
      } else {
        this.toast.successMessage('Food logs updated successfully.');
      }
      this.getFoodLogs();
    });
  }

  changeServingSize(data: any) {
    let val = data.target.value;
    this.foodDetails.total.calories = (this.perGramTotal.oneCalories * val).toFixed(1);
    this.foodDetails.total.protein = (this.perGramTotal.oneProtien * val).toFixed(1);
    this.foodDetails.total.carbs = (this.perGramTotal.oneCarb * val).toFixed(1);
    this.foodDetails.total.fat = (this.perGramTotal.oneFat * val).toFixed(1);
    this.setTotalValue();
  }

  changeServingNo(data: any) {
    let val = data.target.value;
    if (val > 0) {
      let cal = this.foodDetails.total.calories / this.qty;
      let pro = this.foodDetails.total.protein / this.qty;
      let carb = this.foodDetails.total.carbs / this.qty;
      let fat = this.foodDetails.total.fat / this.qty;

      this.foodDetails.total.calories = (cal * val).toFixed(1);
      this.foodDetails.total.protein = (pro * val).toFixed(1);
      this.foodDetails.total.carbs = (carb * val).toFixed(1);
      this.foodDetails.total.fat = (fat * val).toFixed(1);
      this.qty = val;
      this.setTotalValue();
    }
  }

  changeServingValue(data: any, index: any, type: any) {
    let prev = 1;
    let val = data.target.value;
    console.log(this.allData[index]);
    let allFood = this.allData[index][0];
    if (val < 0) {
      val = 1;
    }
    if (val > 0) {
      let value: any;


      if (type == 1) {
        value = this.foodDetails.foodItems[index].serving.size;
        prev = this.foodDetails.foodItems[index].serving.quantity;
        this.foodDetails.foodItems[index].serving.quantity = val;


      }
      else if (type == 2) {
        value = this.foodDetails.foodItems[index].serving.quantity;
        prev = this.foodDetails.foodItems[index].serving.size;
        this.foodDetails.foodItems[index].serving.size = val;

      }
      //let size = allFood.qty;  
      let cal = this.foodDetails.foodItems[index].nutrition.calories / prev;
      let pro = this.foodDetails.foodItems[index].nutrition.protein / prev;
      let carb = this.foodDetails.foodItems[index].nutrition.carbs / prev;
      let fat = this.foodDetails.foodItems[index].nutrition.fat / prev;
      let unit: any = 1;
      if (this.foodDetails.foodItems[index].serving.unit == 'ounces') {
        unit = 28.35;
      }
      else if (this.foodDetails.foodItems[index].serving.unit == 'cups') {
        unit = 28.35 * 8;
      }
      //console.log(pro*val);

      this.foodDetails.foodItems[index].nutrition.calories = (cal * val * unit).toFixed(1);
      this.foodDetails.foodItems[index].nutrition.protein = (pro * val * unit).toFixed(1);
      this.foodDetails.foodItems[index].nutrition.carbs = (carb * val * unit).toFixed(1);
      this.foodDetails.foodItems[index].nutrition.fat = (fat * val * unit).toFixed(1);
      //console.log(this.foodDetails.foodItems[index]);
      let totalNutri = this.getTotalNutri(this.foodDetails.foodItems);
      // console.log(totalNutri);
      this.foodDetails.total.calories = (totalNutri[0]).toFixed(1);
      this.foodDetails.total.protein = (totalNutri[1]).toFixed(1);
      this.foodDetails.total.carbs = (totalNutri[2]).toFixed(1);
      this.foodDetails.total.fat = (totalNutri[3]).toFixed(1);



      this.setTotalValue();
    }
  }

  getPerServe(allFood: any, divideBy: any) {
    //let size = allFood.qty;  
    let cal = allFood.cal / divideBy;
    let pro = allFood.pro / divideBy;
    let carb = allFood.carbs / divideBy;
    let fat = allFood.fat / divideBy;

    return [cal, pro, carb, fat];
  }

  getNutre(cal: any, pro: any, carb: any, fat: any, val: any, value: any, allFood: any, unit: any) {
    let currentCal = (cal * val * value - allFood.cal) * unit;
    let currentPro = (pro * val * value - allFood.pro) * unit;
    let currentCarb = (carb * val * value - allFood.carbs) * unit;
    let currentFat = (fat * val * value - allFood.fat) * unit;
    return [currentCal, currentPro, currentCarb, currentFat];
  }

  getTotalNutri(data: any) {
    //console.log(data);
    let unit = 1;
    let totalCol: any = 0;
    let totalPro: any = 0;
    let totalCarb: any = 0;
    let totalFat: any = 0;

    for (let i = 0; i < data.length; i++) {

      // if(data[i].serving.unit == 'ounces'){
      //     unit = 28.35;
      // }
      // else if(data[i].serving.unit == 'cups'){
      //   unit = 28.35*8;
      // }

      // console.log(i+' '+data[i].nutrition.protein);
      totalCol = (parseFloat(totalCol) + parseFloat(data[i].nutrition.calories));
      totalPro = (parseFloat(totalPro) + parseFloat(data[i].nutrition.protein));
      totalCarb = (parseFloat(totalCarb) + parseFloat(data[i].nutrition.carbs));
      totalFat = (parseFloat(totalFat) + parseFloat(data[i].nutrition.fat));


      //console.log(totalPro);
    }

    return [totalCol, totalPro, totalCarb, totalFat];
  }


  calculateVal(index: any) {
    let unitVal = 1;

    //for(let i=0;i<this.allData.length;i++){
    const data = this.allData[index][0];
    if (data.unit == 'ounces') {
      unitVal = 28.35;
    }
    else if (data.unit == 'cups') {
      unitVal = 28.35 * 8;
    } else {
      unitVal = 1;
    }

    //  let servingType = this.foodDetails.foodItems[index].serving.size/data.qty;
    //  servingType = this.foodDetails.foodItems[index].serving.size/servingType;
    let cal = data.cal * this.foodDetails.foodItems[index].serving.quantity;
    this.foodDetails.foodItems[index].nutrition.calories = formatNumber(cal * unitVal, 'en-US', '1.0-0');

    let pro = data.pro * this.foodDetails.foodItems[index].serving.quantity;
    this.foodDetails.foodItems[index].nutrition.protein = (pro * unitVal).toFixed(1);

    let carb = data.carbs * this.foodDetails.foodItems[index].serving.quantity;
    this.foodDetails.foodItems[index].nutrition.carbs = (carb * unitVal).toFixed(1);

    let fat = data.fat * this.foodDetails.foodItems[index].serving.quantity;
    this.foodDetails.foodItems[index].nutrition.fat = (fat * unitVal).toFixed(1);

    // this.foodDetails.foodItems[index].nutrition.calories =(data.cal*unitVal).toFixed(1);
    // this.foodDetails.foodItems[index].nutrition.protein =(data.pro*unitVal).toFixed(1);
    // this.foodDetails.foodItems[index].nutrition.carbs = (data.carbs*unitVal).toFixed(1);
    // this.foodDetails.foodItems[index].nutrition.fat = (data.fat*unitVal).toFixed(1);
    //}
    return 1;
  }

  changeNoServing(event: any, index: any, type: any) {
    let unitVal = 1;
    const data = this.allData[index][0];
    let currentVal = event.target.value;
    let typeunit: any;
    let servingType: any;
    if (type == 3) {
      this.foodDetails.foodItems[index].serving.unit = currentVal;
      console.log(this.foodDetails.foodItems[index].serving.unit);
    }
    if (this.foodDetails.foodItems[index].serving.unit == 'ounces') {
      unitVal = 28.35;
    }
    else if (this.foodDetails.foodItems[index].serving.unit == 'cups') {
      unitVal = 28.35 * 8;
    } else {
      unitVal = 1;
    }

    if (type == 1) {
      //typeunit = data.qty;

      // servingType = this.foodDetails.foodItems[index].serving.size/data.size;
      this.foodDetails.foodItems[index].serving.quantity = currentVal;
    } else if (type == 2) {
      //typeunit = data.size;
      // servingType = this.foodDetails.foodItems[index].serving.quantity/data.qty;
      this.foodDetails.foodItems[index].serving.size = currentVal;
    }

    servingType = this.foodDetails.foodItems[index].serving.size / data.size;

    let cal = data.cal * this.foodDetails.foodItems[index].serving.quantity;
    this.foodDetails.foodItems[index].nutrition.calories = (servingType * cal * unitVal).toFixed(1);

    let pro = data.pro * this.foodDetails.foodItems[index].serving.quantity;
    this.foodDetails.foodItems[index].nutrition.protein = (servingType * pro * unitVal).toFixed(1);

    let carb = data.carbs * this.foodDetails.foodItems[index].serving.quantity;
    this.foodDetails.foodItems[index].nutrition.carbs = (servingType * carb * unitVal).toFixed(1);

    let fat = data.fat * this.foodDetails.foodItems[index].serving.quantity;
    this.foodDetails.foodItems[index].nutrition.fat = (servingType * fat * unitVal).toFixed(1);

    let totalNutri = this.getTotalNutri(this.foodDetails.foodItems);

    this.foodDetails.total.calories = totalNutri[0];
    this.foodDetails.total.protein = totalNutri[1];
    this.foodDetails.total.carbs = totalNutri[2];
    this.foodDetails.total.fat = totalNutri[3];
    //console.log(this.foodDetails.total);
    this.setTotalValue();

  }

}
